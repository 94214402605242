import type { ReactNode } from 'react';
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';

interface MessageBoxButtonProps extends ButtonProps {
  text: string;
}

export interface ModalProps {
  children?: ReactNode;
  title: ReactNode;
  message?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  button: MessageBoxButtonProps;
  closeText?: string;
  isCloseDisabled?: boolean;
  preventCloseOnConfirm?: boolean;
}

export const MessageBox = ({
  children,
  title,
  message,
  isOpen,
  onClose,
  button,
  closeText,
  isCloseDisabled = false,
  preventCloseOnConfirm = false,
}: ModalProps) => {
  const { colorScheme = 'red', onClick, text: buttonText, ...buttonProps } = button;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={!isCloseDisabled}
      closeOnEsc={!isCloseDisabled}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h3" fontSize="xl">
            {title}
          </Heading>
        </ModalHeader>
        <ModalCloseButton isDisabled={isCloseDisabled} />
        <ModalBody>
          {/* Wrap strings in a Text component, otherwise render as-is */}
          {message && (typeof message === 'string' ? <Text>{message}</Text> : message)}
          {children}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            colorScheme="gray"
            mr={3}
            onClick={onClose}
            isDisabled={isCloseDisabled}
          >
            {closeText || 'Done'}
          </Button>
          <Button
            colorScheme={colorScheme}
            data-testid="confirm"
            onClick={(ev) => {
              onClick?.(ev);

              if (!preventCloseOnConfirm) {
                onClose();
              }
            }}
            {...buttonProps}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
