import { Badge, Box, Flex, Link, Text } from '@chakra-ui/react';
import type { LicensePlate, Shipper } from '@store/services/api.generated';
import { useNavigate } from 'react-router-dom';
import { BarcodeBuddyResult, LineItemDetails } from './components';
import { NoTranslate, OutboundOrderLink } from '@components';

export function LicensePlatePreview({
  licensePlate,
  shipperMap,
  closeModal,
  handleClick = () => {},
}: {
  licensePlate: LicensePlate;
  shipperMap: Map<string | undefined, Shipper>;
  closeModal: () => void;
  handleClick?: () => void;
}) {
  const activeBadgeColor = licensePlate?.active ? 'green' : 'gray';
  const activeBadgeText = licensePlate?.active ? 'active' : 'inactive';

  const directLink = `/license-plates/${encodeURIComponent(licensePlate?.number!)}`;

  const locationLink = `/settings/locations/${encodeURIComponent(licensePlate?.location?.name!)}`;

  const lineItems = (licensePlate.items ?? []).map((item) => ({
    sku: item.product?.sku,
    quantity: item.quantity,
    shipperName: shipperMap.get(item.shipper_id ?? '')?.name ?? '--',
  }));

  const orders = (licensePlate.parcels ?? [])
    .filter((parcel) => parcel.status !== 'void')
    .flatMap((parcel) => ({
      key: parcel.id,
      outboundOrder: parcel.outbound,
      shipperName: shipperMap.get(parcel.outbound?.shipper_id ?? '')?.name ?? '--',
    }));

  const navigate = useNavigate();

  return (
    <BarcodeBuddyResult
      type="lpn"
      directLink={directLink}
      closeModal={closeModal}
      handleClick={handleClick}
    >
      <Box py={1}>
        <Flex alignItems="center" gridGap={4}>
          <Text
            as={Link}
            onClick={() => {
              handleClick();
              navigate(directLink);
              closeModal();
            }}
            fontSize="xl"
            fontWeight="bold"
          >
            <NoTranslate>{licensePlate?.number ?? '--'}</NoTranslate>
          </Text>
          <Badge colorScheme={activeBadgeColor}>{activeBadgeText}</Badge>
        </Flex>

        <Flex gridGap={2}>
          <Text variant="light" fontSize="sm">
            Location:
          </Text>
          <Text
            as={Link}
            onClick={() => {
              handleClick();
              navigate(locationLink);
              closeModal();
            }}
            fontSize="sm"
          >
            <NoTranslate>{licensePlate?.location?.name ?? '--'}</NoTranslate>
          </Text>
        </Flex>

        {orders.length > 0 && (
          <Flex gridGap={2}>
            <Text variant="light" fontSize="sm">
              Order:
            </Text>
            <Box>
              {orders.map(({ key, outboundOrder, shipperName }, index) => (
                <Text
                  variant="light"
                  fontSize="sm"
                  as="span"
                  key={key}
                  onClick={() => {
                    handleClick();
                    closeModal();
                  }}
                >
                  {index > 0 && ', '}
                  <OutboundOrderLink shipperName={shipperName} outbound={outboundOrder} />
                </Text>
              ))}
            </Box>
          </Flex>
        )}

        <LineItemDetails lineItems={lineItems} closeModal={closeModal} />
      </Box>
    </BarcodeBuddyResult>
  );
}
