import BuildingsTable from './BuildingsTable';
import { WmsModal } from '@components';

type SelectBuildingModalProps = {
  isOpen: boolean;
};

const SelectBuildingModal = ({ isOpen }: SelectBuildingModalProps) => {
  return (
    <WmsModal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      hideCloseButton
      isOpen={isOpen}
      // Nothing needs to happen on close because the BuildingsTable will set the buildingId for us, which updates the isOpen prop passed to us where this component is used
      onClose={() => {}}
      size="xl"
      title="Select a Building"
    >
      <BuildingsTable />
    </WmsModal>
  );
};

export default SelectBuildingModal;
