import { isValid, parseISO, format } from 'date-fns';

export type AllowedDate = string | Date;
/**
 * Formats a given Date or valid date string to a format such as 8/4/2021, 9:47 AM PDT
 *
 * @returns {string} Will be a well-formatted date string or the original input value when an invalid date
 */
export const formatDateTime = (
  date: AllowedDate,
  options?: {
    timeZone?: Intl.DateTimeFormatOptions['timeZone'];
    localeParameters?: Record<string, string>;
  }
) => {
  const formatAsLocale = (date: Date) => {
    // Passing `undefined` here forces the browser to render with the user's given locale (or in the timeZone if provided)
    return date.toLocaleString(undefined, {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      timeZoneName: 'short',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: options?.timeZone,
      ...(options?.localeParameters ?? {}),
    });
  };

  if (!date || (typeof date === 'string' && !isValid(parseISO(date)))) {
    return date; // Return params that aren't valid date strings
  }

  if (typeof date === 'string') {
    return formatAsLocale(new Date(date));
  }

  return formatAsLocale(date);
};

export const formatDate = (
  date: AllowedDate,
  timeZone?: Intl.DateTimeFormatOptions['timeZone']
) => {
  const formatAsLocale = (date: Date) => {
    // Passing `undefined` here forces the browser to render with the user's given locale
    return date.toLocaleString(undefined, {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      timeZone,
    });
  };

  if (!date || (typeof date === 'string' && !isValid(parseISO(date)))) {
    return date; // Return params that aren't valid date strings
  }

  if (typeof date === 'string') {
    // Sometimes we have an proper ISO string with a time, sometimes without
    return date.indexOf('T') === -1
      ? formatAsLocale(new Date(`${date}T00:00:00.000`))
      : formatAsLocale(new Date(date));
  }

  return formatAsLocale(date);
};

export function createTZBoundDateUtils(timeZone?: Intl.DateTimeFormatOptions['timeZone']) {
  return {
    formatDate: (date: AllowedDate) => formatDate(date, timeZone),
    formatDateTime: (
      date: AllowedDate,
      options?: {
        localeParameters?: Record<string, string>;
      }
    ) => formatDateTime(date, { timeZone, ...options }),
  };
}

export function buildUTCDateTimeString(date: string, time?: string | null) {
  const timeString = time ? `T${time}Z` : '';
  return `${date}${timeString}`;
}

export function buildLocalDateTimeString(date: string, time?: string | null) {
  const timeString = time ? `T${time}` : '';
  return `${date}${timeString}`;
}

/**
 * Converts a month-year string to a full date string.
 * @param monthYear - A string in the format 'YYYY-MM'
 * @returns A string in the format 'yyyy-MM-dd' with the day set to '01', or an empty string if conversion fails
 */

export const formatMonthYearToDateString = (monthYear: string): string => {
  try {
    const [year, month] = monthYear.split('-');
    const date = new Date(`${year}-${month}-01`);
    return format(date, 'yyyy-MM-dd');
  } catch (e) {
    return '';
  }
};

/**
 * Formats Date or DateString to given format. Return empty string if conversion fails.
 * @param date - A date string or Date to be formatted.
 * @param desiredFormat - A string representing the desired format.
 * @returns A string in the given format, or an empty string if conversion fails
 */

export const formatDateUTC = (date: AllowedDate, desiredFormat: string): string => {
  try {
    return format(formatDate(date), desiredFormat);
  } catch (e) {
    return '';
  }
};
