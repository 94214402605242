import * as yup from 'yup';
import { greaterThanZeroNumberSchema } from '@features/scanner/schemas';
import { numberOrBlankValidation } from '@schemas';

export const updateShipmentSchema = yup.object().shape({
  pallet_quantity: greaterThanZeroNumberSchema,
  weight: numberOrBlankValidation,
  trailer_number: yup.string(),
  pro_number: yup.string(),
  seal_number: yup.string(),
  special_instructions: yup.string().label('Special Instructions').max(250),
});

export const createParcelSchema = yup.object().shape({
  weight: yup.number().typeError('Invalid').required('Invalid').positive('Invalid'),
  length: yup.number().typeError('Invalid').required('Invalid').positive('Invalid'),
  width: yup.number().typeError('Invalid').required('Invalid').positive('Invalid'),
  height: yup.number().typeError('Invalid').required('Invalid').positive('Invalid'),
});
