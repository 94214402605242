import type { SetupWorkerApi } from 'msw';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import {
  ConfigGate,
  DatadogProvider,
  ErrorBoundaryReloadOnDynamicImport,
  FeatureFlagProvider,
  Auth0Provider,
  SEO,
  MSWToolbar,
  AuthBuildingHydrator,
} from '@components';
import pack from '../package.json';
import { store } from '@store';
import { ChakraProvider } from '@theme';
import { getConfigForEnv, getChannelsPubSub } from '@utils';
import App from './App';

const { API_URL } = getConfigForEnv('dev');
const isDev = process.env.NODE_ENV === 'development';

let worker: SetupWorkerApi;

// Start the pubsub before anything else, so we don't have import order or circular dependencies issues :joy:
getChannelsPubSub();

const prepareWorker = async () => {
  if (isDev) {
    const { getWorker } = await import('./mocks/browser');
    worker = getWorker();
    return worker;
  }
};

function renderApp() {
  const app = document.getElementById('root')!;

  // create a root
  const root = createRoot(app);

  //render app to root
  root.render(
    <React.StrictMode>
      <ChakraProvider>
        <ErrorBoundaryReloadOnDynamicImport>
          <HelmetProvider>
            <Provider store={store}>
              <SEO titleTemplate="%s | Stord WMS" defaultTitle="Stord WMS" />
              {isDev && (
                <>
                  <MSWToolbar
                    prefix={pack.name}
                    apiUrl={API_URL}
                    worker={worker}
                    position="bottom"
                    isEnabled
                  />
                </>
              )}
              <ConfigGate>
                <BrowserRouter>
                  <Auth0Provider>
                    <AuthBuildingHydrator>
                      <DatadogProvider version={__APP_VERSION__}>
                        <FeatureFlagProvider>
                          <App />
                        </FeatureFlagProvider>
                      </DatadogProvider>
                    </AuthBuildingHydrator>
                  </Auth0Provider>
                </BrowserRouter>
              </ConfigGate>
            </Provider>
          </HelmetProvider>
        </ErrorBoundaryReloadOnDynamicImport>
      </ChakraProvider>
    </React.StrictMode>
  );
}

prepareWorker().then(renderApp);
