import { Box } from '@chakra-ui/react';
import { getEnvironment } from '@utils';

export const EnvironmentBar = () => {
  const environment = getEnvironment();

  return (
    <>
      {environment === 'staging' && (
        <Box bg="purple.400" w="100%" p={2} color="black" textAlign="center" fontWeight="bold">
          STAGING
        </Box>
      )}
      {environment === 'development' && (
        <Box bg="orange" w="100%" p={2} color="black" textAlign="center" fontWeight="bold">
          DEVELOPMENT
        </Box>
      )}
    </>
  );
};
