import * as yup from 'yup';

export type BuildingFormFields = {
  name?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  timezone?: string;
  invia_base_url: string;
  insights_enabled?: boolean;
};

export const buildingAddressSchema = yup.object({
  line1: yup.string().required('Address is required'),
  line2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  country: yup.string().required('Country is required'),
  postal_code: yup.string().required('Zip/postal is required'),
  timezone: yup.string().required('Timezone is required'),
  invia_base_url: yup
    .string()
    .matches(/^http(s?):\/\/(.+)/, {
      message: 'inVia Base URL must begin with http:// or https://',
      excludeEmptyString: true,
    })
    .matches(/(.+)\.(\w){2,}$/, {
      message: 'inVia Base URL may not contain a traling slash or path',
      excludeEmptyString: true,
    })
    .matches(/^http(s?):\/\/(.+)\.(\w){2,}$/, {
      message: 'inVia Base URL must be a valid domain',
      excludeEmptyString: true,
    }),
});

export const editBuildingSchema = buildingAddressSchema.shape({
  insights_enabled: yup.boolean(),
  name: yup.string().required('Building name is required'),
});
