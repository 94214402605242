import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  HStack,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import type { ModalProps as ChakraModalProps, BoxProps, StackProps } from '@chakra-ui/react';
import type { ReactNode } from 'react';

interface WmsModalProps extends ChakraModalProps {
  footer?: ReactNode;
  hideCloseButton?: boolean;
  modalBodyProps?: BoxProps;
  modalContentProps?: BoxProps;
  modalHeaderProps?: BoxProps;
  title?: ReactNode;
}

export const WmsModal = ({
  children,
  footer,
  hideCloseButton,
  title = '',
  onClose,
  modalHeaderProps,
  modalContentProps,
  modalBodyProps,
  ...chakraModalProps
}: WmsModalProps) => {
  // we turn off modal animations in tests to improve reliability and speed
  // 'scale' is taken from the default modal props in the Chakra UI library
  // see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/modal/src/modal.tsx#L194
  const motionPreset = process.env.NODE_ENV === 'test' ? 'none' : 'scale';
  const closeButtonColor = mode('gray.800', 'whiteAlpha.800');
  const manualStyles =
    chakraModalProps.size === 'full' ? { sx: { minHeight: '-webkit-fill-available' } } : {};

  return (
    <Modal onClose={onClose} motionPreset={motionPreset} {...chakraModalProps}>
      <ModalOverlay />
      <ModalContent {...modalContentProps} {...manualStyles} className="_translate">
        <ModalHeader color={mode('gray.800', 'whiteAlpha.800')} {...modalHeaderProps}>
          {title}
        </ModalHeader>
        {!hideCloseButton && (
          <ModalCloseButton
            color={closeButtonColor}
            data-testid="wms-modal-close-button"
            onClick={onClose}
          />
        )}
        <ModalBody {...modalBodyProps}>{children}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

interface WmsModalFooterProps extends StackProps {
  children: ReactNode;
}

export const WmsModalFooter = ({ children, ...containerProps }: WmsModalFooterProps) => {
  return (
    <HStack pt={6} pb={2} spacing={2} justify="end" {...containerProps}>
      {children}
    </HStack>
  );
};
