import { useSetFieldErrors, useToast } from '@hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFInput, RHFSelect } from '@components/forms';
import * as yup from 'yup';
import { useGetProvidersQuery, useLazyGetBuildingsQuery } from '@store/services/api';
import type { MultiStepFormPageProps } from '@components/multi-step-form';
import { FormButtons, FormContentHeader } from '@components/multi-step-form';
import { Grid, GridItem } from '@chakra-ui/react';
import type { CreateBuildingFormState } from '@features/building/CreateBuilding/CreateBuilding';

const buildingNameSchema = yup.object({
  name: yup.string().required('Building name is required'),
  providerId: yup.string().required('Provider is required'),
  shipperName: yup.string(),
});

type FormData = yup.InferType<typeof buildingNameSchema>;

export const BuildingName = ({
  multiStepFormState,
  onSuccess,
}: MultiStepFormPageProps<CreateBuildingFormState>) => {
  const { handleSubmit, control, setError } = useForm<FormData>({
    defaultValues: {
      name: multiStepFormState?.buildingName ?? '',
      shipperName: multiStepFormState?.shipperName ?? '',
      providerId: multiStepFormState?.providerId ?? '',
    },
    resolver: yupResolver(buildingNameSchema),
  });

  const { data, isLoading, isError } = useGetProvidersQuery({});
  const providers = (data?.data ?? []).map((provider) => {
    return {
      label: provider?.name!,
      value: provider?.id!,
    };
  });

  const [getBuilding] = useLazyGetBuildingsQuery();
  const setFieldErrors = useSetFieldErrors({ setError });
  const { errorToast } = useToast();

  if (isError) {
    errorToast('An error occurred trying to fetch providers');
  }

  const onSubmit = handleSubmit(async (building) => {
    try {
      const buildingData = await getBuilding({
        name: building.name,
      }).unwrap();

      const isExistingBuilding = (buildingData?.data ?? []).find(
        (existingBuilding) => existingBuilding.name === building.name
      );

      if (isExistingBuilding) {
        setError('name', { message: 'Building with this name already exists' });
      } else {
        onSuccess({
          ...multiStepFormState,
          buildingName: building.name,
          providerId: building.providerId,
          shipperName: building.shipperName,
        });
      }
    } catch (err) {
      const didSetErrors = setFieldErrors(err);
      if (!didSetErrors) {
        errorToast('An error occurred trying to create building');
      }
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <FormContentHeader
        mainText="Let's start with a name"
        secondaryText="Enter a unique name for your warehouse. Don't worry, we will double check before we move on. You can also optionally create the first shipper for your new building."
        stepNumber={1}
        totalSteps={6}
      />
      <Grid templateColumns="repeat(1, 1fr)" gap={2}>
        <GridItem colSpan={1}>
          <RHFInput name="name" label="Building Name" isRequired control={control} autoFocus />
        </GridItem>
        <GridItem colSpan={1}>
          <RHFSelect
            disabled={isLoading}
            name="providerId"
            label="Provider"
            options={providers}
            placeholder="Select a Provider"
            control={control}
            isRequired
          />
        </GridItem>
        <GridItem colSpan={1}>
          <RHFInput name="shipperName" label="Shipper Name" control={control} />
        </GridItem>
      </Grid>
      <FormButtons />
    </form>
  );
};

export default BuildingName;
