import type { Address } from '@store/services/api.generated';
import type { Options } from '@type-utils';

export const addressTypeOptions: Options<NonNullable<Address['type']>> = [
  {
    label: 'Commercial',
    value: 'commercial',
  },
  {
    label: 'Residential',
    value: 'residential',
  },
];
