import type { FormEventHandler } from 'react';
import { useState } from 'react';
import { useDownloadItemsFileMutation, usePostItemsUploadMutation } from '@store/services/api';
import { useToast } from '@hooks';
import { FormButtons, FormContentHeader } from '@components/multi-step-form';
import type { MultiStepFormPageProps } from '@components/multi-step-form';
import { UploadSection } from '../shared';
import type { CreateBuildingFormState } from '../CreateBuilding';

const secondaryTextNoShipper =
  'Looks like you skipped creating a shipper. Items and a shipper are both required to add inventory. You can skip this step to finish setup and add a shipper, items, and inventory later.';

const secondaryTextSkippedItemUpload =
  'Looks like you skipped adding items. If you want to add inventory, first you will need to go back and upload your items. You can skip this step to finish setup and add items and inventory later.';

const secondaryTextReadyToUpload =
  'Once you have locations and items, we can start to build out inventory. You can download a template file to get started, or upload if you have one ready. The locations and products should match anything you have already uploaded. Do not edit or remove the ID column.';

export const AddInventory = ({
  multiStepFormState,
  onBackClick,
  onSuccess,
}: MultiStepFormPageProps<CreateBuildingFormState>) => {
  const [downloadTrigger, { isLoading: isDownloadingLocations }] = useDownloadItemsFileMutation();
  const [uploadInventoryFile] = usePostItemsUploadMutation();

  const [selectedInventoryFile, setSelectedInventoryFile] = useState<File | undefined>(undefined);

  const { errorToast, successToast } = useToast();

  const handleUploadInventory: FormEventHandler = async (e) => {
    e.preventDefault();

    if (!selectedInventoryFile) {
      errorToast('Please select a file before trying to upload inventory.');
    } else {
      const formData = new FormData();
      formData.append('file', selectedInventoryFile);
      formData.append('type', 'text/csv');
      formData.append('title', selectedInventoryFile.name);
      try {
        await uploadInventoryFile({ body: formData }).unwrap();
        successToast('Inventory updated successfully.');
        onSuccess(multiStepFormState);
      } catch (err) {
        console.error(err);
        errorToast(
          'The upload failed. Please check your file for proper formatting and try again.'
        );
      }
    }
  };

  const onSkip = () => {
    successToast('Building creation complete');
    onSuccess(multiStepFormState);
  };

  const secondaryText = multiStepFormState?.didSkipItemUpload
    ? multiStepFormState?.shipperId
      ? secondaryTextNoShipper
      : secondaryTextSkippedItemUpload
    : secondaryTextReadyToUpload;

  return (
    <form onSubmit={handleUploadInventory}>
      <FormContentHeader
        mainText="Time to build out your inventory"
        secondaryText={secondaryText}
        stepNumber={6}
        totalSteps={6}
      />
      <UploadSection
        downloadFilename="inventory"
        downloadLabel="Inventory Template"
        downloadTrigger={downloadTrigger}
        isDisabled={!multiStepFormState?.shipperId || multiStepFormState?.didSkipItemUpload}
        isDownloading={isDownloadingLocations}
        selectedFile={selectedInventoryFile}
        setSelectedFile={setSelectedInventoryFile}
        uploadLabel="Upload Inventory"
      />
      <FormButtons
        allowBack
        allowSkip
        isSubmitDisabled={
          !multiStepFormState?.shipperId ||
          multiStepFormState?.didSkipItemUpload ||
          !selectedInventoryFile
        }
        onBackClick={onBackClick}
        submitLabel="Upload + Finish"
        onSkipClick={onSkip}
        skipLabel="Skip + Finish"
      />
    </form>
  );
};

export default AddInventory;
