import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  Tooltip,
  useColorModeValue as mode,
  AlertIcon,
} from '@chakra-ui/react';
import { RHFNumberInput, RHFSelect } from '@components/forms';
import { Loading, NoTranslate, WmsModalFooter } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useGetPickEquipmentGroupOptions,
  useGetZoneOptions,
  useSetFieldErrors,
  useToast,
} from '@hooks';
import { updateLocationSchema } from '@schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle as faInfoCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { useGetItemsQuery, usePatchLocationsByLocationIdMutation } from '@store/services/api';
import type {
  Item,
  Location,
  LocationType,
  LocationSubtype,
  Product,
} from '@store/services/api.generated';
import type { FieldErrorsImpl } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import type { Options, OptionValue } from '@type-utils';
import { formatLocationType } from '@utils';
import type { ActiveLocationType } from '@constants/locationTypes';
import { locationSubTypeOptions } from '@constants/locationTypes';

interface EditLocationProps {
  location: Location;
  onSuccess: () => void;
  onCancel: () => void;
}

type FormData = {
  location_sequence: number;
  active: boolean;
  type: string;
  subtype: string | null;
  pick_equipment_group_id: string | null;
  max_capacity?: number | null | string; // string is to allow default blank value
  replenishment_threshold?: number | null | string; // string is to allow default blank value
  zone_id?: string | null;
};

export const UPDATE_LOCATION_TYPES: LocationType[] = ['equipment', 'staging', 'storage', 'other'];

/**
 * Given a type or subtype, generates list of possible options
 * For `null` value, the actual option value used will be '', label will be `--`.
 */
const optionsFromTypes = (types: LocationType[]): Options<OptionValue> =>
  types.map((type) => {
    const label = formatLocationType(type);
    return { value: type ? type : '', label };
  });

const locationTypeOptions = optionsFromTypes(UPDATE_LOCATION_TYPES);

const ACTIVE_OPTIONS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Not Active' },
];

const isPatchLocationErrorDueToTypes = (
  formErrors: FieldErrorsImpl<{
    location_sequence: number;
    active: boolean;
    type: string;
    subtype: string;
  }>
): boolean => !!formErrors.subtype || !!formErrors.type;

const LabelWithTooltip = ({ label, toolTipText }: { label: string; toolTipText: string }) => (
  <HStack>
    <Text>{label}</Text>
    <Tooltip label={toolTipText} hasArrow>
      <span>
        <FontAwesomeIcon icon={faInfoCircleSolid} />
      </span>
    </Tooltip>
  </HStack>
);

export const EditLocationForm = ({ location, onSuccess, onCancel }: EditLocationProps) => {
  const { successToast, validationErrorToast } = useToast();
  const [patchLocation] = usePatchLocationsByLocationIdMutation({});
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { isDirty: isFormDirty, isSubmitting, errors: formErrors },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      location_sequence: location?.location_sequence ?? 0,
      active: location?.active ?? false,
      type: (location?.type ?? '').toLowerCase(),
      subtype: location?.subtype !== null ? (location?.subtype ?? '').toLowerCase() : undefined,
      pick_equipment_group_id: location?.pick_equipment_group_id ?? '',
      max_capacity: location?.max_capacity !== null ? location?.max_capacity : '',
      replenishment_threshold:
        location?.replenishment_threshold !== null ? location?.replenishment_threshold : '',
      zone_id: location?.zone?.id ?? '',
    },
    resolver: yupResolver(updateLocationSchema),
  });

  const setFieldErrors = useSetFieldErrors({ setError, fields: updateLocationSchema.fields });

  useEffect(() => {
    setValue('location_sequence', location?.location_sequence ?? 0);
    setValue('active', location?.active ?? false);
    setValue('type', (location?.type ?? '').toLowerCase());
    setValue('subtype', (location?.subtype ?? '').toLowerCase());
    setValue('pick_equipment_group_id', location?.pick_equipment_group_id ?? '');
  }, [setValue, location]);

  const selectedType = watch('type') as ActiveLocationType;
  const selectedSubType = watch('subtype') as NonNullable<LocationSubtype> | '';
  const isForwardStorageLocation =
    selectedType === 'storage' && (selectedSubType ?? '') === 'forward';

  const { isLoading: isLoadingPickEquipmentGroups, pickEquipmentGroupOptions } =
    useGetPickEquipmentGroupOptions({
      showBlankOption: true,
    });

  const { isLoading: isLoadingZones, zoneOptions } = useGetZoneOptions({
    showBlankOption: true,
  });

  const { data: forwardStorageLocationItems, isLoading } = useGetItemsQuery(
    {
      locationIds: location?.id!,
      pageSize: 50,
    },
    {
      skip: !location || !isForwardStorageLocation,
    }
  );

  const uniqueProductsInForwardLocation: Product[] = (
    forwardStorageLocationItems?.data ?? []
  ).reduce((acc: Product[], curr: Item) => {
    const isExisting = acc.find((product) => product?.sku === curr?.product?.sku);

    if (isExisting || !curr.product) {
      return acc;
    }

    return [...acc, curr.product];
  }, []);

  const locationSubtypeOptions = useMemo(() => {
    return [{ label: '--', value: '' }, ...locationSubTypeOptions[selectedType]] ?? [];
  }, [selectedType]);

  // reset subtypes if type changes by user, and give it '' value for unselected value
  useEffect(() => {
    if (selectedType !== location?.type) {
      setValue('subtype', '');
    }
  }, [selectedType, setValue, location?.type]);

  const onSubmit = handleSubmit(async (updatedLocation) => {
    const updatedLocationType = (updatedLocation?.type ?? '').toLowerCase() as LocationType;

    try {
      await patchLocation({
        locationId: location.id!,
        location: {
          location_sequence: updatedLocation?.location_sequence!,
          active: updatedLocation?.active!,
          type: updatedLocationType,
          // if subtype has '', it means an explicit intention to nullify (e.g. location -> other type change)
          // without this, subtype doesn't get cleaned up
          subtype: (updatedLocation?.subtype === ''
            ? null
            : updatedLocation?.subtype) as LocationSubtype,
          // Unset pick equipment group if type is not equipment
          pick_equipment_group_id:
            updatedLocationType !== 'equipment' || updatedLocation?.pick_equipment_group_id === ''
              ? null
              : updatedLocation?.pick_equipment_group_id,
          max_capacity:
            isForwardStorageLocation && updatedLocation?.max_capacity
              ? Number(updatedLocation?.max_capacity)
              : null,
          replenishment_threshold:
            isForwardStorageLocation && updatedLocation?.replenishment_threshold
              ? Number(updatedLocation?.replenishment_threshold)
              : null,
          zone_id: updatedLocation?.zone_id === '' ? null : updatedLocation?.zone_id,
        },
      }).unwrap();
      successToast('Location updated');
      onSuccess();
    } catch (err) {
      const didSetErrors = setFieldErrors(err);
      if (!didSetErrors) {
        validationErrorToast(err, {
          defaultMessage: 'An error occurred trying to update location',
        });
      }
    }
  });

  const shouldShowInventoryWarningMessage = isPatchLocationErrorDueToTypes(formErrors);

  const resetReplenFields = () => {
    setValue('max_capacity', '', { shouldDirty: true });
    setValue('replenishment_threshold', '', { shouldDirty: true });
  };

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      {(selectedType !== location?.type || selectedSubType !== location?.subtype) && (
        <Alert status="warning">
          <AlertIcon>
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </AlertIcon>
          <AlertDescription>
            Changing location type or subtype can negatively impact in flight orders.
          </AlertDescription>
        </Alert>
      )}
      <Stack w="full">
        <Flex alignItems="baseline">
          <Box flex={1} mr={4}>
            <RHFSelect
              control={control}
              name="active"
              label="Location State"
              options={ACTIVE_OPTIONS}
            />
          </Box>
          <Box flex={1}>
            <RHFNumberInput
              control={control}
              name="location_sequence"
              label="Location Sequence"
              min={1}
            />
          </Box>
        </Flex>
        <RHFSelect
          control={control}
          name="zone_id"
          label="Zone"
          options={zoneOptions}
          disabled={isLoadingZones}
        />
        <RHFSelect
          control={control}
          name="type"
          label="Type"
          data-testid="select-type"
          options={locationTypeOptions}
        />
        {selectedType === 'equipment' ? (
          <RHFSelect
            control={control}
            name="pick_equipment_group_id"
            label="Pick Equipment Group"
            data-testid="select-pick-equipment-group"
            options={pickEquipmentGroupOptions}
            disabled={isLoadingPickEquipmentGroups}
          />
        ) : (
          <RHFSelect
            control={control}
            name="subtype"
            label="Subtype"
            data-testid="select-subtype"
            options={locationSubtypeOptions}
            disabled={locationSubtypeOptions.length === 0}
          />
        )}

        {isForwardStorageLocation && (
          <>
            <Divider my={3} />
            <Heading as="h3" fontSize="md">
              Replenishment Settings
            </Heading>
            <HStack alignItems="flex-start" spacing={4}>
              <RHFNumberInput
                control={control}
                name="max_capacity"
                label={
                  <LabelWithTooltip
                    label="Capacity"
                    toolTipText="Capacity always refers to the primary unit of measure for the SKU in that location"
                  />
                }
                min={1}
              />

              <RHFNumberInput
                control={control}
                name="replenishment_threshold"
                label={
                  <LabelWithTooltip
                    label="Threshold"
                    toolTipText="Threshold value to trigger replenishment when inventory falls under this value. Value follows primary unit of measure for the SKU in that location"
                  />
                }
                min={1}
              />
            </HStack>
            <HStack spacing={4}>
              <Button
                variant="link"
                onClick={resetReplenFields}
                data-testid="reset-replenishment-fields"
              >
                Reset replenishment fields
              </Button>
            </HStack>
            <Loading h="3rem" isLoaded={!isLoading}>
              {uniqueProductsInForwardLocation.length > 0 && (
                <Alert flexDirection="column" alignItems="flex-start" status="info">
                  <HStack alignItems="flex-start">
                    <AlertIcon>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </AlertIcon>
                    <Stack spacing={0}>
                      <AlertTitle fontSize="sm">{`SKU / Name in Location (${uniqueProductsInForwardLocation.length})`}</AlertTitle>
                      <AlertDescription fontSize="sm">
                        {uniqueProductsInForwardLocation.map((product) => {
                          return (
                            <HStack key={product.id}>
                              <NoTranslate>{product.sku}</NoTranslate>
                              <Text color="gray.800">&#8226;</Text>
                              <NoTranslate>{product.name}</NoTranslate>
                            </HStack>
                          );
                        })}
                      </AlertDescription>
                    </Stack>
                  </HStack>
                </Alert>
              )}
            </Loading>
          </>
        )}
      </Stack>
      <WmsModalFooter flexDirection="column" alignItems="flex-end">
        {shouldShowInventoryWarningMessage && (
          <Alert
            status="warning"
            py={2}
            mb={7}
            bg={mode('orange.50', 'orange.100')}
            color="gray.800"
            borderRadius={4}
            data-testid="inventory-warning-alert"
          >
            <Box mr={2} color={mode('orange.500', 'orange.600')}>
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </Box>
            Inventory in location limits editing capabilities.
          </Alert>
        )}
        <ButtonGroup>
          <Button variant="outline" colorScheme="gray" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            data-testid="confirm"
            onClick={onSubmit}
            isLoading={isSubmitting}
            isDisabled={!isFormDirty}
          >
            Save
          </Button>
        </ButtonGroup>
      </WmsModalFooter>
    </form>
  );
};
