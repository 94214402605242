import { createAction } from '@reduxjs/toolkit';

// Extracted from: https://github.com/phoenixframework/phoenix/blob/master/assets/js/phoenix/constants.js
// As they aren't provided by the definitely typed version
const builtinChannelEvents = [
  'chan_reply',
  'phx_join',
  'phx_reply',
  'phx_error',
  'phx_close',
  'phx_leave',
] as const;

type BuiltinChannelEvent = (typeof builtinChannelEvents)[number];

export const isBuiltinChannelEvent = (event: string = ''): event is BuiltinChannelEvent =>
  builtinChannelEvents.some((key) => (event || '').startsWith(key));

export const broadcastEvent = createAction<{ event: string; payload: unknown }>('channelEvent');
