import { Grid, GridItem } from '@chakra-ui/react';
import { RHFInput, RHFSelect } from '@components/forms';
import { usStatesOptions } from '@constants/usStates';
import type { Control } from 'react-hook-form';
import type { BuildingFormFields } from '@schemas';
import { useGetTimezonesQuery } from '@store/services/api';
import { useMemo } from 'react';
import { caProvinceOptions } from '@constants/canadianProvinces';
import { useWatch } from 'react-hook-form';
import type { Options } from '@type-utils';

type StateProvinceOption = {
  placeholder: string;
  options: Options<string>;
};

const stateProvinceOptions: { [countryCode: string]: StateProvinceOption } = {
  us: {
    placeholder: 'State',
    options: usStatesOptions,
  },
  ca: {
    placeholder: 'Province',
    options: caProvinceOptions,
  },
};

type BuildingAddressFormFieldsProps = {
  autoFocus?: boolean;
  defaultValues?: {
    state?: string;
    timezone?: string;
  };
  formControl: Control<BuildingFormFields, Object>;
};

export const BuildingAddressFormFields = ({
  autoFocus = false,
  defaultValues,
  formControl,
}: BuildingAddressFormFieldsProps) => {
  const countryCode = useWatch({ control: formControl, name: 'country' });

  const {
    data: timezoneData,
    isLoading: isLoadingTimezones,
    isError: isErrorTimezones,
  } = useGetTimezonesQuery();

  const timezoneOptions = useMemo(
    () =>
      (timezoneData?.data ?? [])
        // As of now, US and Canadian timezones are supported; filter out non-American timezones to reduce noise
        .filter((tz) => tz.startsWith('America/'))
        .map((tz) => ({
          // Remove underscores from IANA names, e.g. "America/New_York" -> "America/New York"
          label: tz.replace(/_/g, ' '),
          value: tz,
        })),
    [timezoneData]
  );

  const stateProvinceConfig = useMemo(() => {
    const countryKey = countryCode?.toLowerCase();

    return countryKey && countryKey in stateProvinceOptions
      ? stateProvinceOptions[countryKey]
      : stateProvinceOptions.us;
  }, [countryCode]);

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={2}>
      <GridItem colSpan={{ base: 6, md: 3 }}>
        <RHFInput name="line1" label="Address 1" control={formControl} autoFocus={autoFocus} />
      </GridItem>
      <GridItem colSpan={{ base: 6, md: 3 }}>
        <RHFInput name="line2" label="Address 2" control={formControl} />
      </GridItem>
      <GridItem colSpan={{ base: 6, md: 2 }}>
        <RHFInput name="city" label="City" control={formControl} />
      </GridItem>
      <GridItem colSpan={{ base: 6, md: 2 }}>
        <RHFSelect
          defaultValue={defaultValues?.state ?? ''}
          name="state"
          label="State/Province"
          placeholder={stateProvinceConfig.placeholder}
          options={stateProvinceConfig.options}
          control={formControl}
        />
      </GridItem>
      <GridItem colSpan={{ base: 6, md: 2 }}>
        <RHFInput name="postal_code" label="ZIP/Postal" control={formControl} />
      </GridItem>
      <GridItem colSpan={{ base: 6, md: 3 }}>
        <RHFSelect
          name="country"
          label="Country"
          control={formControl}
          placeholder="Country"
          options={[
            { label: 'United States', value: 'US' },
            { label: 'Canada', value: 'CA' },
          ]}
        />
      </GridItem>
      <GridItem colSpan={{ base: 6, md: 3 }}>
        <RHFSelect
          defaultValue={defaultValues?.timezone ?? ''}
          name="timezone"
          label="Timezone"
          placeholder="Timezone"
          options={timezoneOptions}
          control={formControl}
          disabled={isLoadingTimezones || isErrorTimezones}
        />
      </GridItem>
    </Grid>
  );
};
