import { useColorModeValue as mode, Stack, Text } from '@chakra-ui/react';
import type { ReactNode } from 'react';

export const FormContentHeader = ({
  mainText,
  secondaryText,
  stepNumber,
  totalSteps,
}: {
  mainText: ReactNode;
  secondaryText?: ReactNode;
  stepNumber: number;
  totalSteps: number;
}) => {
  return (
    <Stack borderBottom="1px solid" borderColor={mode('gray.200', 'whiteAlpha.300')} pb={4} mb={4}>
      <Text fontSize="xs" variant="lighter">
        {`Step ${stepNumber}/${totalSteps}`}
      </Text>
      <Text fontSize="xl" fontWeight="bold">
        {mainText}
      </Text>
      <Text fontSize="xs" variant="lighter">
        {secondaryText}
      </Text>
    </Stack>
  );
};

export default FormContentHeader;
