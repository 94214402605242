import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue as mode,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { faMagnifyingGlass, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaStar, FaHistory } from 'react-icons/fa';
import { BiBarcodeReader } from 'react-icons/bi';
import { BsLightningFill } from 'react-icons/bs';
import { ChakraAutocomplete, WmsModal } from '@components';
import { useEffect, useState, useMemo } from 'react';
import { buildUPCScanResult } from '@mocks/scannerData';
import { UNIQUE_SCANNER_CALLBACK_FN_NAME } from '@features/scanner/hooks';
import {
  useGetLicensePlatesQuery,
  useGetLocationsQuery,
  useGetParcelsQuery,
  useGetProductsQuery,
} from '@store/services/api';
import { useDebounce } from '@hooks';
import { useAppSelector } from '@store/hooks';
import {
  selectCurrentScannableEntity,
  useLocalStorageList,
  QuickPickButton,
  selectCurrentQuickScanMetadata,
  QuickPickHelper,
} from '@features/scanner/quick-scan';
import type { SearchEntity } from '@features/scanner/quick-scan';
import { getEnvironment, getAutocompleteOptionsForProducts } from '@utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';

const shouldShowGenerateLPNButton = ['staging', 'development'].includes(getEnvironment());

export interface AutocompleteOption {
  value: string;
  label: string;
  type: SearchEntity;
  isFavorite: boolean;
  isRecent: boolean;
  badge?: { message: string; color?: string } | false;
}

type SearchType = 'startsWith' | 'endsWith' | 'contains' | 'exact';

export function QuickScan() {
  const { quickScan: quickScanEnabled } = useFlags();
  const [selectedSearchEntity, setSelectedSearchEntity] = useState<SearchEntity>('location');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [suggestedValues, setSuggestedValues] = useState<AutocompleteOption[]>([]);
  const [searchType, setSearchType] = useState('contains');
  const debouncedSearch = useDebounce(searchInputValue, 300);
  const hasSearchValue = debouncedSearch.replace(/%/g, '') !== '';

  const { onOpen, onClose, isOpen } = useDisclosure();

  const {
    addItem: addRecentEntity,
    removeItem: removeRecentEntity,
    list: recentEntities,
    resetListForBuilding: resetRecentEntities,
  } = useLocalStorageList<AutocompleteOption>('recentEntity', { limit: 5 });

  const {
    addItem: addFavoriteEntity,
    removeItem: removeFavoriteEntity,
    list: favoriteEntities,
    resetListForBuilding: resetFavoriteEntities,
  } = useLocalStorageList<AutocompleteOption>('favoriteEntity', {
    limit: 5,
  });

  const storeSearchEntity = useAppSelector(selectCurrentScannableEntity);
  const quickScanMetadata = useAppSelector(selectCurrentQuickScanMetadata);
  const showPickHelper =
    (!!quickScanMetadata.product && !!quickScanMetadata.location) ||
    selectedSearchEntity === 'suggestedPickingLpn';

  useEffect(() => {
    setSelectedSearchEntity(storeSearchEntity);
  }, [storeSearchEntity]);

  useEffect(() => {
    if (selectedSearchEntity === 'productBarcode' && !!quickScanMetadata.product) {
      const barcodes: AutocompleteOption[] = (quickScanMetadata.product?.barcodes ?? []).map(
        (barcode) => {
          return {
            value: barcode.barcode_value!,
            label: `${barcode.barcode_value} (${barcode.name})`,
            type: 'productBarcode',
            isFavorite: false,
            isRecent: false,
            badge: { message: 'SUGGESTED', color: 'green' },
          };
        }
      );

      setSuggestedValues(barcodes);
    } else {
      setSuggestedValues([]);
    }
  }, [quickScanMetadata, selectedSearchEntity, setSuggestedValues]);

  const getTabForSearchEntity = (type: SearchEntity) => {
    const entityToIndexMap: Record<SearchEntity, number> = {
      location: 0,
      lpn: 1,
      productBarcode: 2,
      shippingLabel: 3,
      suggestedPickingLpn: 4,
    };

    return entityToIndexMap[type];
  };

  const handleTabClick = (index: number) => {
    let entity: SearchEntity = 'location';

    switch (index) {
      case 0:
        entity = 'location';
        break;
      case 1:
        entity = 'lpn';
        break;
      case 2:
        entity = 'productBarcode';
        break;
      case 3:
        entity = 'shippingLabel';
        break;
      case 4:
        entity = 'suggestedPickingLpn';
        break;
    }

    setSelectedSearchEntity(entity);
    setSearchInputValue('');
  };

  const handleKeyDown = useMemo(() => {
    return (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
        if (!isOpen) {
          onOpen();
          datadogRum.addAction('quick_scan_opened');
        } else {
          onClose();
        }
      }
    };
  }, [isOpen, onOpen, onClose]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const scanAndClose = (option: AutocompleteOption) => {
    addRecentEntity(option);

    (window as any)[UNIQUE_SCANNER_CALLBACK_FN_NAME]?.(buildUPCScanResult(option.value));

    setSearchInputValue('');
    onClose();
  };

  const scanNewLPN = (value?: string) => {
    // generate and scan a new LPN in the format: LPN00000098373
    const randomNumber = Math.floor(Math.random() * 100000);
    const paddedString = `${randomNumber}`.padStart(10, '0');
    const lpnString = value ?? `LPN${paddedString}`;

    const option: AutocompleteOption = {
      label: lpnString,
      value: lpnString,
      type: 'lpn',
      isRecent: true,
      isFavorite: false,
    };

    scanAndClose(option);
  };

  const debouncedSearchParameter = useMemo(() => {
    switch (searchType) {
      case 'startsWith':
        return `${debouncedSearch}%`;
      case 'contains':
        return `%${debouncedSearch}%`;
      case 'endsWith':
        return `%${debouncedSearch}`;
      case 'exact':
        return debouncedSearch;
    }
  }, [searchType, debouncedSearch]);

  const { data: locationsData, isFetching: isLocationsLoading } = useGetLocationsQuery(
    {
      search: debouncedSearchParameter,
    },
    {
      skip: !hasSearchValue || selectedSearchEntity !== 'location',
    }
  );
  const { data: lpnData, isFetching: isLpnsLoading } = useGetLicensePlatesQuery(
    {
      search: debouncedSearchParameter,
      active: 'all',
      empty: 'all',
    },
    {
      skip: !hasSearchValue || selectedSearchEntity !== 'lpn',
    }
  );
  const { data: productData, isFetching: isProductsLoading } = useGetProductsQuery(
    {
      search: debouncedSearchParameter,
    },
    {
      skip: !hasSearchValue || selectedSearchEntity !== 'productBarcode',
    }
  );
  const { data: shippingLabelData, isFetching: isShippingLabelsLoading } = useGetParcelsQuery(
    {
      trackingNumber: debouncedSearchParameter,
      hasTrackingNumber: true,
    },
    {
      skip: !hasSearchValue || selectedSearchEntity !== 'shippingLabel',
    }
  );

  const locationOptions: AutocompleteOption[] =
    locationsData?.data?.map((location) => ({
      label: location.name!,
      badge: !location?.active && { message: 'INACTIVE', color: 'orange' },
      value: location.id!,
      type: 'location',
      isRecent: recentEntities.some(({ value }) => value === location.id),
      isFavorite: favoriteEntities.some(({ value }) => value === location.id),
    })) ?? [];

  const lpnOptions: AutocompleteOption[] =
    lpnData?.data?.map((licensePlate) => ({
      label: licensePlate.number!,
      badge: !licensePlate?.active && { message: 'INACTIVE', color: 'orange' },
      value: licensePlate.number!,
      isRecent: recentEntities.some(({ value }) => value === licensePlate.number),
      isFavorite: favoriteEntities.some(({ value }) => value === licensePlate.number),
      type: 'lpn',
    })) ?? [];

  const upcAutocompleteOptions = getAutocompleteOptionsForProducts(
    productData?.data ?? [],
    recentEntities,
    favoriteEntities
  );
  const upcOptions = [...suggestedValues, ...upcAutocompleteOptions];

  const shippingLabelOptions: AutocompleteOption[] =
    shippingLabelData?.data?.map((parcel) => ({
      label: parcel.tracking_number!,
      value: parcel.tracking_number!,
      isRecent: recentEntities.some(({ value }) => value === parcel.tracking_number),
      isFavorite: favoriteEntities.some(({ value }) => value === parcel.tracking_number),
      type: 'shippingLabel',
    })) ?? [];

  const searchEntityToResultsMap: Record<SearchEntity, AutocompleteOption[]> = {
    location: locationOptions,
    lpn: lpnOptions,
    suggestedPickingLpn: lpnOptions,
    productBarcode: upcOptions,
    shippingLabel: shippingLabelOptions,
  };

  const resetSavedScans = () => {
    if (window.confirm('Are you sure you want to reset your recent and favorite scan buttons?')) {
      resetFavoriteEntities();
      resetRecentEntities();
    }
  };

  const toggleFavorite = (selected: AutocompleteOption) => (e: any) => {
    e.preventDefault();

    if (!selected.isFavorite) {
      addFavoriteEntity(selected);
    } else {
      removeFavoriteEntity(selected);
    }

    e.stopPropagation();
  };

  const listItemRenderer = (selected: AutocompleteOption) => {
    return (
      <HStack alignItems="center">
        <Box
          as={FaStar}
          color={selected.isFavorite ? 'yellow.500' : 'gray.300'}
          onClick={toggleFavorite(selected)}
          aria-label={`Favorite ${selected.label}`}
          data-testid={`favorite-${selected.value}`}
          cursor="pointer"
        />
        <Text className="_no-translate" cursor="pointer" ml={2}>
          {selected.label}
        </Text>
        {selected.badge && (
          <Badge
            data-testid={`inactive-badge-${selected.label}`}
            colorScheme={selected.badge.color ?? 'blue'}
          >
            {selected.badge.message}
          </Badge>
        )}
      </HStack>
    );
  };

  const loadingItemRenderer = () => {
    return (
      <Box width="100%" alignItems="center" justifyContent="center">
        <Spinner color="blue.400" size="sm" />
      </Box>
    );
  };

  const isLoading =
    isLocationsLoading || isLpnsLoading || isProductsLoading || isShippingLabelsLoading;

  const items = hasSearchValue
    ? isLoading
      ? [{} as any]
      : searchEntityToResultsMap[selectedSearchEntity]
    : [];

  const itemRenderer = isLoading ? loadingItemRenderer : listItemRenderer;

  const listItemStyleProps = isLoading
    ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    : {};

  if (!quickScanEnabled) return null;
  const showAutocompleteSearch = ['location', 'lpn', 'productBarcode', 'shippingLabel'].includes(
    selectedSearchEntity
  );
  const getPlaceholderName = (entity: SearchEntity) => {
    switch (entity) {
      case 'productBarcode':
        return 'product';
      case 'shippingLabel':
        return 'shipping label';
      default:
        return entity;
    }
  };

  const autocompletePlaceHolder = getPlaceholderName(selectedSearchEntity);
  const tabColor = mode('gray.800', 'darkmode.gray.200');
  const tabSelectedBgColor = mode('blue.500', 'blue.300');
  const tabSelectedTextColor = mode('white', 'darkmode.gray.900');
  const favoritesHeaderColor = mode('gray.900', 'darkmode.gray.100');

  const searchListBackground = mode('white', 'darkmode.gray.700');
  const searchListBoxShadow = mode('md', 'dark-lg');
  const searchListProps = !hasSearchValue
    ? {
        border: 'none',
      }
    : {
        background: searchListBackground,
        boxShadow: searchListBoxShadow,
      };

  return (
    <Box>
      <Tooltip hasArrow label="Search and scan LPNs and Locations">
        <Button
          data-testid="quick-scan-menu-button"
          position="absolute"
          zIndex={2}
          bottom={4}
          paddingX={3}
          right={4}
          bg={mode('blue.200', 'blue.500')}
          color={mode('blue.500', 'blue.200')}
          shadow="lg"
          onClick={() => {
            onOpen();
            datadogRum.addAction('quick_scan_opened');
          }}
          _hover={{ bg: mode('black', 'gray.800'), color: 'stord.volt' }}
          transition="all 0.4s"
        >
          <BsLightningFill />
          <BiBarcodeReader size="20px" style={{ marginLeft: '2px' }} />
        </Button>
      </Tooltip>
      <WmsModal
        title="QuickScan"
        isOpen={isOpen}
        onClose={() => {
          setSearchInputValue('');
          onClose();
        }}
        modalHeaderProps={{ display: 'none' }}
        modalContentProps={{
          minWidth: { base: '90%', md: '60%', lg: '50%', xl: '45%' },
        }}
      >
        <Stack
          direction="row"
          flexWrap={{ base: 'wrap-reverse' }}
          align="center"
          mt={2}
          paddingRight={{ base: 8, lg: 10 }}
          gridRowGap={4}
        >
          <Tabs
            size="sm"
            variant="solid"
            onChange={handleTabClick}
            defaultIndex={getTabForSearchEntity(selectedSearchEntity)}
          >
            <TabList>
              <Tab
                color={tabColor}
                mx={1}
                borderRadius={4}
                fontWeight="semibold"
                _selected={{
                  bg: tabSelectedBgColor,
                  color: tabSelectedTextColor,
                }}
                _focus={{
                  outlineWidth: '0px',
                }}
              >
                Locations
              </Tab>
              <Tab
                color={tabColor}
                mx={1}
                borderRadius={4}
                fontWeight="semibold"
                _selected={{
                  bg: tabSelectedBgColor,
                  color: tabSelectedTextColor,
                }}
                _focus={{
                  outlineWidth: '0px',
                }}
              >
                LPNs
              </Tab>
              <Tab
                color={tabColor}
                mx={1}
                borderRadius={4}
                fontWeight="semibold"
                _selected={{
                  bg: tabSelectedBgColor,
                  color: tabSelectedTextColor,
                }}
                _focus={{
                  outlineWidth: '0px',
                }}
              >
                Barcodes
              </Tab>
              <Tab
                color={tabColor}
                mx={1}
                borderRadius={4}
                fontWeight="semibold"
                _selected={{
                  bg: tabSelectedBgColor,
                  color: tabSelectedTextColor,
                }}
                _focus={{
                  outlineWidth: '0px',
                }}
              >
                Shipping Label
              </Tab>
              {showPickHelper && (
                <Tab
                  color={tabColor}
                  mx={1}
                  borderRadius={4}
                  fontWeight="semibold"
                  _selected={{
                    bg: tabSelectedBgColor,
                    color: tabSelectedTextColor,
                  }}
                  _focus={{
                    outlineWidth: '0px',
                  }}
                >
                  Pick Helper
                </Tab>
              )}
            </TabList>
          </Tabs>
          <Flex direction="row" gridGap={4} flex={1} align="center" justifyContent="flex-end">
            {shouldShowGenerateLPNButton ? (
              <Button variant="link" size="sm" onClick={() => scanNewLPN()}>
                Generate LPN
              </Button>
            ) : null}
            <Button variant="link" size="sm" onClick={resetSavedScans}>
              Reset Saved Scans
            </Button>
          </Flex>
        </Stack>
        {showAutocompleteSearch && (
          <>
            <ChakraAutocomplete
              optionFilterFunc={(i, inputValue) => {
                setSearchInputValue(inputValue.trim());
                return i;
              }}
              placeholder={`Search for a ${autocompletePlaceHolder} to scan`}
              items={items}
              listStyleProps={searchListProps}
              highlightItemBg={mode('blue.50', 'darkmode.gray.500')}
              listItemStyleProps={listItemStyleProps}
              onSelectedItemsChange={(changes) => {
                const selectedItems = changes?.selectedItems || [];
                if (selectedItems.length > 0 && selectedItems[0].value) {
                  scanAndClose(selectedItems[0]);
                }
              }}
              renderCustomInput={(inputProps) => {
                return (
                  <InputGroup my={3}>
                    <InputLeftElement>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </InputLeftElement>
                    <Input
                      {...inputProps}
                      autoFocus
                      borderTopRightRadius={0}
                      borderBottomRightRadius={0}
                    />
                    <Select
                      defaultValue={searchType}
                      onChange={(event) => setSearchType(event.target.value as SearchType)}
                      borderTopLeftRadius={0}
                      borderBottomLeftRadius={0}
                      w="200px"
                      data-testid="search-type-filter"
                    >
                      <option value="startsWith">Starts With</option>
                      <option value="contains">Contains</option>
                      <option value="endsWith">Ends With</option>
                      <option value="exact">Exact</option>
                    </Select>
                  </InputGroup>
                );
              }}
              isSearching={isLoading}
              itemRenderer={itemRenderer}
              selectedItems={[]}
              disableCreateItem
              hideToggleButton
            />

            {hasSearchValue && items?.length === 0 && selectedSearchEntity === 'lpn' && (
              <Alert status="warning">
                <AlertIcon>
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </AlertIcon>

                <HStack width="full" justifyContent="space-between">
                  <Stack spacing={1}>
                    <AlertTitle>LPN entered does not currently exist</AlertTitle>
                    <AlertDescription>
                      Would you like to proceed with <b>{searchInputValue}</b>?
                    </AlertDescription>
                  </Stack>

                  <Button variant="primary" size="sm" onClick={() => scanNewLPN(searchInputValue)}>
                    Yes
                  </Button>
                </HStack>
              </Alert>
            )}
            {!hasSearchValue && (
              <Stack
                direction={{ base: 'column', md: 'row' }}
                justify="center"
                align={{ base: 'center', md: 'flex-start' }}
                spacing={{ base: 5, md: 10, lg: 16 }}
                mb={5}
              >
                <Box minWidth={44}>
                  <Flex direction="row" alignItems="center" mb={4} ml={2}>
                    <FaHistory size="15px" />
                    <Heading marginLeft={2} size="sm" color={favoritesHeaderColor}>
                      Recent Scans
                    </Heading>
                  </Flex>
                  <VStack align="flex-start" spacing={4}>
                    {recentEntities?.length > 0 ? (
                      recentEntities.map((selected, index) => {
                        return (
                          <QuickPickButton
                            key={`recent-${selected.value}-${index}`}
                            selected={selected}
                            onClick={scanAndClose}
                            onXClick={removeRecentEntity}
                          />
                        );
                      })
                    ) : (
                      <Text ml={2} variant="lighter">
                        No recent scans
                      </Text>
                    )}
                  </VStack>
                </Box>
                <Box minWidth={44}>
                  <Flex direction="row" alignItems="center" mb={4} ml={2}>
                    <FaStar size="15px" />
                    <Heading marginLeft={2} size="sm" color={favoritesHeaderColor}>
                      Favorites
                    </Heading>
                  </Flex>
                  <VStack align="flex-start" spacing={4}>
                    {favoriteEntities?.length > 0 ? (
                      favoriteEntities.map((selected, index) => {
                        return (
                          <QuickPickButton
                            key={`favorite-${selected.value}-${index}`}
                            selected={selected}
                            onClick={scanAndClose}
                            onXClick={removeFavoriteEntity}
                          />
                        );
                      })
                    ) : (
                      <Text ml={2} variant="lighter">
                        No favorites
                      </Text>
                    )}
                  </VStack>
                </Box>
              </Stack>
            )}
          </>
        )}
        {selectedSearchEntity === 'suggestedPickingLpn' && (
          <QuickPickHelper
            favoriteEntities={favoriteEntities}
            location={quickScanMetadata?.location!}
            product={quickScanMetadata?.product!}
            recentEntities={recentEntities}
            scanAndClose={scanAndClose}
          />
        )}
      </WmsModal>
    </Box>
  );
}
