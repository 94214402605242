import { Button, HStack } from '@chakra-ui/react';
import { RHFInput } from '@components/forms';
import type { Control } from 'react-hook-form';

type FormData = {
  name: string;
  abbreviation: string;
};

interface AddUomFormProps {
  formControl: Control<FormData, Object>;
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const AddUomForm = ({ formControl, isSubmitting, onCancel, onSubmit }: AddUomFormProps) => (
  <form onSubmit={onSubmit}>
    <HStack>
      <RHFInput
        control={formControl}
        name="name"
        label="New unit of measure name"
        hideLabel
        placeholder="Box"
        autoFocus
        w={32}
        variant="flushed"
        formControlProps={{ w: 'fit-content' }}
      />

      <RHFInput
        control={formControl}
        name="abbreviation"
        label="New unit of measure abbreviation"
        hideLabel
        placeholder="bx"
        w={32}
        variant="flushed"
        formControlProps={{ w: 'fit-content' }}
      />

      <Button variant="primary" type="submit" fontSize="sm" isLoading={isSubmitting} w={16}>
        Save
      </Button>
      <Button fontSize="sm" colorScheme="red" variant="ghost" w={16} onClick={onCancel}>
        Cancel
      </Button>
    </HStack>
  </form>
);
