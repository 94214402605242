import { FormLabel } from '@chakra-ui/react';
import type { FormLabelProps } from '@chakra-ui/react';
import type { ReactNode } from 'react';

interface FilterProps extends FormLabelProps {
  label: ReactNode;
}

export const FilterLabel = ({ label, ...props }: FilterProps) => {
  return (
    <FormLabel as="label" alignItems="start" fontWeight="semibold" fontSize="sm" mb={1} {...props}>
      {label}
    </FormLabel>
  );
};
