import { hasValidationErrors, isFetchBaseQueryError } from '@store/services/utils';
import { useCallback } from 'react';
import type { FieldValues, UseFormSetError } from 'react-hook-form';

export const useSetFieldErrors = <FormData extends FieldValues = FieldValues>({
  setError,
  fields,
}: {
  setError: UseFormSetError<FormData>;
  fields?: Record<string, unknown>; // yup schema fields
}) => {
  return useCallback(
    (err: unknown) => {
      let addedErrors = 0;
      if (isFetchBaseQueryError(err)) {
        if (hasValidationErrors<FormData>(err.data)) {
          for (const validationError of err.data.errors) {
            if (!fields || validationError.name in fields) {
              setError(validationError.name as any, {
                type: 'server',
                message: validationError.message,
              });
              addedErrors++;
            }
          }
        }
      }

      return addedErrors > 0;
    },
    [setError, fields]
  );
};
