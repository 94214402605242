import {
  CheckmarkCircle,
  SearchFieldWithTypeSelector,
  TableActionBar,
  TableContent,
  WmsModal,
} from '@components';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectCurrentBuildingId } from '@features/auth/authSlice';
import { useState } from 'react';
import { useDebounce, usePaginatedQuery, useToast, useUrlState } from '@hooks';
import {
  api,
  useGetBuildingsQuery,
  useLazyGetBuildingsByBuildingIdQuery,
} from '@store/services/api';
import type { Building } from '@store/services/api.generated';
import { setBuildingId } from '@features/auth/actions';
import { Button, HStack, useDisclosure, Wrap } from '@chakra-ui/react';
import { CreateBuilding } from './CreateBuilding/CreateBuilding';
import { EditBuilding } from './EditBuilding';

const BuildingsTable = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const currentBuildingId = useAppSelector(selectCurrentBuildingId);
  const [getBuilding] = useLazyGetBuildingsByBuildingIdQuery();
  const [buildingNameFilter, setBuildingNameFilter] = useUrlState<string>('search', '');
  const debouncedNameFilter = useDebounce(buildingNameFilter);
  const [buildingToEdit, setBuildingToEdit] = useState<Building>();
  const { onOpen: onCreateOpen, onClose: onCreateClose, isOpen: isCreateOpen } = useDisclosure();
  const { onOpen: onEditOpen, onClose: onEditClose, isOpen: isEditOpen } = useDisclosure();

  const { data, isLoading, isFetching, isError, page, onPageChange } = usePaginatedQuery(
    'getBuildings',
    useGetBuildingsQuery,
    { sort: 'name:asc', ...(debouncedNameFilter ? { name: debouncedNameFilter } : {}) }
  );

  const changeBuilding = (building: Building) => {
    // clear api caches so we re-request all data for the new building.
    dispatch(api.util.resetApiState());
    dispatch(setBuildingId({ id: building.id }));

    // don't redirect back to home page from scanner flow so we don't leave the scanner flow
    const { pathname } = window.location;
    !pathname.startsWith('/scanner') && navigate('/');
  };

  const onEditSuccess = async () => {
    // refetch building data if you edited the current building to make sure we have correct name/timezone
    if (buildingToEdit?.id === currentBuildingId) {
      try {
        await getBuilding({ buildingId: currentBuildingId }).unwrap();
      } catch (e) {
        errorToast('Error retrieving updated building data. Refresh page before continuing');
      }
    }

    onEditClose();
  };

  const columns = [
    {
      Header: 'Name',
      accessor: '',
      Cell: (building: Building) => {
        const isSelectedBuilding = currentBuildingId === building.id;

        return (
          <HStack>
            <Button
              variant="link"
              onClick={() => {
                changeBuilding(building);
              }}
            >
              {building.name}
            </Button>
            {isSelectedBuilding ? <CheckmarkCircle data-testid="selected" mx="auto" /> : null}
          </HStack>
        );
      },
    },
    {
      Header: '',
      accessor: '',
      id: 'actions',
      disableSortBy: true,
      Cell: (building: Building) => {
        return (
          <Button
            variant="link"
            onClick={() => {
              setBuildingToEdit(building);
              onEditOpen();
            }}
          >
            EDIT
          </Button>
        );
      },
    },
  ];

  const tableData = data?.data || [];

  return (
    <>
      <TableActionBar>
        <Wrap justify="space-between" w="100%">
          <HStack w={{ base: '100%', md: 'inherit' }}>
            <SearchFieldWithTypeSelector
              placeholder="Search by building name"
              onInputChange={setBuildingNameFilter}
              searchValue={buildingNameFilter}
            />
          </HStack>
          <HStack>
            <Button variant="primary" onClick={onCreateOpen}>
              New Building
            </Button>
          </HStack>
        </Wrap>
      </TableActionBar>
      <TableContent
        columns={columns}
        data={tableData}
        isError={isError}
        isLoading={isLoading || isFetching}
        pageInfo={data?.meta ?? page}
        onPageChange={onPageChange}
      />
      <WmsModal isOpen={isEditOpen} onClose={onEditClose} title="Edit Building" size="2xl">
        <EditBuilding onSuccess={onEditSuccess} onClose={onEditClose} building={buildingToEdit!} />
      </WmsModal>
      <CreateBuilding isOpen={isCreateOpen} onCloseModal={onCreateClose} />
    </>
  );
};

export default BuildingsTable;
