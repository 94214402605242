const localStorageEnableScanSuffixKey = '__wms-enable-scan-suffix';
const localStorageScanSensitivityKey = '__wms-scan-suffix-sensitivity';

export const DEFAULT_SCAN_DELAY = 50;
export const MAX_SCAN_DELAY = 250;
export const MAX_SCAN_SENSITIVITY = MAX_SCAN_DELAY - DEFAULT_SCAN_DELAY;

export function getEnableScanSuffixFromLocalstorage(): boolean {
  return localStorage.getItem(localStorageEnableScanSuffixKey) === 'true';
}

export function setEnableScanSuffixInLocalStorage(enabled: boolean) {
  localStorage.setItem(localStorageEnableScanSuffixKey, `${enabled}`);
}

export function getScanSensitivityFromLocalstorage(): number | null {
  const scanSensitivity = localStorage.getItem(localStorageScanSensitivityKey);

  return scanSensitivity ? Number(scanSensitivity) : null;
}

export function getScanDelayFromLocalstorage(): number {
  const sensitivity = getScanSensitivityFromLocalstorage() ?? MAX_SCAN_SENSITIVITY;

  return MAX_SCAN_DELAY - sensitivity;
}

export function setScanSensitivityInLocalstorage(delay: number) {
  localStorage.setItem(localStorageScanSensitivityKey, `${delay}`);
}
