import { has, isNumber, isObject, isString } from 'lodash';
import type { DateFilterData, RangedValue } from '@components';

const UNSELECTED_VALUES = ['', 'all'];

export const isStringOptionSelected = (currentValue: string | undefined) => !!currentValue;
export const isNumberOptionSelected = (currentValue: number | undefined) => isNumber(currentValue);
export const isDropDownOptionSelected = (
  currentValue: string | number | readonly string[] | undefined,
  defaultValue?: string | number
) => {
  const unselectedValues = defaultValue ? [defaultValue] : UNSELECTED_VALUES;

  if (isString(currentValue) && unselectedValues.includes(currentValue.toLowerCase())) {
    return false;
  }

  return isString(currentValue);
};
export const isOneOrMoreMultiSelectOptionSelected = (currentValue: any[] | undefined) => {
  if (Array.isArray(currentValue)) {
    return currentValue.length > 0;
  }
  return false;
};
export const isDateRangeOptionSelected = (currentValue: DateFilterData | undefined) => {
  if (isObject(currentValue) && has(currentValue, 'startDate') && has(currentValue, 'endDate')) {
    return !!(currentValue?.startDate || currentValue?.endDate);
  }
  return false;
};
export const isRangedValueOptionSelected = (currentValue: RangedValue | undefined) => {
  if (isObject(currentValue) && has(currentValue, 'min') && has(currentValue, 'max')) {
    return !!(isNumber(currentValue?.min) || isNumber(currentValue?.max));
  }
  return false;
};

export const isMultiOrStringSelected = (currentValue: any[] | string | undefined) => {
  if (Array.isArray(currentValue)) {
    return currentValue.length > 0;
  }
  if (typeof currentValue === 'string') {
    return !!currentValue;
  }
  return false;
};
