import { Badge, Box, Flex, Link, Text } from '@chakra-ui/react';
import type { Product, Shipper } from '@store/services/api.generated';
import { useNavigate } from 'react-router-dom';
import { BarcodeBuddyResult } from './components';

export function ProductPreview({
  closeModal,
  product,
  shipper,
  handleClick = () => {},
}: {
  closeModal: () => void;
  product: Product;
  shipper?: Shipper;
  handleClick?: () => void;
}) {
  const navigate = useNavigate();

  const directLink = `/items/${encodeURIComponent(shipper?.name ?? '')}/${encodeURIComponent(
    product.sku ?? ''
  )}`;

  const badgeColor = product?.kit ? 'orange' : 'blue';
  const badgeText = product?.kit ? 'KIT' : 'ITEM';

  return (
    <BarcodeBuddyResult
      type="product"
      directLink={directLink}
      closeModal={closeModal}
      handleClick={handleClick}
    >
      <Box py={1}>
        <Flex alignItems="center" gridGap={4}>
          <Text
            as={Link}
            variant="unstyled"
            onClick={() => {
              handleClick();
              navigate(directLink);
              closeModal();
            }}
            fontSize="xl"
            fontWeight="bold"
            className="_no-translate"
          >
            {product?.name ?? '--'}
          </Text>
          <Badge colorScheme={badgeColor}>{badgeText}</Badge>
        </Flex>

        <Text fontSize="sm" variant="lighter">
          Shipper: {shipper?.name ?? '--'}
        </Text>
      </Box>
    </BarcodeBuddyResult>
  );
}
