import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type UrlState<T> = [T, (value: T) => void, { reset: () => void; hasDefaultValue: boolean }];

export function useUrlState<T>(
  queryParamName: string,
  defaultValue: T,
  queryParamSerializer?: (searchParams: URLSearchParams, val: T) => void,
  queryParamNormalizer?: (searchParams: URLSearchParams) => T | undefined,
  areParamsEqual?: (a: T | undefined, b: T | undefined) => boolean
): UrlState<T> {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramValue = useMemo(
    () =>
      queryParamNormalizer
        ? queryParamNormalizer(searchParams)
        : (searchParams.get(queryParamName) as unknown as T),
    [queryParamName, queryParamNormalizer, searchParams]
  );

  const [state, setState] = useState<T>(paramValue ?? defaultValue);

  const areStatesEqual = useCallback(
    (a: T | undefined, b: T | undefined) => {
      if (areParamsEqual) {
        return areParamsEqual(a, b);
      }

      return a === b;
    },
    [areParamsEqual]
  );

  useEffect(() => {
    if (paramValue === undefined || paramValue === null) {
      setState((s) => (!areStatesEqual(s, defaultValue) ? defaultValue : s));
    } else if (paramValue !== null && paramValue !== undefined) {
      setState((s) => (!areStatesEqual(s, paramValue) ? paramValue : s));
    }
  }, [areStatesEqual, defaultValue, paramValue]);

  const set = useCallback(
    (val: T) => {
      if (queryParamSerializer) {
        queryParamSerializer(searchParams, val);
      } else if (
        val === undefined ||
        val === null ||
        (val as any) === '' ||
        areStatesEqual(val, defaultValue)
      ) {
        searchParams.delete(queryParamName);
      } else {
        searchParams.set(queryParamName, val as any);
      }

      setState(val);
      setSearchParams(searchParams);
    },
    [
      areStatesEqual,
      defaultValue,
      queryParamName,
      queryParamSerializer,
      searchParams,
      setSearchParams,
    ]
  );

  const reset = useCallback(() => {
    const search = window.location.search;
    const searchParameters = new URLSearchParams(search);
    searchParameters.delete(queryParamName);

    setSearchParams(searchParameters);
    setState(defaultValue);
  }, [defaultValue, setSearchParams, queryParamName]);

  const hasDefaultValue = useMemo(
    () => areStatesEqual(state, defaultValue),
    [state, defaultValue, areStatesEqual]
  );

  return [state, set, { reset, hasDefaultValue }];
}
