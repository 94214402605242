import type { Options } from '@type-utils';
import type { LocationSubtype, LocationType } from '@store/services/api.generated';

export type ActiveLocationType = Exclude<LocationType, 'pending' | 'shipped'>;

export const locationTypeOptions: Options<ActiveLocationType> = [
  { value: 'staging', label: 'Staging' },
  { value: 'storage', label: 'Storage' },
  { value: 'equipment', label: 'Equipment' },
  { value: 'other', label: 'Other' },
];

export const locationSubTypeOptions: Record<
  ActiveLocationType,
  Options<NonNullable<LocationSubtype>>
> = {
  equipment: [],
  staging: [
    { value: 'assembly', label: 'Assembly' },
    { value: 'crossdock', label: 'Crossdock' },
    { value: 'dock_door', label: 'Dock Door' },
    { value: 'packstation', label: 'Packstation' },
    { value: 'returns', label: 'Returns' },
    { value: 'replenishment', label: 'Replenishment' },
  ],
  storage: [
    { value: 'forward', label: 'Forward' },
    { value: 'reserve', label: 'Reserve' },
  ],
  other: [
    { value: 'damaged', label: 'Damaged' },
    { value: 'lost', label: 'Lost' },
    { value: 'quarantine', label: 'Quarantine' },
    { value: 'triage', label: 'Triage' },
  ],
};
