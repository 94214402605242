import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import type { CenterProps } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isFetchBaseQueryError } from '@store/services/utils';
import { datadogRum } from '@datadog/browser-rum';
import { getBuildingIdFromLocalStorage } from '@utils';

export type GenericErrorProps = CenterProps & {
  error?: unknown;
  title?: string;
  showGoBackButton?: boolean;
  showRefreshButton?: boolean;
};

export const GenericError = ({
  error,
  title,
  children,
  showGoBackButton = true,
  showRefreshButton = true,
  ...centerProps
}: GenericErrorProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const pathname = window?.location?.pathname;

    datadogRum.addError(error, {
      baseRoute: pathname?.split('/')?.[1] ?? 'unknown',
      buildingId: getBuildingIdFromLocalStorage(),
      pathname: pathname ?? 'unknown',
      search: window?.location?.search ?? 'unknown',
    });
  }, [error]);

  const generalErrorPage = () => (
    <Center h="100vh" {...centerProps} data-testid="generic-error">
      <VStack spacing={12} w="100%">
        <Heading as="h3" size="md" pb={6}>
          {title || 'There was an error loading this content'}
        </Heading>
        <Box>{children || 'Please try again'}</Box>
        <HStack>
          {showGoBackButton && (
            <Box mr={8}>
              <Button variant="link" onClick={() => navigate(-1)}>
                Go back
              </Button>
            </Box>
          )}
          {showRefreshButton && (
            <Box>
              <Button variant="link" onClick={() => window?.location?.reload()}>
                Refresh
              </Button>
            </Box>
          )}
        </HStack>
      </VStack>
    </Center>
  );

  const notFoundPage = () => (
    <Flex
      direction={{ base: 'column-reverse', lg: 'row' }}
      data-testid="generic-error"
      alignItems="center"
    >
      <Spacer />
      <Box
        w={{ base: '360px', lg: '400px' }}
        alignItems="flex-start"
        pl="16px"
        pr="16px"
        mt={{ base: '-70px', lg: '0px' }}
        flexBasis={{ base: '300px', lg: '400px' }}
        flexShrink={0}
      >
        <Heading fontWeight="extrabold" fontSize={{ base: '100px', lg: '165px' }} m="0px">
          404
        </Heading>

        <Heading fontSize={{ base: '22px', lg: '30px' }} w="100%" pt="0px">
          The page is undeliverable.
        </Heading>

        <Box fontSize={{ base: '14px', lg: '16px' }} mb="16px" w="100%" pt="8px">
          Sorry for the trouble, but it looks like something got lost in the clouds. If you're still
          having trouble, reach out to us at&nbsp;
          <Link href="mailto:wmssupport@stord.com" _hover={{ textDecoration: 'none' }}>
            wmssupport@stord.com
          </Link>
          .
        </Box>
        <Button
          colorScheme="blue"
          onClick={() => navigate('/')}
          display={{ base: 'none', lg: 'flex' }}
          data-testid="button-return"
        >
          Return to Home Page
        </Button>
        <Button
          colorScheme="blue"
          onClick={() => navigate('/')}
          w="full"
          display={{ base: 'flex', lg: 'none' }}
          data-testid="button-return-mobile"
          marginTop="16px"
          marginBottom="16px"
        >
          Return to Home page
        </Button>
      </Box>
      <Spacer />
      <Image
        src="/404.png"
        w={{ lg: '773px', base: '360px' }}
        h={{ lg: '773px', base: '360px' }}
        mt={{ base: '-70px', lg: '0px' }}
        alignSelf={{ lg: 'flex-end', base: 'center' }}
      />
    </Flex>
  );

  return isFetchBaseQueryError(error) && error?.status === 404
    ? notFoundPage()
    : generalErrorPage();
};
