import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Checkbox as ChakraCheckbox,
} from '@chakra-ui/react';
import type { FormLabelProps, CheckboxProps as ChakraCheckboxProps } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { getFieldErrorMessage } from './utils';

export function RHFCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hideLabel,
  // Controller props
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  labelProps,
  ...checkboxProps
}: UseControllerProps<TFieldValues, TName> &
  ChakraCheckboxProps & {
    label: string;
    labelProps?: FormLabelProps;
    hideLabel?: boolean;
    readOnly?: boolean;
  }) {
  const { field, formState } = useController({
    defaultValue: false as any, // This is to prevent silly jest errors regarding controlled switching to uncontrolled errors
    name,
    rules,
    shouldUnregister,
    control,
  });

  return (
    <FormControl isInvalid={!!(formState.errors as any)[field.name]} id={field.name}>
      <FormLabel
        hidden={hideLabel}
        d="flex"
        flexDirection="row"
        align="center"
        htmlFor={`${field.name}_checkbox`}
        {...labelProps}
      >
        <ChakraCheckbox
          id={`${field.name}_checkbox`}
          isChecked={field.value}
          marginEnd={2}
          {...field}
          {...checkboxProps}
        />
        {label}
      </FormLabel>
      <FormErrorMessage>{getFieldErrorMessage(formState, field.name)}</FormErrorMessage>
    </FormControl>
  );
}
