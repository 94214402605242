import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from './services/api';
import { wsMiddleware } from './middleware';

import configReducer from '@features/config/configSlice';
import authReducer from '@features/auth/authSlice';
import realtimeReducer from '@features/realtime/realtimeSlice';
import quickScanReducer from '@features/scanner/quick-scan/quickScanSlice';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  config: configReducer,
  auth: authReducer,
  quickScan: quickScanReducer,
  realtime: realtimeReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // Adding the api middleware enables caching, invalidation, polling and other features of RTK Query
      getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware, wsMiddleware),
    preloadedState,
  });
};

export const store = setupStore();
// Call setupListeners to enable online & focused detection
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
