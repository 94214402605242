import { useState } from 'react';

const determineState = (activeIndex: number, index: number) => {
  if (activeIndex > index) return 'complete';
  if (activeIndex === index) return 'active';
  return 'incomplete';
};

export const useProgressState = <T extends Record<string, unknown>[]>(steps: T) => {
  const [activeStep, setActiveStep] = useState(0);
  const factor = steps.length - 1;
  return {
    activeStep,
    value: (100 / factor) * activeStep,
    getState: (index: number) => determineState(activeStep, index),
    setActiveStep,
  };
};
