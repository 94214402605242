import { useCallback, useMemo } from 'react';

export const ledColorMap = {
  Green: 1,
  Red: 2,
  Yellow: 3,
  Cyan: 4,
  Magenta: 5,
  Blue: 6,
  White: 7,
} as const;

export type LEDColor = keyof typeof ledColorMap;

const noop = () => {};

const logger = (...args: Parameters<(typeof console)['info']>) => {
  if (process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.debug(...args);
  }
};

const credentialsFile = '__wms_creds.txt';

function stubScannerUtils() {
  window._scanManager = {
    setReadCallback: (callbackFunction: string) => {
      logger('Would set a read callback for the fn name:', callbackFunction);
      return true;
    },
    clearReadCallback: noop,
    getConfig: noop,
    setConfig: noop,
    ___isMocked: true,
  } as any;
  window._notificationUtil = {
    startLed: noop,
    stopLed: noop,
    startBuzzer: noop,
    stopBuzzer: noop,
    startVibrator: noop,
    stopVibrator: noop,
  } as any;
  window._ftpClient = {
    ___isMocked: true,
  } as any;
  window._fileUtil = {
    ___isMocked: true,
  } as any;

  logger(
    'Required keyence scanner globals were not found on the window. Scanner functionality is only available on the Keyence Scanner Enterprise Browser.'
  );
  return true;
}

export function useKeyenceScanner() {
  const canUseKJS = (window._scanManager && window._notificationUtil) || stubScannerUtils();

  /**
   * @param color {LEDColor}
   * @param onPeriod {number} 1-5000 in ms
   * @param offPeriod {number} 1-5000 in ms
   * @param repeatCount {number} 1-10, On/Off repeat count
   * @returns {boolean} `true` = Success, `false` = Failure
   */
  const startLed = useCallback(
    (color: LEDColor, onPeriod: number, offPeriod: number, repeatCount: number) => {
      return window._notificationUtil.startLed(
        ledColorMap[color],
        onPeriod,
        offPeriod,
        repeatCount
      );
    },
    []
  );

  /**
   * Sounds the buzzer
   *
   * @param tone {number} 1-16 The tone goes from deeper (1) to higher pitched (16)
   * @param onPeriod {number} 1-5000 in ms
   * @param offPeriod {number} 1-5000 in ms
   * @param repeatCount {number} 1-10, On/Off repeat count
   * @returns {boolean} `true` = Success, `false` = Failure
   */
  const startBuzzer = useCallback(
    (...args: Parameters<(typeof window)['_notificationUtil']['startBuzzer']>) => {
      return window._notificationUtil.startBuzzer(...args);
    },
    []
  );

  const startVibrator = useCallback(
    (...args: Parameters<(typeof window)['_notificationUtil']['startVibrator']>) => {
      return window._notificationUtil.startVibrator(...args);
    },
    []
  );

  /**
   * Basic method used to fire off a red LED for 1 sec
   */
  const errorLed = useCallback(() => startLed('Red', 1000, 0, 1), [startLed]);
  /**
   * Basic method used to fire off a green LED for 1 sec
   */
  const successLed = useCallback(() => startLed('Green', 200, 0, 1), [startLed]);

  /**
   * Basic method used to fire off a friendly-ish beep
   */
  const successBuzz = useCallback(() => startBuzzer(7, 200, 0, 1), [startBuzzer]);

  /**
   * Basic method used to fire off a friendly-ish bzzz
   */
  const successVibrator = useCallback(() => startVibrator(200, 200, 1), [startVibrator]);

  /**
   * Filesystem management!
   */

  const readCredentials = useCallback(() => {
    if (window._fileUtil.exists(0, credentialsFile)) {
      const file = window._fileUtil.open(0, credentialsFile);
      const content = window._fileUtil.readLine(file);
      window._fileUtil.close(file as any);
      return content;
    }
    return '';
  }, []);

  const writeCredentials = useCallback((content: string) => {
    if (!window._fileUtil.exists(0, credentialsFile)) {
      window._fileUtil.createFile(0, credentialsFile);
    }
    let result = true;
    const file = window._fileUtil.open(0, credentialsFile);
    if (!window._fileUtil.write(file, content)) {
      result = false;
    }
    window._fileUtil.close(file as any);
    return result;
  }, []);

  return useMemo(
    () => ({
      canUseKJS,

      startLed,

      successLed,
      successBuzz,
      successVibrator,
      successFeedback: () => {
        successLed();
        successBuzz();
        successVibrator();
      },

      errorLed,
      errorFeedback: () => {
        // Errors in the default handler don't do anything outside of show a RED led
        errorLed();
      },

      readCredentials,
      writeCredentials,
    }),
    [
      canUseKJS,
      startLed,
      errorLed,
      successLed,
      successBuzz,
      successVibrator,

      readCredentials,
      writeCredentials,
    ]
  );
}
