import type { FormEventHandler } from 'react';
import { useState } from 'react';
import {
  useDownloadProductsFileMutation,
  usePostProductsUploadMutation,
} from '@store/services/api';
import { useToast } from '@hooks';
import { FormButtons, FormContentHeader } from '@components/multi-step-form';
import type { MultiStepFormPageProps } from '@components/multi-step-form';
import { UploadSection } from '../shared';
import type { CreateBuildingFormState } from '@features/building/CreateBuilding/CreateBuilding';

export const AddItems = ({
  multiStepFormState,
  onBackClick,
  onSuccess,
}: MultiStepFormPageProps<CreateBuildingFormState>) => {
  const [downloadTrigger, { isLoading: isDownloadingItems }] = useDownloadProductsFileMutation();
  const [uploadItemsFile] = usePostProductsUploadMutation();
  const [selectedItemFile, setSelectedItemFile] = useState<File | undefined>(undefined);
  const { errorToast, successToast } = useToast();

  const onSkip = () => {
    onSuccess({
      ...multiStepFormState,
      didSkipItemUpload: true,
    });
  };

  const handleUploadItems: FormEventHandler = async (e) => {
    e.preventDefault();

    if (!selectedItemFile) {
      errorToast('Please select a file before trying to upload items.');
    } else {
      const formData = new FormData();
      formData.append('file', selectedItemFile);
      formData.append('type', 'text/csv');
      formData.append('title', selectedItemFile.name);
      try {
        await uploadItemsFile({ body: formData }).unwrap();
        successToast('Items updated successfully.');
        onSuccess({
          ...multiStepFormState,
          didSkipItemUpload: false,
        });
      } catch (err) {
        console.error(err);
        errorToast(
          'The upload failed. Please check your file for proper formatting and try again.'
        );
      }
    }
  };

  const secondaryText = multiStepFormState?.shipperId
    ? "Your building is created, but it's missing items. Upload some now to get started. If you need a template, you can download one from here. Do not edit or remove the ID column."
    : 'Looks like you skipped creating a shipper. A shipper is required to add items. You can skip this step and add a shipper, items, and inventory later.';

  return (
    <form onSubmit={handleUploadItems}>
      <FormContentHeader
        mainText="Let's get some items in here"
        secondaryText={secondaryText}
        stepNumber={5}
        totalSteps={6}
      />
      <UploadSection
        downloadFilename="items"
        downloadLabel="Item Template"
        downloadTrigger={downloadTrigger}
        isDisabled={!multiStepFormState?.shipperId}
        isDownloading={isDownloadingItems}
        selectedFile={selectedItemFile}
        setSelectedFile={setSelectedItemFile}
        uploadLabel="Upload Items"
      />
      <FormButtons
        allowBack
        allowSkip
        onBackClick={onBackClick}
        onSkipClick={onSkip}
        isSubmitDisabled={!multiStepFormState?.shipperId || !selectedItemFile}
      />
    </form>
  );
};

export default AddItems;
