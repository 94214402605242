import { Box, Button, Center, Heading, HStack, VStack } from '@chakra-ui/react';
import type { CenterProps } from '@chakra-ui/react';

export type FatalErrorProps = CenterProps & {
  error?: unknown;
  title?: string;
  showRefreshButton?: boolean;
};

export const FatalError = ({
  error,
  title,
  children,
  showRefreshButton = true,
  ...centerProps
}: FatalErrorProps) => {
  return (
    <Center h="100vh" {...centerProps} data-testid="fatal-error">
      <VStack spacing={12} w="100%">
        <Heading as="h3" size="md" pb={6}>
          {title || 'There was an error loading this content'}
        </Heading>
        <Box>{children || 'Please try again'}</Box>
        <HStack>
          {showRefreshButton && (
            <Box>
              <Button variant="link" onClick={() => window?.location?.reload()}>
                Refresh
              </Button>
            </Box>
          )}
        </HStack>
      </VStack>
    </Center>
  );
};
