import { Button, useDisclosure, VStack } from '@chakra-ui/react';
import { BuildingAddressFormFields } from '@features/building/CreateBuilding/shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFCheckbox, RHFInput } from '@components/forms';
import type { Building } from '@store/services/api.generated';
import {
  usePatchBuildingsByBuildingIdMutation,
  usePostBuildingsByBuildingIdInviaSyncMutation,
} from '@store/services/api';
import { useAuth, useSetFieldErrors, useToast } from '@hooks';
import { editBuildingSchema } from '@schemas';
import type { BuildingFormFields } from '@schemas';
import { MessageBox, WmsModalFooter } from '@components';
import { hasValidationErrors, isFetchBaseQueryError } from '@store/services/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

type EditBuildingProps = {
  building: Building;
  onClose: () => void;
  onSuccess: () => void;
};

export const EditBuilding = ({ building, onClose, onSuccess }: EditBuildingProps) => {
  const { successToast, errorToast } = useToast();
  const { superAdminPrivileges } = useFlags();
  const [patchBuilding] = usePatchBuildingsByBuildingIdMutation({});

  const { isStordEmployee } = useAuth();

  const canSyncInventoryToInvia = isStordEmployee && !!building.invia_base_url;

  const {
    isOpen: isConfirmSyncOpen,
    onClose: closeConfirmSync,
    onOpen: onOpenConfirmSync,
  } = useDisclosure();

  const [syncInventoryToInvia, { isLoading: isSyncingInventory }] =
    usePostBuildingsByBuildingIdInviaSyncMutation();

  const { control, handleSubmit, setError } = useForm<BuildingFormFields>({
    defaultValues: {
      name: building?.name ?? '',
      line1: building?.shipping_from?.line1 ?? '',
      line2: building?.shipping_from?.line2 ?? '',
      city: building?.shipping_from?.city ?? '',
      state: building?.shipping_from?.state ?? '',
      country: building?.shipping_from?.country ?? '',
      postal_code: building?.shipping_from?.postal_code ?? '',
      timezone: building?.timezone ?? '',
      insights_enabled: building?.insights_enabled ?? false,
      invia_base_url: building?.invia_base_url ?? '',
    },
    resolver: yupResolver(editBuildingSchema),
  });
  const setFieldErrors = useSetFieldErrors({ setError });

  const onSubmit = handleSubmit(async (updatedBuilding) => {
    try {
      await patchBuilding({
        buildingId: building.id!,
        building: {
          name: updatedBuilding.name,
          shipping_from: {
            line1: updatedBuilding.line1 ?? '',
            line2: updatedBuilding.line2 ?? '',
            city: updatedBuilding?.city ?? '',
            state: updatedBuilding.state ?? '',
            country: updatedBuilding.country ?? '',
            postal_code: updatedBuilding.postal_code ?? '',
          },
          insights_enabled: updatedBuilding.insights_enabled,
          invia_base_url: updatedBuilding.invia_base_url ?? '',
          timezone: updatedBuilding.timezone,
        },
      }).unwrap();
      successToast('Building updated');
      onSuccess();
    } catch (err) {
      const didSetErrors = setFieldErrors(err);
      if (!didSetErrors) {
        errorToast('An error occurred trying to update building');
      }
    }
  });

  const syncToInvia = async () => {
    try {
      await syncInventoryToInvia({ buildingId: building.id! }).unwrap();

      successToast('Successfully initiated inventory sync');
    } catch (err) {
      if (isFetchBaseQueryError(err) && hasValidationErrors(err.data)) {
        errorToast(err.data.errors[0].message);
        return;
      }

      errorToast('Could not trigger inVia inventory sync');
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <VStack>
          <RHFInput autoFocus name="name" label="Building Name" isRequired control={control} />
          <BuildingAddressFormFields
            defaultValues={{ state: building?.shipping_from?.state, timezone: building?.timezone }}
            formControl={control}
          />
          <RHFInput name="invia_base_url" label="inVia Base API URL" control={control} />
          <RHFCheckbox
            control={control}
            name="insights_enabled"
            label="Enable Insights"
            my={1}
            disabled={!superAdminPrivileges}
          />
          <WmsModalFooter width="100%">
            {canSyncInventoryToInvia ? (
              <Button
                variant="ghost"
                colorScheme="blue"
                onClick={onOpenConfirmSync}
                marginRight="auto"
                data-confirm="Are you sure you want to sync to inVia?"
                isLoading={isSyncingInventory}
              >
                Sync Inventory to inVia
              </Button>
            ) : null}
            <Button variant="outline" colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </WmsModalFooter>
        </VStack>
      </form>
      <MessageBox
        title="Sync Inventory to inVia"
        message="Are you sure you want to sync inventory with inVia. This will take up to a minute and may interrupt existing pick operations."
        button={{ text: 'Confirm', onClick: syncToInvia }}
        isOpen={isConfirmSyncOpen}
        onClose={closeConfirmSync}
        closeText="Cancel"
      />
    </>
  );
};
