import { useAuth0 } from '@auth0/auth0-react';
import type { RedirectLoginOptions, User } from '@auth0/auth0-spa-js';

import { Loading } from '@components';
import type React from 'react';
import { useEffect } from 'react';
import { useConfig } from '@hooks';
import { NovuProvider } from '@novu/notification-center';
import { useGetTrackingQuery } from '@store/services/api';
import userflow from 'userflow.js';

// NOTE: This is almost copied exactly from `withAuthenticationRequired` from auth0-react

const defaultOnRedirecting = (): JSX.Element => <Loading isLoaded={false} />;

/**
 * @ignore
 */
const defaultReturnTo = () => `${window.location.pathname}${window.location.search}`;

export interface RequireAuthOptions {
  /**
   * Add a path for the `onRedirectCallback` handler to return the user to after login.
   */
  returnTo?: string | (() => string);
  /**
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element;
  /**
   * Pass additional login options, like extra `appState` to the login page.
   * This will be merged with the `returnTo` option used by the `onRedirectCallback` handler.
   */
  loginOptions?: RedirectLoginOptions;
  /**
   * Check the user object for JWT claims and return a boolean indicating
   * whether or not they are authorized to view the component.
   */
  claimCheck?: (claims?: User) => boolean;
}

export const RequireAuth = ({
  children,
  options = {},
}: React.PropsWithChildren<{
  options?: RequireAuthOptions;
}>) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { NOVU_APP_ID, USERFLOW_TOKEN } = useConfig();
  const { data: trackingData } = useGetTrackingQuery();
  const {
    returnTo = defaultReturnTo,
    onRedirecting = defaultOnRedirecting,
    loginOptions = {},
    claimCheck = (): boolean => true,
  } = options;

  /**
   * The route is authenticated if the user has valid auth and there are no
   * JWT claim mismatches.
   */
  const routeIsAuthenticated = isAuthenticated && claimCheck(user);

  useEffect(() => {
    if (isLoading || routeIsAuthenticated || window.Cypress) {
      return;
    }
    const opts = {
      ...loginOptions,
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
      },
    };
    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, routeIsAuthenticated, loginWithRedirect, loginOptions, returnTo]);

  useEffect(() => {
    const userflowSignature = trackingData?.data?.userflow_signature;
    if (USERFLOW_TOKEN && userflowSignature && user && routeIsAuthenticated) {
      userflow.identify(
        user.sub!,
        {
          name: user.name!,
          email: user.email!,
        },
        {
          signature: userflowSignature,
        }
      );
    }
  }, [user, routeIsAuthenticated, USERFLOW_TOKEN, trackingData]);

  return window.Cypress || routeIsAuthenticated ? (
    <NovuProvider subscriberId={user?.sub} applicationIdentifier={NOVU_APP_ID}>
      {children}
    </NovuProvider>
  ) : (
    onRedirecting()
  );
};
