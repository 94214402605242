import { FormContentHeader } from '@components/multi-step-form';
import type { MultiStepFormPageProps } from '@components/multi-step-form';
import { LoadingOrError, UnitsOfMeasureEditor } from '@components';
import { useGetUomQuery } from '@store/services/api';
import { Button, Flex, Spacer } from '@chakra-ui/react';
import type { CreateBuildingFormState } from '@features/building/CreateBuilding/CreateBuilding';

export const UnitsOfMeasure = ({
  multiStepFormState,
  onSkipClick,
}: MultiStepFormPageProps<CreateBuildingFormState>) => {
  const shipperId = multiStepFormState?.shipperId ?? '';
  const shipperName = multiStepFormState?.shipperName ?? '';

  const {
    data: uomData,
    isLoading: isLoadingUom,
    isError: isErrorUom,
    error: errorUom,
  } = useGetUomQuery(
    {
      shipperIds: shipperId,
      sort: 'inserted_at:asc',
    },
    {
      skip: !shipperId,
    }
  );

  if (!shipperId) {
    return (
      <>
        <FormContentHeader
          mainText="You'll need to create a shipper first"
          secondaryText="A shipper is required to create units of measure.
        You can skip this step and create units of measure later in Settings."
          stepNumber={3}
          totalSteps={6}
        />
        <Flex paddingY={4}>
          <Spacer />
          <Button variant="primary" onClick={() => onSkipClick()}>
            Skip
          </Button>
        </Flex>
      </>
    );
  }

  return (
    <>
      <FormContentHeader
        mainText={`Let's create units of measure for ${shipperName}`}
        secondaryText="We'll add these units of measure to your shipper's profile.
        You'll be able to map units of measure to SKUs and add conversions later."
        stepNumber={3}
        totalSteps={6}
      />

      <LoadingOrError isLoaded={!isLoadingUom} isError={isErrorUom} error={errorUom} h="auto">
        <UnitsOfMeasureEditor
          shipperId={shipperId}
          unitsOfMeasure={uomData?.data ?? []}
          data-testid="uom-editor"
        />
      </LoadingOrError>

      <Flex paddingY={4}>
        <Spacer />
        <Button variant="primary" onClick={() => onSkipClick()}>
          Next
        </Button>
      </Flex>
    </>
  );
};
