import type { UseToastOptions } from '@chakra-ui/react';
import { useToast as useChakraToast } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { Toast } from '@components';
import type { ReactNode } from 'react';
import { hasValidationErrors, isFetchBaseQueryError } from '@store/services/utils';
import { capitalize } from '@utils';

type ValidationUseToastOptions = UseToastOptions & {
  defaultMessage?: ReactNode;
};

// For usage with useStandaloneToast if necessary
export const DEFAULT_TOAST_OPTIONS = {
  duration: 3000,
  isClosable: true,
};

export const useToast = () => {
  const toast = useChakraToast();

  const defaultOptions: UseToastOptions = useMemo(() => DEFAULT_TOAST_OPTIONS, []);

  const errorToast = useCallback(
    (message: ReactNode, options: UseToastOptions = {}) =>
      toast({
        status: 'error',
        render: ({ onClose }) => {
          return (
            <Toast
              description={message}
              title={options.title ?? 'Error'}
              onClose={onClose}
              variant="error"
            />
          );
        },
        ...defaultOptions,
        ...options,
      }),
    [toast, defaultOptions]
  );

  const validationErrorToast = useCallback(
    (error: unknown, { defaultMessage, ...options }: ValidationUseToastOptions = {}) => {
      let message = defaultMessage ?? 'Something went wrong';

      if (isFetchBaseQueryError(error) && hasValidationErrors(error.data)) {
        const apiErrorMessage = error.data.errors[0]?.message;
        message = apiErrorMessage ? capitalize(apiErrorMessage) : message;
      }

      errorToast(message, options);
    },
    [errorToast]
  );

  const successToast = useCallback(
    (message: ReactNode, options: UseToastOptions = {}) =>
      toast({
        status: 'success',
        render: ({ onClose }) => {
          return (
            <Toast
              description={message}
              title={options.title ?? 'Success'}
              onClose={onClose}
              variant="success"
            />
          );
        },
        ...defaultOptions,
        ...options,
      }),
    [toast, defaultOptions]
  );

  const warningToast = useCallback(
    (message: ReactNode, options: UseToastOptions = {}) =>
      toast({
        status: 'warning',
        render: ({ onClose }) => {
          return (
            <Toast description={message} title="Warning" onClose={onClose} variant="warning" />
          );
        },
        ...defaultOptions,
        ...options,
      }),
    [toast, defaultOptions]
  );

  const infoToast = useCallback(
    (message: ReactNode, options: UseToastOptions = {}) =>
      toast({
        status: 'info',
        render: ({ onClose }) => {
          return <Toast description={message} title="Notice" onClose={onClose} variant="info" />;
        },
        ...defaultOptions,
        ...options,
      }),
    [toast, defaultOptions]
  );

  return useMemo(
    () => ({
      errorToast,
      infoToast,
      warningToast,
      validationErrorToast,
      successToast,
    }),
    [errorToast, infoToast, successToast, validationErrorToast, warningToast]
  );
};
