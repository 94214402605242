/* eslint-disable no-console */
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDebounce, useGlobalKeydown } from '@hooks';
import type { KeyenceScannerCallbackFunction } from '@scanner/hooks/useKeyenceScannerCallback';
import { buildUPCScanResult } from '@mocks/scannerData';
import { datadogRum } from '@datadog/browser-rum';
import {
  DEFAULT_SCAN_DELAY,
  getEnableScanSuffixFromLocalstorage,
  getScanDelayFromLocalstorage,
} from '@utils';

/**
 * Adds a keypress listener to the window and will record keystrokes for 5ms after the initial keypress.
 * This assumes any generic desktop scanner works as a keyboard and 'types really fast' :)
 * See ScannableInput for an example -- THIS SHOULD NOT BE NEEDED OUTSIDE OF ScannableInput COMPONENT
 * In the event this needs to be used somewhere else, it should be used alongside useKeyenceScannerCallback with different callbackKeys
 *
 * ex.
 *   const handleScannerCallback = useCallback(async (scanResult: ScanResult) => {
 *   const scanValue = scanResult?.mStringData;
 *    if (
 *      scanResult?.mDecodeResult === 'SUCCESS' &&
 *      scanValue
 *    ) {
 *      setValue(name, scanValue);
 *      await onScan();
 *    }
 *  }, [name, onScan, setValue]);
 *
 *  useKeyenceScannerCallback({
 *    callbackFunction: handleScannerCallback,
 *    callbackKey: `${name}-scannerCallback`,
 *  });
 *
 *  useKeyboardScannerCallback({
 *    callbackFunction: handleScannerCallback,
 *    callbackKey: `${name}-keyboardCallback`,
 *  });
 */

// Enumerated here: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#function_keys
const functionKeyRegex = /F1|F2|F3|F4|F5|F6|F7|F8|F9|F10|F11|F12|F13|F14|F15|F16|F17|F18|F19|F20/;

// Enumerated here: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#ime_and_composition_keys
const IMEKeyRegex = /Dead|Process/;

// Keyence scanner callbacks will emit an 'Unidentified' keypress event on the handheld device. Details: https://stackoverflow.com/questions/59584061/why-is-unidentified-returned-on-mobile
// Enumerated here: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#special_values
const specialKeyRegex = /Unidentified/;

// Enumerated here: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#navigation_keys
const navigationKeyRegex = /ArrowDown|ArrowLeft|ArrowRight|ArrowUp|End|Home|PageDown|PageUp/;

// Enumerated here: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#editing_keys
const editingKeyRegex = /Backspace|Clear|Copy|Paste|Cut|Delete|Redo|Undo|Insert/;

// Enumerated here: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#modifier_keys
const modifierKeyRegex = /Shift|Control|Alt|Meta|CapsLock|ScrollLock|NumLock/;

// Enumerated here: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#whitespace_keys
// 'Enter' and 'Tab' are intentionally omitted as we use them as stop characters
const whiteSpaceKeyRegex = /Space/;

// Enumerated here: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#ui_keys
const UIKeyRegex = /Escape|Pause|Play/;

const IGNORED_KEYS_REGEX_GROUPS = [
  functionKeyRegex,
  IMEKeyRegex,
  specialKeyRegex,
  navigationKeyRegex,
  editingKeyRegex,
  modifierKeyRegex,
  whiteSpaceKeyRegex,
  UIKeyRegex,
];

const IGNORED_KEYS_REGEX_GROUPS_COMBINED = IGNORED_KEYS_REGEX_GROUPS.map(
  (group) => group.source
).join('|');

const IGNORED_KEYS_REGEX = new RegExp(IGNORED_KEYS_REGEX_GROUPS_COMBINED, 'gm');

const callbackBackFunctions: Record<string, Function> = {};

export const useKeyboardScannerCallback = ({
  callbackFunction,
  callbackKey,
}: {
  callbackFunction?: KeyenceScannerCallbackFunction;
  callbackKey?: string;
} = {}) => {
  const [entry, setEntry] = useState<string>('');
  const [enableScanSuffix, setEnableScanSuffix] = useState(false);
  const [scanDelay, setScanDelay] = useState(DEFAULT_SCAN_DELAY);

  useGlobalKeydown(({ key, ctrlKey, altKey, metaKey, code, shiftKey }: KeyboardEvent) => {
    if (key.match(IGNORED_KEYS_REGEX)) {
      return;
    }

    if (ctrlKey || altKey || metaKey) {
      const modifierKey = ctrlKey ? 'Control' : altKey ? 'Alt' : metaKey ? 'Meta' : 'Unknown';
      console.info('useKeyboardScannerCallback: encountered modifier key during scan', modifierKey);

      datadogRum.addAction('barcode_scan_had_modifier_key', {
        modifierKey,
        key,
        code,
        shiftKey,
      });

      return;
    }

    setEntry((prev) => `${prev}${key}`);
  });

  useEffect(() => {
    const localStorageEnableScanSuffix = getEnableScanSuffixFromLocalstorage();
    const localStorageScanDelay = getScanDelayFromLocalstorage();

    setEnableScanSuffix(localStorageEnableScanSuffix);
    setScanDelay(localStorageScanDelay);

    console.info(
      `useKeyboardScannerCallback: scan suffix ${
        localStorageEnableScanSuffix === true ? 'enabled' : 'disabled'
      } with delay of ${localStorageScanDelay}ms`
    );
  }, []);

  const keyboardResult = useDebounce(entry, scanDelay);

  useEffect(() => {
    const scanContent = keyboardResult.replace(/(Enter|Tab)/g, '');

    // ignore any text that isn't at least 3 character long. This would have to be REALLY fast typing
    if (
      scanContent &&
      scanContent.length > 2 &&
      (!enableScanSuffix || keyboardResult.endsWith('Enter') || keyboardResult.endsWith('Tab'))
    ) {
      const scanResult = buildUPCScanResult(scanContent);

      if (process.env.NODE_ENV !== 'test') {
        console.info('useKeyboardScannerCallback: scan result', `'${scanResult.mStringData}'`);
      }

      Object.values(callbackBackFunctions).forEach((fn) => {
        fn(scanResult);
      });
    }

    // this resets the result to empty so we can scan the same barcode twice and still resend the request
    // if this wasn't here, the second scan would not change result and thus not trigger the effect
    setEntry('');
  }, [keyboardResult, enableScanSuffix]);

  useLayoutEffect(() => {
    if (callbackFunction && callbackKey) {
      callbackBackFunctions[callbackKey] = callbackFunction;
    }

    return () => {
      if (callbackKey && callbackBackFunctions[callbackKey])
        delete callbackBackFunctions[callbackKey];
    };
  }, [callbackFunction, callbackKey]);
};
