import type { ButtonProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  InputGroup,
  FormControl,
  FormLabel,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
  useColorModeValue as mode,
  NumberInput,
  NumberInputField,
  InputRightElement,
  Spacer,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import type { KeyboardEventHandler, ReactNode } from 'react';
import { useMemo, useCallback, useRef, memo } from 'react';
import { isRangedValueOptionSelected } from '@utils/filterSelection';
import { useHighlightSelectedProps } from '@hooks/useHighlightSelectedProps';
import { FilterLabel } from '@components/FilterLabel';

export type RangedValue = { min?: number; max?: number };

interface RangedValueSelectorProps extends Omit<ButtonProps, 'onChange' | 'value'> {
  filterLabel?: ReactNode;
  label: string;
  max?: number;
  min?: number;
  onChange?: (value: RangedValue) => void;
  prompt?: string;
  value: RangedValue;
}

const getRangeText = ({ min, max }: RangedValue): string => {
  if (!min && !max) {
    return '';
  }

  if (!min) {
    return `: Max. ${max}`;
  }

  if (!max) {
    return `: Min. ${min}`;
  }

  if (min === max) {
    return `: ${min}`;
  }

  return `: ${min}-${max}`;
};

export const RangedValueSelector = memo(
  ({
    filterLabel,
    label,
    max,
    min = 1,
    onChange,
    prompt = 'Select a range',
    value = {},
    ...buttonProps
  }: RangedValueSelectorProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const minInputRef = useRef<HTMLInputElement>(null);

    const handleMinChange = useCallback(
      (_strVal: string, numVal: number) => {
        const parsedMin = isNaN(numVal) ? undefined : numVal;
        if (value.min === parsedMin) {
          return;
        }

        onChange?.({ ...value, min: parsedMin });
      },
      [onChange, value]
    );

    const handleMaxChange = useCallback(
      (_strVal: string, numVal: number) => {
        const parsedMax = isNaN(numVal) ? undefined : numVal;
        if (value.max === parsedMax) {
          return;
        }

        onChange?.({ ...value, max: parsedMax });
      },
      [onChange, value]
    );

    const handleEnterKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
      ({ key }) => {
        if (key === 'Enter') {
          onClose();
        }
      },
      [onClose]
    );

    const shouldHighlight = useMemo(() => isRangedValueOptionSelected(value), [value]);
    const highlightingProps = useHighlightSelectedProps(shouldHighlight);

    return (
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        initialFocusRef={minInputRef}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <ButtonGroup isAttached variant="filter" display="inline">
            {filterLabel && <FilterLabel label={filterLabel} />}
            <Button
              variant="filter"
              cursor="default"
              display="inline"
              py={0}
              pr={2.5}
              w="full"
              {...buttonProps}
              {...highlightingProps}
            >
              <HStack>
                <Text as="span" variant={mode('lighter', undefined)}>
                  {label}
                  {getRangeText(value)}
                </Text>
                <Spacer />
                <Box width={5}>
                  <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} size="xs" />
                </Box>
              </HStack>
            </Button>
          </ButtonGroup>
        </PopoverTrigger>
        <PopoverContent borderRadius="md" bg={mode('white', 'darkmode.gray.800')} w="fit-content">
          <VStack p={4}>
            <Text w="full" fontSize="sm" fontWeight="semibold" color="gray.600">
              {prompt}
            </Text>
            <HStack alignItems="start">
              <FormControl>
                <InputGroup display="inherit">
                  <NumberInput
                    w={20}
                    value={value.min ?? ''}
                    min={min}
                    max={max}
                    onChange={handleMinChange}
                    onKeyDown={handleEnterKeyPress}
                  >
                    <NumberInputField ref={minInputRef} textAlign="center" pr={value.min ? 8 : 4} />
                    {value.min !== undefined && (
                      <InputRightElement>
                        <Button
                          title="Clear minimum"
                          onClick={() => onChange?.({ ...value, min: undefined })}
                          variant="unstyled"
                        >
                          <FontAwesomeIcon icon={faCircleXmark} />
                        </Button>
                      </InputRightElement>
                    )}
                  </NumberInput>
                  <FormLabel>Min</FormLabel>
                </InputGroup>
              </FormControl>
              <Text pt={2}>to</Text>
              <FormControl>
                <InputGroup display="inherit">
                  <NumberInput
                    w={20}
                    value={value.max ?? ''}
                    min={value.min ?? 1}
                    max={max}
                    onChange={handleMaxChange}
                    onKeyDown={handleEnterKeyPress}
                  >
                    <NumberInputField textAlign="center" pr={value.max ? 8 : 4} />
                    {value.max !== undefined && (
                      <InputRightElement>
                        <Button
                          title="Clear maximum"
                          onClick={() => onChange?.({ ...value, max: undefined })}
                          variant="unstyled"
                        >
                          <FontAwesomeIcon icon={faCircleXmark} />
                        </Button>
                      </InputRightElement>
                    )}
                  </NumberInput>
                  <FormLabel textAlign="right">Max</FormLabel>
                </InputGroup>
              </FormControl>
            </HStack>
          </VStack>
        </PopoverContent>
      </Popover>
    );
  }
);
