import { useGetPickEquipmentGroupsQuery } from '@store/services/api';
import { useMemo } from 'react';
import type { GetPickEquipmentGroupsApiArg } from '@store/services/api.generated';

export const useGetPickEquipmentGroupOptions = ({
  blankOptionText,
  params = {},
  showBlankOption,
}: {
  blankOptionText?: string;
  params?: GetPickEquipmentGroupsApiArg;
  showBlankOption?: boolean;
}) => {
  const { data, isLoading, isFetching, isError, error } = useGetPickEquipmentGroupsQuery({
    sort: 'name',
    ...params,
  });

  const pickEquipmentGroupOptions = useMemo(() => {
    const blankOption = showBlankOption ? [{ label: blankOptionText ?? '--', value: '' }] : [];
    const pickEquipmentGroupDataOptions = (data?.data ?? []).map((pickEquipmentGroup) => {
      return {
        label: pickEquipmentGroup?.name!,
        value: pickEquipmentGroup?.id!,
      };
    });

    return [...blankOption, ...pickEquipmentGroupDataOptions];
  }, [blankOptionText, data, showBlankOption]);

  const pickEquipmentGroupMap = useMemo(
    () =>
      new Map(
        (data?.data ?? []).map((pickEquipmentGroup) => [pickEquipmentGroup.id!, pickEquipmentGroup])
      ),
    [data]
  );

  return {
    data,
    pickEquipmentGroupMap,
    pickEquipmentGroupOptions,
    isLoading: isLoading || isFetching,
    isError,
    error,
  };
};
