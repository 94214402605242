import { Stack } from '@chakra-ui/react';
import type { StackProps } from '@chakra-ui/react';
interface TableActionBarProps extends StackProps {}

export const TableActionBar = ({ children, ...rest }: TableActionBarProps) => {
  return (
    <Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between" {...rest}>
      {children}
    </Stack>
  );
};
