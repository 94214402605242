import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Announcement: ComponentStyleConfig = {
  parts: ['banner', 'dismissButton', 'message'],
  baseStyle: (props) => ({
    banner: {},
    dismissButton: {
      bgColor: 'transparent',
      color: 'white',
    },
    message: {
      fontWeight: 'bold',
      color: 'white',
      p: 2,
      pl: 10, // this is to offset the icon on the right to make text appear centered
      textAlign: 'center',
      width: '100%',
    },
  }),
  variants: {
    success: (props) => ({
      banner: {
        bgColor: 'green.500',
      },
    }),
    warning: (props) => ({
      banner: {
        bgColor: 'yellow.500',
      },
      dismissButton: {
        color: 'gray.900',
      },
      message: {
        color: 'gray.900',
      },
    }),
    error: (props) => ({
      banner: {
        bgColor: 'red.500',
      },
    }),
    info: (props) => ({
      banner: {
        bgColor: 'blue.500',
      },
    }),
  },
};
