import { CheckmarkCircle } from '@components';
import { Stack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

interface CreateBuildingSuccessProps {
  isShipperCreated?: boolean;
}

export const CreateBuildingSuccess = ({ isShipperCreated }: CreateBuildingSuccessProps) => {
  const mainText = isShipperCreated
    ? 'Your building and shipper have been created!'
    : 'Your building was created, but a shipper was not';

  const secondaryText = isShipperCreated
    ? 'You can continue to create units of measure and upload locations, items, and/or inventory or finish\n' +
      'now and perform these steps later.'
    : 'We recommend that you exit the wizard now and create at least one shipper before moving on to units of measure and uploads.';

  return (
    <Stack alignItems="center" pb={4} mb={4}>
      {isShipperCreated ? (
        <CheckmarkCircle fontSize="7xl" color="green.500" />
      ) : (
        <FontAwesomeIcon icon={faCircleExclamation} size="7x" color="red.500" />
      )}
      <Text fontSize="xl" fontWeight="bold" textAlign="center">
        {mainText}
      </Text>
      <Text fontSize="sm" textAlign="center">
        {secondaryText}
      </Text>
    </Stack>
  );
};

export default CreateBuildingSuccess;
