import { Socket } from 'phoenix';
import type { Middleware } from '@reduxjs/toolkit';
import { logout, setAuthData, setBuildingId } from '@features/auth/actions';
import { getConfigForEnv, getChannelsPubSub, getBuildingIdFromLocalStorage } from '@utils';
import type { AppDispatch, RootState } from '@store';
import type { KnownTopic } from '@features/realtime/realtimeSlice';
import type {
  ActivityAction,
  PostWebsocketTicketsApiResponse,
} from '@store/services/api.generated';
import { broadcastEvent, isBuiltinChannelEvent } from '@features/realtime/common';
import type { AuthClaimAction } from '@features/auth/authSlice';

const channelsPubSub = getChannelsPubSub();

let _client: undefined | Socket;
let isSubscribedToPubSub = false;
let isJoiningWebsocket = false;

let lastToken: string | undefined;
let lastBuildingId: string | undefined;

const resetConnectionState = () => {
  _client = undefined;
  isSubscribedToPubSub = false;
  lastToken = undefined;
  lastBuildingId = undefined;
};

const getOrCreateClient = async ({
  getState,
  token,
  buildingId,
}: {
  getState: () => RootState;
  token?: string;
  buildingId?: string;
}) => {
  const hasChangedToken = lastToken !== undefined && lastToken !== token;
  const hasChangedBuildingId = lastBuildingId !== undefined && lastBuildingId !== buildingId;

  if (
    token &&
    buildingId &&
    !isJoiningWebsocket &&
    (!_client || hasChangedToken || hasChangedBuildingId)
  ) {
    isJoiningWebsocket = true;

    if (_client?.isConnected() && (hasChangedToken || hasChangedBuildingId)) {
      _client.disconnect();
      isSubscribedToPubSub = false;
    }

    const ticket = await getWebsocketAuthTicket(token, buildingId, getState);

    if (!ticket) {
      isJoiningWebsocket = false;
      return;
    }

    const socketUrl = getWsUrl(getState);

    _client = new Socket(socketUrl, {
      params: { ticket },
    });

    lastToken = token;
    lastBuildingId = buildingId;

    _client.connect();
    isJoiningWebsocket = false;
  }
  return _client;
};

export const phoenixChannelTopics: KnownTopic[] = [
  'adjustments',
  // 'buildings', // Not currently a joinable channel
  'cycle_counts',
  'locations',
  'inbounds',
  'items',
  'license_plates',
  'outbounds',
  'printers',
  'products',
  'users',
  'units_of_measure',
];

// Being that we want to opt in/out of channel messages on demand and not potentially
// block other subscribers from receiving messages they care about, we're dumping messages
// into a pubsub util and letting consumers do their own filtering.

const joinAllChannelsAndBindPubSub = (buildingId: string, dispatch: AppDispatch) => {
  if (isSubscribedToPubSub || !_client) return;

  for (const topic of phoenixChannelTopics) {
    const joinableChannel = `${topic}:${buildingId}`;
    const channel = _client?.channel(joinableChannel, {
      client: 'browser',
    });

    if (!channel) continue;

    channel.onMessage = (event, payload) => {
      channelsPubSub.emit({ topic, event: event as ActivityAction, payload });
      return payload;
    };

    channel.join().receive('error', (resp) => {
      console.error('Unable to join', topic, resp);
    });
  }

  // We establish a subscription once to interact with the realtimeSlice / unseenAlerts
  channelsPubSub.subscribe((message) => {
    const { event, payload } = message || {};
    // This is used for the redux 'missed updates' counts and is handled by realtimeSlice.ts + api.ts (resets the count on a new query)
    if (!isBuiltinChannelEvent(event)) {
      // We're setting a soft timeout to ensure the HTTP request is flushed and avoid timing issues
      setTimeout(() => {
        dispatch(broadcastEvent({ event, payload }));
      }, 100);
    }
  });

  isSubscribedToPubSub = true;
};

const getWebsocketAuthTicket = async (
  token: string,
  buildingId: string,
  getState: () => RootState
) => {
  const apiUrl = getApiUrl(getState);
  const url = `${apiUrl}/websocket-tickets`;

  try {
    const ticketResponse = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-building-id': buildingId,
      },
    });

    const body = (await ticketResponse.json()) as PostWebsocketTicketsApiResponse;

    return body?.data?.websocket_ticket;
  } catch (err) {
    console.error('Failed to get websocket ticket from API');
  }
};

const getApiUrl = (getState: () => RootState): string => {
  let { config } = getState();

  // Handle the case where in tests the env isn't preloaded
  if (process.env.NODE_ENV === 'test' && !Object.keys(config).length) {
    config = getConfigForEnv('test');
  }

  return config.API_URL ?? '';
};

const transformApiUrlForWs = (str: string) => {
  return str
    .replace(/(http)(s)?:\/\//, 'ws$2://')
    .replace('/v1', '')
    .concat('/socket');
};

const getWsUrl = (getState: () => RootState): string => {
  const apiUrl = getApiUrl(getState);

  return transformApiUrlForWs(apiUrl);
};

function ws() {
  const middleware: Middleware =
    ({ getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch }) =>
    (next) =>
    async (action) => {
      // during unit tests we skip this execution block to prevent websocket errors like this one from popping up
      // and breaking tests that do not need or use websockets to pass
      // https://github.com/stordco/wms-ui/runs/8043279552?check_suite_focus=true#step:6:1394
      if (process.env.NODE_ENV === 'test') return next(action);

      const { auth } = getState();

      // if the user is not available in state, or user is associate then skip
      // websocket setup
      const userRole =
        (action as AuthClaimAction)?.payload?.['https://wms.stord.com']?.role ?? auth.role;

      if (!userRole || userRole === 'associate') {
        return next(action);
      }

      if (setAuthData.match(action)) {
        try {
          const buildingId =
            action.payload?.['https://wms.stord.com']?.building_id ??
            getBuildingIdFromLocalStorage();

          getOrCreateClient({
            getState,
            buildingId,
            token: action.payload.__raw,
          }).then(() => joinAllChannelsAndBindPubSub(buildingId, dispatch));
        } catch (err) {
          console.error('WS conn error: ', err);
        }
      } else if (setBuildingId.match(action)) {
        try {
          const buildingId = action.payload.id!;

          getOrCreateClient({
            getState,
            buildingId,
            token: auth.token,
          }).then(() => joinAllChannelsAndBindPubSub(buildingId, dispatch));
        } catch (err) {
          console.error('WS conn error: ', err);
        }
      } else if (logout.match(action)) {
        try {
          _client?.disconnect();
          resetConnectionState();
        } catch (err) {
          console.warn('WS disconnection error: ', err);
        }
      }

      return next(action);
    };

  return middleware;
}

export const wsMiddleware = ws();
