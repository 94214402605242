import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import type { RootState } from '@store';
import type { FieldValues } from 'react-hook-form';
import type { EnvConfig } from './api';
import { capitalize } from '@utils';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Attempts to parse the `?config=` query parameter as a configuration value
 * override
 */
export const fetchOverrideConfig = ({ paramKey } = { paramKey: 'config' }) => {
  // eslint-disable-next-line no-restricted-globals
  const configOverride = new URLSearchParams(location.search)?.get(paramKey);
  let overrides: Record<string, any> = {};
  try {
    if (configOverride) {
      overrides = JSON.parse(`${atob(configOverride)}`);
    }
  } catch {
    console.error('Error parsing config overrides, ensure that you have encoded valid JSON');
  }
  return overrides as Partial<EnvConfig>;
};

/**
 * Attempts to resolve the environment configuration from ./public/config.json by making
 * a relative request.
 */
export const fetchStaticConfig = async () =>
  fetch('/config.json').then((r) => r.json() as Promise<EnvConfig>);

export const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError =>
  error && 'status' in error;

export type WmsValidationError<T extends FieldValues> = {
  name: keyof T;
  message: string;
  reasons?: string[];
};

export type WmsNestedValidationError<T extends FieldValues> = {
  name: keyof T;
  message: object;
  reasons?: string[];
};

export type WmsExternalApiError = {
  name: string;
  error: string;
  message: string;
  status: number;
};

export type WmsValidationErrors<T extends FieldValues = FieldValues> = {
  errors: WmsValidationError<T>[];
};

export type WmsNestedValidationErrors<T extends FieldValues = FieldValues> = {
  errors: WmsNestedValidationError<T>[];
};

export type WmsExternalApiErrors = {
  errors: WmsExternalApiError[];
};

export const isValidationError = <T extends FieldValues = FieldValues>(
  error: any
): error is WmsValidationError<T> =>
  'name' in error && 'message' in error && typeof error.message === 'string';

export const isNestedValidationError = <T extends FieldValues = FieldValues>(
  error: any
): error is WmsNestedValidationError<T> =>
  'name' in error &&
  'message' in error &&
  typeof error.message === 'object' &&
  Object.keys(error.message).length > 0;
export const hasValidationErrors = <T extends FieldValues = FieldValues>(
  error: any
): error is WmsValidationErrors<T> =>
  error && Array.isArray(error.errors) && error.errors.every(isValidationError);

export const hasNestedValidationErrors = <T extends FieldValues = FieldValues>(
  error: any
): error is WmsNestedValidationErrors<T> =>
  error &&
  'errors' in error &&
  Array.isArray(error.errors) &&
  error.errors.some(isNestedValidationError);

export const isExternalApiError = (error: any): error is WmsExternalApiError =>
  error && error.error === 'External API error';

export const hasGenericApiErrors = (error: any): error is WmsExternalApiErrors =>
  error &&
  Array.isArray(error.errors) &&
  error.errors.some((error: WmsExternalApiError) => !!error.message);

export const hasExternalApiErrors = (error: any): error is WmsExternalApiErrors =>
  error &&
  error.data &&
  Array.isArray(error.data.errors) &&
  error.data.errors.some((error: WmsExternalApiError) => error.error === 'external_api_error');

export const getNestedValidationErrorMessage = (error: WmsNestedValidationError<any>): string => {
  let curr: string | object = error.message;

  while (typeof curr !== 'string') {
    if (typeof curr === 'object' && Object.values(curr).length > 0) {
      curr =
        Object.values(curr).find((el) => typeof el === 'string' || Object.keys(el).length > 0) ??
        '';
    } else if (Array.isArray(curr) && curr.length > 1) {
      // if curr is an array, we want to get the first element that has keys or is a string
      curr = curr.find((el) => typeof el === 'string' || Object.keys(el).length > 0) ?? '';
    } else {
      return '';
    }
  }

  return curr;
};

export const getAllNestedValidationErrorMessages = <T extends FieldValues = FieldValues>(
  error: any
): string[] => {
  const nestedErrors = error.errors.filter(isNestedValidationError);

  return nestedErrors.map((e: WmsNestedValidationError<T>) =>
    capitalize(getNestedValidationErrorMessage(e))
  );
};

export const buildAuth0ClientOptions = (config: RootState['config']) => ({
  domain: config.AUTH0_DOMAIN,
  client_id: config.AUTH0_CLIENT_ID,
});

type ErrorMessageMap = {
  input: string;
  output: string;
};

type ErrorHandlingOptions = {
  defaultErrorMessage: string;
  errorMessageTranslationMap?: ErrorMessageMap[];
  showMultipleErrors?: boolean;
  statusCodesToIgnore?: FetchBaseQueryError['status'][];
};

const translateErrorMessage = (message: string, errorMessageTranslationMap: ErrorMessageMap[]) => {
  const matchingTranslation = errorMessageTranslationMap.find((errorMessageToTranslate) => {
    return message.includes(errorMessageToTranslate.input);
  });

  return matchingTranslation?.output ?? message;
};

export const getApiErrorMessage = (
  error: any,
  {
    defaultErrorMessage,
    errorMessageTranslationMap = [],
    showMultipleErrors = false,
    statusCodesToIgnore = [],
  }: ErrorHandlingOptions
) => {
  const isBaseQueryError = isFetchBaseQueryError(error);

  // do not return any error and continue if we want to ignore (usually for when 404 is a valid response)
  if (isBaseQueryError && statusCodesToIgnore.includes(error?.status)) {
    return '';
  }

  let errorMessage = '';

  if (isBaseQueryError && hasValidationErrors(error.data)) {
    errorMessage = error.data.errors[0]?.message;
  } else if (isBaseQueryError && hasNestedValidationErrors(error.data)) {
    if (showMultipleErrors) {
      errorMessage = getAllNestedValidationErrorMessages(error.data).join();
    } else {
      const validationError = error.data.errors.find(isNestedValidationError)!;
      errorMessage = getNestedValidationErrorMessage(validationError);
    }
  } else if (isBaseQueryError && hasGenericApiErrors(error.data)) {
    errorMessage = error?.data?.errors?.[0]?.message;
  }

  if (!errorMessage) {
    datadogRum.addError('Unable to parse error message', {
      error,
    });
  }

  const finalErrorMessage = errorMessage
    ? translateErrorMessage(errorMessage, errorMessageTranslationMap)
    : defaultErrorMessage;

  return capitalize(finalErrorMessage);
};
