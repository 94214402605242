import { useMemo } from 'react';

export const useScannerUserAgent = () => {
  const browserUserAgent = navigator.userAgent;

  return useMemo(() => {
    const scannerUserAgents = ['BTA700'];
    return {
      isScanner: scannerUserAgents.some((agentString) => browserUserAgent.includes(agentString)),
    };
  }, [browserUserAgent]);
};
