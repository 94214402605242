import { addYears, isAfter, isBefore, isValid, subYears } from 'date-fns';
import * as yup from 'yup';

/**
 * Date validation schema
 * In order to use this correctly, you need to set the form context key to a boolean value
 * that will determine if the date field is required or not.
 */
export function dateValidationSchema({ label, key }: { label: string; key: string }) {
  return yup
    .string()
    .label(label)
    .test({
      name: 'date-validation',
      test: (value, ctx) => {
        const isRequired = ctx?.options?.context?.[key] || false;

        // Skip validation if not required and no value
        if (!isRequired && !value) return true;

        // If required and no value, return error
        if (isRequired && !value) {
          return ctx.createError({
            message: '${label} is required',
          });
        }

        const date = new Date(value ?? '');

        switch (true) {
          // If date is invalid
          case !isValid(date):
            return ctx.createError({
              message: 'Valid date (mm/dd/yyyy) is required',
            });

          // If date is more than 10 years ago
          case isBefore(date, subYears(new Date(), 10)):
            return ctx.createError({
              message: '${label} cannot be more than 10 years ago',
            });

          // If date is more than 10 years in the future
          case isAfter(date, addYears(new Date(), 10)):
            return ctx.createError({
              message: '${label} cannot be more than 10 years in the future',
            });

          default:
            return true;
        }
      },
    });
}
