import type { ScanResult } from '@features/scanner/hooks';

export const convertStringToByteArray = (str: string) => str.split('').map((c) => c.charCodeAt(0));

/**
 * Builds an object that would be returned from the Keyence scanner upon a scan of a UPC barcode.
 *
 * @param resultString The string to be converted to a ScanResult (typically a GUID)
 * @returns {ScanResult}
 */
export const buildUPCScanResult = (resultString: string): ScanResult => ({
  mDecodeResult: 'SUCCESS',
  mCodeType: 'UPC/EAN/JAN',
  mStringData: resultString,
  mRawData: convertStringToByteArray(resultString),
});
