import { format, formatInTimeZone, getTimezoneOffset, toDate } from 'date-fns-tz';
import { useMemo, useCallback } from 'react';
import { addHours, parseISO } from 'date-fns';
import { useAppSelector } from '@store/hooks';
import { selectCurrentBuildingTZ } from '@features/auth/authSlice';
import { buildUTCDateTimeString, createTZBoundDateUtils } from '@utils';

export function useLocalDateFormatter() {
  const currentBuildingTZ = useAppSelector(selectCurrentBuildingTZ);

  // Map short timezones to their IANA counterparts
  const shortTZToKnownTZ: Record<string, string> = {
    PST: 'America/Los_Angeles',
    EST: 'America/New_York',
    CST: 'America/Chicago',
    MST: 'America/Denver',
  };

  // If the timezone is a short code, map to IANA. Otherwise, use the timezone as provided
  const currentBuildingFullTZ = currentBuildingTZ
    ? shortTZToKnownTZ[currentBuildingTZ] ?? currentBuildingTZ
    : undefined;
  const { formatDate, formatDateTime } = createTZBoundDateUtils(currentBuildingFullTZ);

  const getBuildingTzOffsetHours = useCallback(() => {
    if (!currentBuildingFullTZ) return 0;

    const offsetMs = getTimezoneOffset(currentBuildingFullTZ);

    return offsetMs / (1000 * 60 * 60);
  }, [currentBuildingFullTZ]);

  const getTzOffsetDifference = useCallback(() => {
    const clientDateTime = new Date();
    clientDateTime.setMilliseconds(0); // for rounding

    // get the equivalent datetime in the facility's timezone
    // e.g. as a supervisor user in LA working at 9:00 AM, get
    // the current datetime for a facility in Atlanta such that we
    // can compare the dates to find the time difference.
    const buildingLocaleString = clientDateTime.toLocaleString('en-US', {
      timeZone: currentBuildingFullTZ,
    });
    const buildingDate = new Date(buildingLocaleString);
    const tzDifferenceMs = buildingDate.getTime() - clientDateTime.getTime();

    // return difference in hours
    return tzDifferenceMs / (1000 * 60 * 60);
  }, [currentBuildingFullTZ]);

  const shiftDateToBuildingTimezone = useCallback(
    (date: Date | string): Date => {
      const d = typeof date === 'string' ? new Date(date) : date;
      return addHours(d, getTzOffsetDifference());
    },
    [getTzOffsetDifference]
  );

  const shiftBackDateTimeToUTC = useCallback(
    (date: Date | string): Date => {
      const d = typeof date === 'string' ? new Date(date) : date;
      return addHours(d, -1 * getTzOffsetDifference());
    },
    [getTzOffsetDifference]
  );

  const formatOrderExpectedDate = useCallback(
    (display: boolean, expectedAtDate?: string, expectedAtTime?: string | null): string => {
      if (!expectedAtDate) return '';

      const date = expectedAtTime
        ? new Date(buildUTCDateTimeString(expectedAtDate, expectedAtTime))
        : toDate(expectedAtDate, {
            timeZone: currentBuildingFullTZ,
          });

      if (!currentBuildingFullTZ) {
        return display ? format(date, 'M/d/yyyy') : format(date, 'yyyy-MM-dd');
      }

      return display
        ? formatInTimeZone(date, currentBuildingFullTZ, 'M/d/yyyy')
        : formatInTimeZone(date, currentBuildingFullTZ, 'yyyy-MM-dd');
    },
    [currentBuildingFullTZ]
  );

  const formatOrderExpectedTime = useCallback(
    (display: boolean, expectedAtDate?: string, expectedAtTime?: string | null): string => {
      if (!expectedAtDate || !expectedAtTime) return '';

      const date = new Date(buildUTCDateTimeString(expectedAtDate, expectedAtTime));

      if (!currentBuildingFullTZ) {
        return display ? format(date, 'h:mm a zzz') : format(date, 'HH:mm:ss');
      }

      return display
        ? formatInTimeZone(date, currentBuildingFullTZ, 'h:mm a zzz')
        : formatInTimeZone(date, currentBuildingFullTZ, 'HH:mm:ss');
    },
    [currentBuildingFullTZ]
  );

  const displayOrderExpectedDate = useCallback(
    (expectedAtDate?: string, expectedAtTime?: string | null) =>
      formatOrderExpectedDate(true, expectedAtDate, expectedAtTime),
    [formatOrderExpectedDate]
  );

  const normalizeOrderExpectedDate = useCallback(
    (expectedAtDate?: string, expectedAtTime?: string | null) =>
      formatOrderExpectedDate(false, expectedAtDate, expectedAtTime),
    [formatOrderExpectedDate]
  );

  const displayOrderExpectedTime = useCallback(
    (expectedAtDate?: string, expectedAtTime?: string | null) =>
      formatOrderExpectedTime(true, expectedAtDate, expectedAtTime),
    [formatOrderExpectedTime]
  );

  const normalizeOrderExpectedTime = useCallback(
    (expectedAtDate?: string, expectedAtTime?: string | null) =>
      formatOrderExpectedTime(false, expectedAtDate, expectedAtTime),
    [formatOrderExpectedTime]
  );

  const formatISOStringInBuildingTimezone = useCallback(
    (isoDateString: string, formatString: string): string => {
      if (!currentBuildingFullTZ || !isoDateString || !formatString) return '';

      const date = parseISO(isoDateString);
      return formatInTimeZone(date, currentBuildingFullTZ, formatString);
    },
    [currentBuildingFullTZ]
  );

  return useMemo(
    () => ({
      formatDate,
      formatDateTime,
      formatISOStringInBuildingTimezone,
      displayOrderExpectedDate,
      normalizeOrderExpectedDate,
      displayOrderExpectedTime,
      normalizeOrderExpectedTime,
      currentBuildingTZ,
      getBuildingTzOffsetHours,
      currentBuildingFullTZ,
      shiftDateToBuildingTimezone,
      shiftBackDateTimeToUTC,
    }),
    [
      formatDate,
      formatDateTime,
      formatISOStringInBuildingTimezone,
      displayOrderExpectedDate,
      getBuildingTzOffsetHours,
      normalizeOrderExpectedDate,
      displayOrderExpectedTime,
      normalizeOrderExpectedTime,
      currentBuildingTZ,
      currentBuildingFullTZ,
      shiftDateToBuildingTimezone,
      shiftBackDateTimeToUTC,
    ]
  );
}
