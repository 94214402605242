import { mode } from '@chakra-ui/theme-tools';
import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const ScannerFlowCompleteTemplate: ComponentStyleConfig = {
  parts: [
    'resultBanner',
    'resultBannerText',
    'resultIcon',
    'additionalTextContainer',
    'additionalText',
  ],
  baseStyle: (props) => ({
    resultBanner: {},
    resultBannerText: {
      color: mode('white', 'gray.900')(props),
      fontSize: '3xl',
      fontWeight: 'bold',
      lineHeight: '40px',
    },
    resultIcon: {
      color: mode('white', 'gray.900')(props),
      ml: 8,
    },
    additionalTextContainer: {},
    additionalText: {},
  }),
  variants: {
    success: (props) => ({
      resultBanner: {
        bgColor: mode('green.500', 'green.300')(props),
      },
    }),
    error: (props) => ({
      resultBanner: {
        bgColor: mode('red.500', 'red.300')(props),
      },
    }),
    alert: (props) => ({
      resultBanner: {
        bgColor: mode('blue.500', 'blue.300')(props),
      },
    }),
  },
};
