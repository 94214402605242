import { useState } from 'react';
import { BiBuilding, BiLayer, BiMap, BiMapPin } from 'react-icons/bi';
import { FaBoxes, FaPallet } from 'react-icons/fa';
import {
  AddInventory,
  AddItems,
  AddLocations,
  BuildingAddress,
  BuildingName,
  UnitsOfMeasure,
} from './pages';
import { MultiStepForm } from '@components/multi-step-form';
import type { FormStep } from '@components/multi-step-form';

export type CreateBuildingFormState = {
  buildingName?: string;
  providerId?: string;
  shipperId?: string;
  shipperName?: string;
  didSkipItemUpload?: boolean;
};

const steps: FormStep<CreateBuildingFormState>[] = [
  {
    label: 'Building Name',
    icon: <BiBuilding />,
    pageComponent: BuildingName,
  },
  {
    label: 'Address',
    icon: <BiMapPin />,
    pageComponent: BuildingAddress,
  },
  {
    label: 'Units of Measure',
    optional: true,
    icon: <FaBoxes />,
    pageComponent: UnitsOfMeasure,
  },
  {
    label: 'Locations',
    optional: true,
    icon: <BiMap />,
    pageComponent: AddLocations,
  },
  {
    label: 'Items',
    optional: true,
    icon: <BiLayer />,
    pageComponent: AddItems,
  },
  {
    label: 'LPNs',
    optional: true,
    icon: <FaPallet />,
    pageComponent: AddInventory,
  },
];

const initialFormState: CreateBuildingFormState = {
  buildingName: '',
  providerId: '',
  shipperId: '',
  shipperName: '',
  didSkipItemUpload: false,
};

export const CreateBuilding = ({
  isOpen,
  onCloseModal,
}: {
  isOpen: boolean;
  onCloseModal: () => void;
}) => {
  const [multiStepFormState, setMultiStepFormState] =
    useState<CreateBuildingFormState>(initialFormState);

  return (
    <MultiStepForm
      isOpen={isOpen}
      multiStepFormState={multiStepFormState}
      onCloseModal={onCloseModal}
      setMultiStepFormState={setMultiStepFormState}
      steps={steps}
      title="New Warehouse Setup"
    />
  );
};

export default CreateBuilding;
