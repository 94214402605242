import { useCallback, useMemo } from 'react';
import type { Pagination } from '@store/services/api.generated';
import type { PageSizeOption } from '@components';
import { useUrlState } from '@hooks';

export type TablePaginationOptions = {
  defaultPageNumber?: number;
  defaultPageSize?: PageSizeOption;
};

type PageState = {
  pageNumber: number;
  pageSize: number;
};

export function useTablePagination({
  defaultPageNumber = 1,
  defaultPageSize = 20,
}: TablePaginationOptions = {}) {
  const defaultPage: Pagination = useMemo(
    () => ({
      page_number: defaultPageNumber,
      page_size: defaultPageSize,
      total_entries: 0,
      total_pages: 1,
    }),
    [defaultPageNumber, defaultPageSize]
  );

  const [pageState, setPageState] = useUrlState<PageState>(
    'page',
    useMemo(
      () => ({ pageNumber: defaultPageNumber, pageSize: defaultPageSize }),
      [defaultPageNumber, defaultPageSize]
    ),
    useCallback(
      (
        searchParams: URLSearchParams,
        { pageSize: newPageSize, pageNumber: newPageNumber }: PageState
      ) => {
        // whenever these values are changed in state, we need to serialize them such
        // that they can be placed in the URL
        if (newPageSize || newPageNumber) {
          searchParams.set('page_size', newPageSize ? `${newPageSize}` : `${defaultPageSize}`);
          searchParams.set(
            'page_number',
            newPageNumber ? `${newPageNumber}` : `${defaultPageNumber}`
          );
        } else {
          searchParams.delete('page_number');
          searchParams.delete('page_size');
        }
      },
      [defaultPageNumber, defaultPageSize]
    ),
    useCallback(
      (searchParams: URLSearchParams) => {
        const searchParamPageSize = searchParams.get('page_size');
        const searchParamPageNumber = searchParams.get('page_number');

        // when the search params in the URL change, we need to normalize them so
        // they can be saved in state and used for paginating API data
        if (searchParamPageSize || searchParamPageNumber) {
          return {
            pageSize: searchParamPageSize ? parseInt(searchParamPageSize) : defaultPageSize,
            pageNumber: searchParamPageNumber ? parseInt(searchParamPageNumber) : defaultPageNumber,
          };
        }

        return undefined;
      },
      [defaultPageNumber, defaultPageSize]
    ),
    useCallback(
      (a?: PageState, b?: PageState) =>
        a?.pageSize === b?.pageSize && a?.pageNumber === b?.pageNumber,
      []
    )
  );

  const setPage = useCallback(
    ({ page_number, page_size }: { page_number: number; page_size: number }) => {
      setPageState({ pageNumber: page_number, pageSize: page_size });
    },
    [setPageState]
  );

  return useMemo(
    () => ({
      defaultPage,
      page: {
        page_size: pageState?.pageSize,
        page_number: pageState?.pageNumber,
        total_pages: 1,
        total_entries: 0,
      },
      setPage,
    }),
    [defaultPage, pageState, setPage]
  );
}
