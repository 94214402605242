import { Badge, Box, Flex, Link, Text } from '@chakra-ui/react';
import type { OutboundOrder, Shipper } from '@store/services/api.generated';
import { useNavigate } from 'react-router-dom';
import { BarcodeBuddyResult, LineItemDetails } from './components';

export function OutboundPreview({
  closeModal,
  order,
  shipper,
  handleClick = () => {},
}: {
  closeModal: () => void;
  order: OutboundOrder;
  shipper?: Shipper;
  handleClick?: () => void;
}) {
  const navigate = useNavigate();

  const directLink = `/outbounds/${encodeURIComponent(shipper?.name ?? '')}/${encodeURIComponent(
    order.external_id ?? ''
  )}`;

  let badgeColor = '';

  switch (order?.status) {
    case 'shipped':
      badgeColor = 'green';
      break;
    case 'staged':
    case 'packed':
    case 'packing':
    case 'picking':
    case 'picked':
      badgeColor = 'orange';
      break;
    case 'canceled':
      badgeColor = 'red';
      break;
    default:
      badgeColor = 'blue';
  }

  const lineItems = (order.lines ?? []).map((line) => ({
    sku: line.product?.sku,
    quantity: line.quantity,
    shipperName: shipper?.name ?? '--',
  }));

  return (
    <BarcodeBuddyResult
      type="outbound"
      directLink={directLink}
      closeModal={closeModal}
      handleClick={handleClick}
    >
      <Box py={1}>
        <Flex alignItems="center" gridGap={4}>
          <Text
            as={Link}
            onClick={() => {
              handleClick();
              navigate(directLink);
              closeModal();
            }}
            fontSize="xl"
            fontWeight="bold"
          >
            {order?.external_id ?? '--'}
          </Text>
          <Badge colorScheme={badgeColor}>{order?.status ?? '--'}</Badge>
        </Flex>

        <Text fontSize="sm" variant="lighter">
          Shipper: {shipper?.name ?? '--'}
        </Text>

        <LineItemDetails lineItems={lineItems} closeModal={closeModal} />
      </Box>
    </BarcodeBuddyResult>
  );
}
