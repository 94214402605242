import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

export const Toast: ComponentStyleConfig = {
  parts: ['container', 'iconContainer', 'messageContainer', 'title', 'description'],
  baseStyle: (props) => ({
    container: {
      bgColor: mode('white', 'darkmode.gray.700')(props),
      borderRadius: '0.25rem',
      boxShadow: 'md',
      color: mode('gray.900', 'darkmode.gray.100')(props),
      justifyContent: 'space-between',
      minH: '2.75rem',
      p: 0,
      position: 'relative',
      transition: 'left 0.2s ease-out',
      w: '22.5rem',
    },
    iconContainer: {
      alignItems: 'center',
      borderRadius: '0.25rem 0 0 0.25rem',
      justifyContent: 'center',
      w: '2.25rem',
    },
    messageContainer: {
      flex: 1,
      flexFlow: 'row wrap',
      pl: 4,
      pr: 2,
      py: 3,
    },
    title: {
      fontWeight: 'bold',
      m: 0,
      w: 'full',
    },
  }),
  variants: {
    success: (props) => ({
      iconContainer: {
        bgColor: mode('green.400', 'green.600')(props),
      },
    }),
    warning: (props) => ({
      iconContainer: {
        bgColor: mode('mango.400', 'mango.600')(props),
      },
    }),
    error: (props) => ({
      iconContainer: {
        bgColor: mode('red.400', 'red.600')(props),
      },
    }),
    info: (props) => ({
      iconContainer: {
        bgColor: mode('blue.400', 'blue.600')(props),
      },
    }),
  },
};
