import type { LocationSubtype, LocationType } from '@store/services/api.generated';
import { capitalize } from './capitalize';

export function formatLocationType(type?: LocationType): string {
  if (!type) return '--';

  return capitalize(type);
}

export function formatLocationSubtype(subtype?: LocationSubtype): string {
  if (!subtype) return '--';

  switch (subtype) {
    case 'crossdock':
      return 'Cross Dock';
    case 'dock_door':
      return 'Dock Door';
    default:
      return capitalize(subtype);
  }
}
