import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '@store/hooks';
import { selectCurrentBuildingId } from '@features/auth/authSlice';

interface StorageListOptions {
  limit?: number;
  dedupItems?: boolean;
}

interface StorageOption {
  value: string | number;
}

export function useLocalStorageList<T extends StorageOption>(
  name: string,
  { limit = 10, dedupItems = true }: StorageListOptions
) {
  const [listsBuildingMap, setListBuildingsMap] = useState<Record<string, T[]>>({});

  const buildingId = useAppSelector(selectCurrentBuildingId);

  const PREFIX = '__wms_list__';
  const localStorageName = `${PREFIX}${name}`;

  const list = listsBuildingMap[buildingId];

  useEffect(() => {
    if (!buildingId) return;

    const initialListsBuildingMapString = window.localStorage.getItem(localStorageName);

    const initialListsBuildingMap = initialListsBuildingMapString
      ? JSON.parse(initialListsBuildingMapString)
      : { [buildingId]: [] };

    if (!initialListsBuildingMap[buildingId]) {
      setListBuildingsMap({ ...initialListsBuildingMap, [buildingId]: [] });
    } else {
      setListBuildingsMap(initialListsBuildingMap);
    }
  }, [localStorageName, buildingId]);

  const persistListBuildingsMap = useCallback(
    (listForBuilding: T[]) => {
      const newListsBuildingMap = {
        ...listsBuildingMap,
        [buildingId]: listForBuilding,
      };
      window.localStorage.setItem(localStorageName, JSON.stringify(newListsBuildingMap));
      setListBuildingsMap(newListsBuildingMap);
    },
    [buildingId, listsBuildingMap, localStorageName]
  );

  const resetListForBuilding = useCallback(() => {
    persistListBuildingsMap([]);
  }, [persistListBuildingsMap]);

  const addItem = useCallback(
    (value: T) => {
      let updatedList = [value, ...list];

      if (dedupItems) {
        const itemValues = updatedList.map((item) => item.value);
        updatedList = updatedList.filter((item, index, array) => {
          return itemValues.indexOf(item.value) === index;
        });
      }

      updatedList = updatedList.slice(0, limit);

      persistListBuildingsMap(updatedList);
    },
    [list, dedupItems, limit, persistListBuildingsMap]
  );

  const removeItem = useCallback(
    (value: T) => {
      const updatedList = list.filter((item) => {
        const itemKeyToRemove = value.value;
        const itemKey = item.value;

        if (itemKeyToRemove && itemKey && itemKey === itemKeyToRemove) return false;

        return true;
      });

      if (list.length !== updatedList.length) {
        persistListBuildingsMap(updatedList);
      }
    },
    [list, persistListBuildingsMap]
  );

  return useMemo(
    () => ({
      addItem,
      list,
      removeItem,
      resetListForBuilding,
    }),
    [addItem, list, removeItem, resetListForBuilding]
  );
}
