import { Box, Skeleton } from '@chakra-ui/react';
import { LinkWithBuildingId } from '@components';
import type { OutboundOrder } from '@store/services/api.generated';
import { useOutboundOrderIdentifier } from '@hooks';

interface OutboundOrderLinkProps {
  outbound?: OutboundOrder;
  shipperName?: string;
  backPath?: string;
}

export const OutboundOrderLink = ({ outbound, shipperName, backPath }: OutboundOrderLinkProps) => {
  const { orderIdentifier, isLoading } = useOutboundOrderIdentifier(outbound);

  if (isLoading) {
    return <Skeleton h={4} data-testid="loading" />;
  }

  return outbound && shipperName ? (
    <LinkWithBuildingId
      state={{ backPath }}
      to={`/outbounds/${encodeURIComponent(shipperName)}/${encodeURIComponent(
        outbound?.external_id!
      )}`}
    >
      {orderIdentifier}
    </LinkWithBuildingId>
  ) : (
    <Box>--</Box>
  );
};

export default OutboundOrderLink;
