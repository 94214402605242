import { useColorModeValue as mode } from '@chakra-ui/react';

interface HighlightSelectedPropsType {
  outlineWidth: number;
  outlineColor: string;
}

export const useHighlightSelectedProps = (
  shouldHighlight: boolean
): HighlightSelectedPropsType | {} => {
  const outlineColor = mode('blue.500', 'blue.300');
  if (shouldHighlight) {
    return {
      outline: '2px solid',
      outlineOffset: '-1px',
      outlineColor,
    };
  }
  return {};
};
