import { Box, Button, Flex, Text, useColorModeValue as mode } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type React from 'react';
import { useState } from 'react';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import type { ScanResultType } from '../types';
import { getIconForType, getTitleForType } from '../utils';

export function BarcodeBuddyResult({
  type,
  children,
  closeModal,
  directLink,
  handleClick,
}: {
  type: ScanResultType;
  directLink: string;
  children: React.ReactNode;
  closeModal: () => void;
  handleClick: () => void;
}) {
  const [isHoveringOverHeader, setIsHoveringOverHeader] = useState(false);

  const navigate = useNavigate();

  const headerBackgroundColor = mode('blue.700', 'darkmode.blue.700');
  const contentBackgroundColor = mode('gray.300', 'darkmode.gray.600');
  const labelColor = mode('white', 'darkmode.gray.100');

  const title = getTitleForType(type);
  const icon = getIconForType(type);

  return (
    <Box backgroundColor={contentBackgroundColor} boxShadow="md" borderRadius="md">
      <Flex
        alignItems="center"
        gridGap={2}
        py={1}
        px={3}
        borderTopRadius="md"
        backgroundColor={headerBackgroundColor}
        onMouseEnter={() => {
          setIsHoveringOverHeader(true);
        }}
        onMouseLeave={() => {
          setIsHoveringOverHeader(false);
        }}
      >
        <Button
          onClick={() => {
            handleClick();
            navigate(directLink);
            closeModal();
          }}
          variant="unstyled"
          flex={1}
          h="auto"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          gridGap={2}
        >
          <FontAwesomeIcon icon={icon} size="sm" color={labelColor} />
          <Text color={labelColor}>{title}</Text>
          <FontAwesomeIcon
            style={{
              transition: 'all 0.3s ease-in-out',
              transform: isHoveringOverHeader ? 'translateX(10px)' : 'translateX(0px)',
            }}
            icon={faArrowRight}
            size="sm"
            color={labelColor}
          />
        </Button>
      </Flex>
      <Box px={3} py={1}>
        {children}
      </Box>
    </Box>
  );
}
