import type * as React from 'react';

import { FatalError } from './FatalError';
import { DatadogErrorBoundary } from './Datadog/ErrorBoundary';
import type { DatadogErrorBoundaryProps } from './Datadog/ErrorBoundary';
import { Loading } from './Loading';
export interface ErrorBoundaryProps extends DatadogErrorBoundaryProps {
  children?: React.ReactNode;
}

/**
 * A generic error boundary component that sends events to Datadog. It is preconfigured to
 * log to cypress and the console, and will fallback to ErrorBoundaryPage.
 *
 * @remarks You shouldn't need to modify `onError`, and should only pass a `fallback` component if required
 */
export const ErrorBoundary = ({ children, ...props }: ErrorBoundaryProps) => (
  <DatadogErrorBoundary
    fallback={FatalError}
    onError={(error) => {
      // eslint-disable-next-line no-console
      console.log('ErrorBoundary onError:', error.toString());
      (window as any).Cypress?.log({
        name: 'ErrorBoundary Error',
        message: error.toString(),
      });
    }}
    {...props}
  >
    {children}
  </DatadogErrorBoundary>
);

export const ErrorBoundaryReloadOnDynamicImport = ({ children, ...props }: ErrorBoundaryProps) => {
  return (
    <DatadogErrorBoundary
      fallback={({ error }) => {
        const isLoadingFailure =
          error.message?.includes('fetch dynamically imported module') ||
          error.message?.includes('loading dynamically imported module') ||
          error.message?.includes('Loading chunk');

        if (isLoadingFailure) {
          window.location.reload();
          return <Loading isLoaded={false} />;
        }

        return <FatalError error={error} />;
      }}
      onError={(error) => {
        // eslint-disable-next-line no-console
        console.log('ErrorBoundary onError:', error.toString());
        (window as any).Cypress?.log({
          name: 'ErrorBoundary Error',
          message: error.toString(),
        });
      }}
      {...props}
    >
      {children}
    </DatadogErrorBoundary>
  );
};
