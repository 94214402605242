import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import type { AlertStatus } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/styled-system';
import { transparentize } from '@chakra-ui/theme-tools';

type ExpandedStyleFunctionProps = StyleFunctionProps & { status?: AlertStatus };

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const STATUS_TO_COLOR_SCHEME = {
  info: 'blue',
  warning: 'orange',
  success: 'green',
  error: 'red',
} as Record<AlertStatus, string>;

const STATUS_COLORS = {
  [STATUS_TO_COLOR_SCHEME['info']]: {
    fg: {
      light: 'colors.blue.500',
      dark: 'colors.blue.600',
    },
    bg: {
      light: 'colors.blue.50',
      dark: 'colors.darkmode.blue.200',
    },
  },
  [STATUS_TO_COLOR_SCHEME['warning']]: {
    fg: {
      light: 'colors.mango.600',
      dark: 'colors.mango.700',
    },
    bg: {
      light: 'colors.mango.50',
      dark: 'colors.mango.100',
    },
  },
  [STATUS_TO_COLOR_SCHEME['success']]: {
    fg: {
      light: 'colors.green.600',
      dark: 'colors.green.700',
    },
    bg: {
      light: 'colors.green.50',
      dark: 'colors.green.100',
    },
  },
  [STATUS_TO_COLOR_SCHEME['error']]: {
    fg: {
      light: 'colors.red.500',
      dark: 'colors.red.600',
    },
    bg: {
      light: 'colors.red.50',
      dark: 'colors.red.100',
    },
  },
};

const $fg = cssVar('alert-fg');
const $bg = cssVar('alert-bg');

const baseStyle = definePartsStyle({
  container: {
    py: 2,
    borderRadius: '4px',
  },
  description: { fontSize: 'md', lineHeight: 5 },
  title: { fontSize: 'md', lineHeight: 5 },
  icon: {
    mt: 0.5,
    mr: 2,
    boxSize: 4,
    alignSelf: 'flex-start',
  },
});

function getBg(props: ExpandedStyleFunctionProps) {
  const { theme, colorScheme: c } = props;
  const status = props.status ?? 'info';
  const matchedColorStatus = STATUS_COLORS[c];
  const schemeMatchesStatus = STATUS_TO_COLOR_SCHEME[status] === c;

  if (matchedColorStatus && schemeMatchesStatus) {
    return matchedColorStatus.bg;
  }

  const darkBg = transparentize(`${c}.200`, 0.16)(theme);
  return {
    light: `colors.${c}.100`,
    dark: darkBg,
  };
}

function getFg(props: ExpandedStyleFunctionProps) {
  const { colorScheme: c } = props;
  const status = props.status ?? 'info';
  const matchedColorStatus = STATUS_COLORS[c];
  const schemeMatchesStatus = STATUS_TO_COLOR_SCHEME[status] === c;

  if (matchedColorStatus && schemeMatchesStatus) {
    return matchedColorStatus.fg;
  }

  const darkFg = `${c}.600`;
  return {
    light: `colors.${c}.600`,
    dark: darkFg,
  };
}

const variantSubtle = definePartsStyle((props) => {
  const bg = getBg(props);
  const fg = getFg(props);

  return {
    description: {
      color: 'gray.800',
    },
    container: {
      color: 'gray.800',
      [$fg.variable]: fg.light,
      [$bg.variable]: bg.light,

      _dark: {
        ...(props.colorScheme === 'blue' && {
          a: {
            color: $fg.reference,
          },
        }),
        color: 'gray.900',
        [$fg.variable]: fg.dark,
        [$bg.variable]: bg.dark,
      },
    },
  };
});

const variants = {
  subtle: variantSubtle,
};

export const Alert = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'blue',
  },
});
