import type { AbsoluteCenterProps } from '@chakra-ui/react';
import { AbsoluteCenter, Box, useColorModeValue } from '@chakra-ui/react';

interface ProgressbarProps extends AbsoluteCenterProps {
  value: number;
}

export const Progressbar = (props: ProgressbarProps) => {
  const { value, ...rest } = props;
  return (
    <AbsoluteCenter
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
      aria-valuetext={`Progress: ${value}%`}
      position="absolute"
      height="calc(100% - 5rem)"
      axis="vertical"
      bg={useColorModeValue('gray.100', 'darkmode.gray.200')}
      width="2px"
      left="calc(28% - 2px)"
      top={8}
      transform="translateX(-50%)"
      {...rest}
    >
      <Box bg={useColorModeValue('blue.500', 'darkmode.blue.300')} height={`${value}%`} />
    </AbsoluteCenter>
  );
};
