import type { KnownTopic } from '@features/realtime/realtimeSlice';
import type { ActivityAction } from '@store/services/api.generated';
import { PubSub } from '@utils/pubsub';

export type ChannelsPubSub = {
  event: ActivityAction;
  topic: KnownTopic;
  payload: {
    data: unknown;
    meta: {
      requestId: string;
    };
  };
};

let instance: PubSub<ChannelsPubSub>;

export const getChannelsPubSub = () => {
  if (instance) return instance;

  instance = new PubSub<ChannelsPubSub>();

  if (process.env.NODE_ENV === 'development') {
    (window as any).channelsPubSub = instance;
  }

  return instance;
};
