import { Box, Circle, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import type { ReactNode } from 'react';

interface StepProps {
  children?: ReactNode;
  label?: string;
  optional?: boolean;
  state: 'active' | 'complete' | 'incomplete';
  stepNumber?: number;
}

export const Step = (props: StepProps) => {
  const { label, children, state, stepNumber, optional } = props;

  const isCompleted = state === 'complete' || state === 'active';
  const isIncompleted = state === 'incomplete';

  const inCompletedColor = useColorModeValue('gray.600', 'gray.300');
  const defaultColor = useColorModeValue('white', 'gray.900');
  const completedBg = useColorModeValue('blue.500', 'blue.300');
  const incompletedBg = useColorModeValue('gray.300', 'gray.600');
  const getFontWeight = (state: string) => {
    if (state === 'active') return 'bold';
    if (state === 'completed') return 'semibold';
    return 'light';
  };

  return (
    <Box as="li" display="flex" alignContent="center" justifyContent="end" py={6}>
      <Flex mr={4} direction="column" align="end">
        <Text
          fontWeight={getFontWeight(state)}
          textAlign="right"
          data-testid={`step-${stepNumber}-name`}
        >
          {label}
        </Text>
        <Text fontWeight="thin" fontSize="xs">
          {optional ? 'Optional' : '* Required'}
        </Text>
      </Flex>
      <Circle
        aria-hidden
        zIndex={1}
        position="relative"
        size="8"
        bg={isCompleted ? completedBg : incompletedBg}
      >
        <Box as="span" color={isIncompleted ? inCompletedColor : defaultColor} fontWeight="bold">
          {children}
        </Box>
      </Circle>
      <Box srOnly>{state === 'complete' ? `${label} - Completed` : label}</Box>
    </Box>
  );
};
