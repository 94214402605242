import { nanoid } from '@reduxjs/toolkit';

export interface Subscription {
  unsubscribe(): void;
}

export type Client<T> = (value: T) => any;

export interface Publisher<T> {
  subscribe(client: Client<T>): Subscription | undefined;
}

type ClientMap<T> = Map<string, Client<T>>;

class PubSubSubscription<T> implements Subscription {
  private clients: ClientMap<T> | undefined;
  private clientId: string | undefined;

  constructor(clients: ClientMap<T>, clientId: string) {
    this.clients = clients;
    this.clientId = clientId;
  }

  unsubscribe(): void {
    if (this.clients && this.clientId) {
      this.clients.delete(this.clientId);
    }
    this.clients = undefined;
    this.clientId = undefined;
  }
}

export class PubSub<T> implements Publisher<T> {
  private clients: ClientMap<T>;

  constructor() {
    this.clients = new Map<string, Client<T>>();
  }

  get size(): number {
    return this.clients.size;
  }

  /**
   *
   * @param client A callback function that receives a message and returns any value.
   * @param reservedClientId An optional client id to use for the subscription. If you provide this, it will only allow one subscription per provided ID. Useful in the event you want to subscribe and can't control
   *                         how many times the function might try to restablish a subscription.
   * @returns
   */
  subscribe(client: Client<T>, reservedClientId?: string): Subscription | undefined {
    if (reservedClientId && this.clients?.get(reservedClientId)) {
      return;
    }
    if (!client) {
      return {
        unsubscribe: () => undefined,
      };
    }

    const clientId = reservedClientId || nanoid();

    this.clients.set(clientId, client);

    return new PubSubSubscription(this.clients, clientId);
  }

  emit(value: T) {
    if (this.clients.size === 0) {
      return;
    }
    this.clients.forEach((client) => client(value));
  }

  clear() {
    this.clients.clear();
    this.clients = new Map<string, Client<T>>();
  }
}
