import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@store';
import type { Location, Product } from '@store/services/api.generated';

export type SetScannableEntityActionPayload = {
  type: SearchEntity;
  metadata?: QuickScanMetadata;
};

export type SearchEntity =
  | 'location'
  | 'lpn'
  | 'productBarcode'
  | 'suggestedPickingLpn'
  | 'shippingLabel';
export type QuickScanMetadata = {
  location?: Location;
  product?: Product;
};

export const quickScanSlice = createSlice({
  name: 'quickScan',
  initialState: {
    scannerVisible: false,
    scannableEntity: 'location' as SearchEntity,
    metadata: {} as QuickScanMetadata,
  },
  reducers: {
    setScannableEntity: (state, action: PayloadAction<SetScannableEntityActionPayload>) => ({
      ...state,
      scannerVisible: true,
      scannableEntity: action.payload.type,
      metadata: {
        ...(action?.payload?.metadata ?? {}),
      },
    }),
  },
});

export const { setScannableEntity } = quickScanSlice.actions;
export default quickScanSlice.reducer;

export const selectCurrentScannableEntity = (state: RootState) => state.quickScan.scannableEntity;
export const selectCurrentQuickScanMetadata = (state: RootState) => state.quickScan.metadata;
