import * as yup from 'yup';
import { dateValidationSchema } from './dateValidationSchema';

export const createReturnSchema = yup.object({
  external_id: yup.string(),
  shipper_id: yup.string().required('Shipper is required'),
  tracking_number: yup.string().required('Tracking number is required'),
  carrier: yup.string(),
  custom_reference: yup.string(),
  name: yup.string(),
  line1: yup.string(),
  line2: yup.string(),
  city: yup.string(),
  state: yup.string(),
  country: yup.string(),
  postal_code: yup.string(),
});

export const addReturnLineSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
  sku: yup.string().required(),
  uom: yup.string().required(),
  quantity: yup
    .number()
    .typeError('Quantity must be a number')
    .required('Quantity is required')
    .positive('Must be greater than 0'),
  return_reason: yup.string().required('Reason is required'),
  return_condition: yup.string().required('Condition is required'),
  lot_number: yup.string().when('$lotTracked', {
    is: true,
    then: yup.string().required('Lot number is required'),
  }),
  manufacturer_date: dateValidationSchema({
    label: 'Manufacturer Date',
    key: 'manufacturerDateTracked',
  }),
  expiration_date: dateValidationSchema({
    label: 'Expiration Date',
    key: 'expirationDateTracked',
  }),
  lpn: yup.string().required('LPN is required'),
});
