import { Box, Button } from '@chakra-ui/react';
import { WmsModal, WmsModalFooter } from '@components';
import type { UnitOfMeasure } from '@store/services/api.generated';

interface DeleteUomModalProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  uom?: UnitOfMeasure;
}

export const DeleteUomModal = ({
  isLoading,
  isOpen,
  onClose,
  onConfirm,
  uom,
}: DeleteUomModalProps) => (
  <WmsModal isOpen={isOpen} onClose={onClose} title="Delete unit of measure">
    <Box>
      Are you sure you want to delete {uom?.name} ({uom?.abbreviation})?
      <WmsModalFooter>
        <Button variant="outline" colorScheme="gray" onClick={onClose}>
          Cancel
        </Button>
        <Button colorScheme="red" data-testid="confirm" onClick={onConfirm} isLoading={isLoading}>
          Delete
        </Button>
      </WmsModalFooter>
    </Box>
  </WmsModal>
);
