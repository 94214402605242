import { useCallback } from 'react';
import { useToast } from './useToast';

export const useCSVDownload = <D extends (props: any) => { unwrap(): Promise<any> }>({
  downloadFn,
  filename,
}: {
  downloadFn: D;
  filename: string;
}) => {
  const { errorToast, successToast } = useToast();

  return useCallback(
    async (props: Parameters<D>[0], filenameOverride?: string) => {
      try {
        const csvData = await downloadFn(props).unwrap();
        const downloadLink = document.createElement('a');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = filenameOverride ?? filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        successToast('Download successful');
      } catch (err) {
        console.error(err);
        errorToast('The download failed. Please try again later.');
      }
    },
    [errorToast, downloadFn, filename, successToast]
  );
};
