import { useEffect, useCallback, useRef } from 'react';

/**
 * Establishes a global keydown listener that fires a callback fn when a key is pressed.
 */
type CallbackFunction = (event: KeyboardEvent) => void;

export const useGlobalKeydown = (callback: CallbackFunction) => {
  const callbackRef = useRef<CallbackFunction>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  const downHandler = useCallback((event: KeyboardEvent) => {
    callbackRef.current(event);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);
};
