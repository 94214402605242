import * as React from 'react';
import { Center, Spinner } from '@chakra-ui/react';
import type { CenterProps, SpinnerProps } from '@chakra-ui/react';

export type LoadingProps = CenterProps & {
  isLoaded: boolean;
  size?: SpinnerProps['size'];
  containerProps?: CenterProps;
};
export const Loading = ({
  children = <div />,
  isLoaded = false,
  size = 'xl',
  containerProps,
  h = '400px',
}: LoadingProps) => {
  return !isLoaded ? (
    <Center h={h} {...containerProps} data-testid="loading">
      <Spinner thickness="3px" emptyColor="blue.100" color="blue.500" size={size} />
    </Center>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};
