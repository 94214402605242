import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ButtonProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Spacer,
  Text,
  useDisclosure,
  useColorModeValue as mode,
  Checkbox,
  CheckboxGroup,
  VStack,
} from '@chakra-ui/react';
import { faEquals, faNotEqual } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import type { MouseEventHandler, ReactNode } from 'react';
import { memo, useMemo, useCallback, useState } from 'react';
import type { IncludeExcludeFilterValue } from '@hooks';
import { isOneOrMoreMultiSelectOptionSelected } from '@utils/filterSelection';
import { useHighlightSelectedProps } from '@hooks/useHighlightSelectedProps';
import { FilterLabel } from '@components';

export type TagFilterValue = {
  tag: string;
  exclude: boolean;
};

interface TagFilterProps extends Omit<ButtonProps, 'onChange' | 'value'> {
  allowTagFilter?: boolean;
  isLoading?: boolean;
  label?: ReactNode;
  onChange: (values: IncludeExcludeFilterValue[]) => void;
  tags?: string[];
  value: IncludeExcludeFilterValue[];
}

export const TagFilter = memo(
  ({
    allowTagFilter = true,
    isLoading,
    label,
    onChange,
    tags,
    value,
    ...buttonProps
  }: TagFilterProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [globalFilterMode, setGlobalFilterMode] = useState<'include' | 'exclude'>('include');

    const handleGlobalModeToggle: MouseEventHandler<HTMLButtonElement> = () => {
      setGlobalFilterMode(globalFilterMode === 'include' ? 'exclude' : 'include');
    };

    const handleSelectedChange = (tag: string, selected: boolean) => {
      if (selected) {
        onChange([...value, { filterValue: tag, exclude: globalFilterMode === 'exclude' }]);
        return;
      }

      onChange(value.filter((v) => v.filterValue !== tag));
    };

    const isTagDisabled = useCallback(
      (tag: string): boolean => {
        const tagValue = value.find((v) => v.filterValue === tag);
        if (!tagValue) {
          return false;
        }

        return (
          (tagValue.exclude && globalFilterMode === 'include') ||
          (!tagValue.exclude && globalFilterMode === 'exclude')
        );
      },
      [globalFilterMode, value]
    );

    const tagsInUse = useMemo(() => {
      const useExcludedTags = globalFilterMode === 'exclude';
      return value.filter((v) => v.exclude === useExcludedTags);
    }, [globalFilterMode, value]);

    const shouldHighlight = useMemo(() => isOneOrMoreMultiSelectOptionSelected(value), [value]);
    const highlightingProps = useHighlightSelectedProps(shouldHighlight);

    return (
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-start">
        <Box>
          {label && <FilterLabel label={label} />}
          <ButtonGroup
            isAttached
            variant="filter"
            isDisabled={!allowTagFilter}
            {...highlightingProps}
            outlineOffset="0px"
            borderRadius={shouldHighlight ? 'base' : 'none'}
            w="full"
          >
            <IconButton
              data-testid={`tag-filter-mode-${globalFilterMode}`}
              icon={
                <FontAwesomeIcon icon={globalFilterMode === 'include' ? faEquals : faNotEqual} />
              }
              aria-label="Toggle mode"
              onClick={handleGlobalModeToggle}
              borderRight={0}
            />
            <PopoverTrigger>
              <Button cursor="default" py={0} pr={2.5} display="inline" {...buttonProps}>
                <HStack>
                  <Text as="span" variant={mode('lighter', undefined)}>
                    Tags to {globalFilterMode} {tagsInUse.length > 0 && `(${tagsInUse.length})`}
                  </Text>
                  <Spacer />
                  <Box width={5}>
                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} size="xs" />
                  </Box>
                </HStack>
              </Button>
            </PopoverTrigger>
          </ButtonGroup>
        </Box>
        <PopoverContent borderRadius="md" bg={mode('white', 'darkmode.gray.800')} w="fit-content">
          <Box p={4} minW={48} maxH={72} overflowY="auto">
            {isLoading ? (
              <VStack data-testid="loading">
                <Skeleton h={4} w="full" />
                <Skeleton h={4} w="full" />
                <Skeleton h={4} w="full" />
              </VStack>
            ) : tags?.length ? (
              <CheckboxGroup value={tagsInUse.map((tag) => tag.filterValue)}>
                <VStack alignItems="left" w="full">
                  {tags.map((tag) => {
                    const isDisabled = isTagDisabled(tag);

                    return (
                      <HStack key={tag} justifyContent="space-between">
                        <Checkbox
                          data-testid={`tag-checkbox-${tag}`}
                          w="full"
                          value={tag}
                          isDisabled={isDisabled}
                          onChange={({ target: { checked } }) => handleSelectedChange(tag, checked)}
                        >
                          {tag}
                        </Checkbox>
                        {isDisabled && (
                          <Text
                            as="span"
                            fontSize="xs"
                            data-testid={`${
                              globalFilterMode === 'exclude' ? 'include' : 'exclude'
                            }-${tag}`}
                          >
                            <FontAwesomeIcon
                              icon={globalFilterMode === 'exclude' ? faEquals : faNotEqual}
                            />
                          </Text>
                        )}
                      </HStack>
                    );
                  })}
                </VStack>
              </CheckboxGroup>
            ) : (
              <Text fontSize="sm" fontWeight="semibold" color="gray.600">
                No tags
              </Text>
            )}
          </Box>
        </PopoverContent>
      </Popover>
    );
  }
);
