import { useCallback, useEffect, useRef, useMemo } from 'react';
import { usePrefetch } from '@store/services/api';
import { useTablePagination } from '@hooks/useTablePagination';
import type { FetchBaseQueryError, QueryDefinition } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/dist/query/react';
import type { SubscriptionOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import type { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import type { Pagination } from '@store/services/api.generated';
import type { TablePaginationOptions } from '@hooks/useTablePagination';

interface ArgsWithPagination extends Record<string, unknown> {
  page?: number;
  pageSize?: number;
}

interface ResponseWithPagination extends Record<string, unknown> {
  meta?: Pagination;
}

type QueryHook<P, R> = UseQuery<
  QueryDefinition<
    P,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    R,
    'api'
  >
>;

interface UseQuerySubscriptionOptions extends SubscriptionOptions {
  skip?: boolean;
  refetchOnMountOrArgChange?: boolean | number;
}

type PaginatedQueryOptions = {
  pagination?: TablePaginationOptions;
  query?: UseQuerySubscriptionOptions;
  disablePrefetch?: boolean;
};

export const usePaginatedQuery = <P extends ArgsWithPagination, R extends ResponseWithPagination>(
  endpointName: Parameters<typeof usePrefetch>[0],
  queryHook: QueryHook<P, R>,
  queryArgs: P,
  options?: PaginatedQueryOptions
) => {
  // Set initial value to true to prefetch a potential second page after the first API response
  const hasPendingPrefetch = useRef(true);
  const prefetchPage = usePrefetch(endpointName);
  const pagination = useTablePagination(options?.pagination);

  const queryArgsJson = JSON.stringify(queryArgs);
  const serializedQueryArgs = useMemo(() => JSON.parse(queryArgsJson), [queryArgsJson]);

  const query = queryHook(
    {
      ...serializedQueryArgs,
      page: pagination.page.page_number,
      pageSize: pagination.page.page_size,
    },
    options?.query
  );

  const prefetchNextPage = useCallback(
    (currentPage: number, pageSize?: number) => {
      if (options?.disablePrefetch) return;

      // Only prefetch if there are more pages to fetch
      if ((query.data?.meta?.total_pages ?? 1) > currentPage) {
        prefetchPage({
          page: (currentPage ?? 1) + 1,
          pageSize: pageSize ?? pagination.page.page_size,
          ...serializedQueryArgs,
        });
      }
    },
    [
      options?.disablePrefetch,
      pagination.page.page_size,
      prefetchPage,
      query.data?.meta?.total_pages,
      serializedQueryArgs,
    ]
  );

  const onPageChange = useCallback(
    (page: Partial<Pagination>) => {
      prefetchNextPage(page.page_number ?? 1, page.page_size ?? 20);
      pagination.setPage({
        ...page,
        page_size: page.page_size ?? 20,
        page_number: page.page_number ?? 1,
      });
    },
    [prefetchNextPage, pagination]
  );

  // Prefetch next page when a new response has been received after flagging a pending prefetch
  // Example: query args change -> set pending prefetch -> load data -> prefetch next page
  useEffect(() => {
    if (query.isLoading || query.isFetching || !hasPendingPrefetch.current) {
      return;
    }

    prefetchNextPage(pagination.page.page_number);
    hasPendingPrefetch.current = false;
  }, [onPageChange, pagination, prefetchNextPage, query.isFetching, query.isLoading]);

  // Flag a pending prefetch when query args change
  useEffect(() => {
    hasPendingPrefetch.current = true;
  }, [serializedQueryArgs]);

  return {
    onPageChange,
    ...pagination,
    ...query,
  };
};
