import {
  Divider,
  GridItem,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface LineItemDetailsProps {
  lineItems: {
    sku?: string;
    shipperName?: string;
    quantity?: number;
  }[];
  skuHeader?: string;
  closeModal: () => void;
}

export function LineItemDetails({
  closeModal,
  lineItems,
  skuHeader = 'SKU',
}: LineItemDetailsProps) {
  const dividerColor = mode('gray.600', 'darkmode.gray.300');
  const navigate = useNavigate();

  return (
    <SimpleGrid columns={2} gridTemplateColumns="auto auto" mt={5}>
      <GridItem>
        <Text variant="lighter" fontSize="sm">
          {skuHeader}
        </Text>
      </GridItem>
      <GridItem>
        <Text variant="lighter" fontSize="sm">
          QUANTITY
        </Text>
      </GridItem>
      {lineItems.map((li, index) => {
        return (
          <React.Fragment key={index}>
            <GridItem colSpan={2}>
              <Divider borderColor={dividerColor} />
            </GridItem>
            <GridItem>
              <Link
                onClick={() => {
                  navigate(
                    `/items/${encodeURIComponent(li.shipperName ?? '')}/${encodeURIComponent(
                      li.sku ?? ''
                    )}`
                  );
                  closeModal();
                }}
                fontSize="md"
              >
                <Text className="_no-translate">{li.sku ?? '--'}</Text>
              </Link>
            </GridItem>

            <GridItem fontSize="md">{li.quantity ?? '--'}</GridItem>
          </React.Fragment>
        );
      })}
    </SimpleGrid>
  );
}
