import { useGetShippersByShipperIdConfigQuery } from '@store/services/api';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import type { WithOrderIdentifier } from '@utils/getOrderIdentifier';
import { getOrderIdentifier } from '@utils/getOrderIdentifier';

type UseOrderIdentifier = {
  orderIdentifier?: string;
  isLoading: boolean;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
};

export const useOrderIdentifier = (
  orderType: 'inbound' | 'outbound',
  order?: WithOrderIdentifier
): UseOrderIdentifier => {
  const {
    data: config,
    isLoading,
    isError,
    error,
  } = useGetShippersByShipperIdConfigQuery(
    {
      shipperId: order?.shipper_id ?? '',
    },
    {
      skip: !order?.shipper_id,
    }
  );

  const orderIdentifier = useMemo(
    () => getOrderIdentifier(orderType, order ?? {}, config?.data ?? {}),
    [config, order, orderType]
  );

  return {
    orderIdentifier,
    isLoading,
    isError,
    error,
  };
};

export const useOutboundOrderIdentifier = (order?: WithOrderIdentifier) =>
  useOrderIdentifier('outbound', order);

export const useInboundOrderIdentifier = (order?: WithOrderIdentifier) =>
  useOrderIdentifier('inbound', order);
