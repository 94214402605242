import * as React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import type { RumGlobal } from '@datadog/browser-rum';

import { useAuth, useConfig } from '@hooks';
import { useAppSelector } from '@store/hooks';
import { selectCurrentBuildingId } from '@features/auth/authSlice';

const IGNORED_ERROR_MESSAGES = [
  'Network Error',
  'unauthorized',
  'Userflow.js',
  'status code 401',
  'websocket error',
];

export type DatadogProviderContextValue = {
  instance: null | RumGlobal;
};
export const DatadogProviderContext = React.createContext<DatadogProviderContextValue>({
  instance: null,
});

export interface DataDogProviderProps {
  children: React.ReactNode;
  version?: string;
}

// Note: it's not really necessary to throw `datadogRum` into state in the provider, but we're doing this for ease of use if the underlying implementation changes.
export const DatadogProvider = ({ children, version }: DataDogProviderProps) => {
  const {
    DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE_NAME = 'unlabeled-service',
    DATADOG_ENV = 'nonprod',
  } = useConfig();
  const { user } = useAuth();
  const buildingId = useAppSelector(selectCurrentBuildingId);
  const [instance, setInstance] = React.useState<RumGlobal | null>(null);

  React.useEffect(() => {
    if (!DATADOG_APPLICATION_ID || !DATADOG_CLIENT_TOKEN) return;

    if (!instance) {
      const newInstance = datadogRum;
      newInstance.init({
        allowedTracingUrls: [
          {
            match: /stord\.com/i,
            propagatorTypes: ['tracecontext'],
          },
        ],
        applicationId: DATADOG_APPLICATION_ID,
        beforeSend: (event, context) => {
          if (event.type === 'error') {
            return IGNORED_ERROR_MESSAGES.some((message) => {
              return event.error.message.includes(message);
            });
          }
          return true;
        },
        clientToken: DATADOG_CLIENT_TOKEN,
        defaultPrivacyLevel: 'allow',
        enableExperimentalFeatures: ['feature_flags'],
        site: 'datadoghq.com',
        service: DATADOG_SERVICE_NAME,
        env: DATADOG_ENV,
        sessionReplaySampleRate: 100,
        sessionSampleRate: 100,
        trackLongTasks: true,
        trackResources: true,
        trackSessionAcrossSubdomains: true,
        trackUserInteractions: true,
        version,
      });
      newInstance?.startSessionReplayRecording();
      setInstance(newInstance);
    }
  }, [
    DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE_NAME,
    DATADOG_ENV,
    instance,
    version,
  ]);

  React.useEffect(() => {
    if (!instance || !user || !buildingId) return;
    instance.setUser({
      email: user.email,
      language: user['https://wms.stord.com']?.language ?? 'en',
      name: user.name,
      role: user['https://wms.stord.com'].role,
      buildingId,
    });
  }, [instance, buildingId, user]);

  return (
    <DatadogProviderContext.Provider value={{ instance }}>
      {children}
    </DatadogProviderContext.Provider>
  );
};

export function useDatadogRUM(): RumGlobal | null {
  const context = React.useContext(DatadogProviderContext);
  if (context === undefined) {
    throw new Error('useDatadogRUM must be used within a DatadogProvider');
  }
  return context.instance;
}
