import { createSlice } from '@reduxjs/toolkit';
import type { UnknownAction } from '@reduxjs/toolkit';
import type { RootState } from '@store';
import { api } from '@store/services/api';
import { setAuthData, setBuildingId } from './actions';
import { updateBuildingIdInLocalStorage } from '@utils';
import type { Building } from '@store/services/api.generated';

export interface AuthClaimAction extends UnknownAction {
  payload: {
    __raw: string;
    'https://wms.stord.com': {
      building_id: string;
      role: string;
    };
  };
}

/**
 * We need to setAuthClaims on initial login using an api matcher, and we need to also have an action to be used when
 * our baseQuery results in 401 when we attempt to refetch claims. This is a local util to consolidate the claims payload
 * being put in state
 * @param state - auth state
 * @param payload - an IdToken from claims
 */
const setAuthClaims = (
  state: { token: string; building_id: string; role: string },
  { payload }: AuthClaimAction
) => {
  state.token = payload?.__raw ?? '';
  state.building_id = payload?.['https://wms.stord.com']?.building_id;
  state.role = payload?.['https://wms.stord.com']?.role;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
    building_id: '',
    role: '',
    building_tz: '',
    building_name: '',
    building: {} as Building,
  },
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(setAuthData, setAuthClaims)
      .addCase(setBuildingId, (state, { payload }) => {
        state.building_id = payload.id!;
        updateBuildingIdInLocalStorage(payload.id!);
      })
      .addMatcher(
        api.endpoints['getBuildingsByBuildingId'].matchFulfilled,
        (state, { payload }) => {
          state.building_tz = payload?.data?.timezone ?? '';
          state.building_name = payload?.data?.name ?? '';
          state.building_id = payload?.data?.id ?? '';
          state.building = payload?.data!;
        }
      )
      .addMatcher(api.endpoints['postBuildings'].matchFulfilled, (state, { payload }) => {
        if (payload?.data?.id) {
          state.building_tz = payload?.data?.timezone ?? '';
          state.building_name = payload?.data?.name ?? '';
          state.building_id = payload?.data?.id ?? '';
          state.building = payload?.data!;
        }
      });
  },
});

export default authSlice.reducer;

export const selectCurrentBuildingId = (state: RootState) => state.auth.building_id;

export const selectCurrentBuildingTZ = (state: RootState) => state.auth.building_tz;

export const selectCurrentBuildingName = (state: RootState) => state.auth.building_name;

export const selectCurrentBuilding = (state: RootState) => state.auth.building;
