const BUILDING_ID_LOCAL_STORAGE_KEY = '__wms-building-id';

/**
 * Persists the building id to localstorage. If the id is undefined, null,
 * or an empty string, remove it from localstorage instead.
 *
 * @param buildingId - id to set in local storage as the current building id
 */
export const updateBuildingIdInLocalStorage = (buildingId?: string) => {
  if (buildingId) {
    localStorage.setItem(BUILDING_ID_LOCAL_STORAGE_KEY, buildingId);
  } else {
    localStorage.removeItem(BUILDING_ID_LOCAL_STORAGE_KEY);
  }
};

/**
 * Retrieve the building id from localstorage to hydrate admin users
 * current session with the last building they viewed.
 */
export const getBuildingIdFromLocalStorage = (): string | null => {
  return localStorage.getItem(BUILDING_ID_LOCAL_STORAGE_KEY);
};
