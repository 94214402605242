import type {
  NumberInputProps as ChakraNumberInputProps,
  FormLabelProps as ChakraFormLabelProps,
  FormControlProps,
} from '@chakra-ui/react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { getFieldErrorMessage, RequiredAsterisk } from './utils';

export function RHFNumberInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hideLabel,
  inlineLabel,
  // Controller props
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  labelProps,
  bg,
  isRequired,
  formControlProps = {},
  placeholder,
  ...inputProps
}: UseControllerProps<TFieldValues, TName> &
  ChakraNumberInputProps & {
    label: React.ReactNode | string;
    formControlProps?: FormControlProps;
    hideLabel?: boolean;
    inlineLabel?: boolean;
    labelProps?: ChakraFormLabelProps;
    placeholder?: string;
  }) {
  const { field, formState } = useController({
    defaultValue: 0 as any, // This is to prevent silly jest errors regarding controlled switching to uncontrolled errors
    name,
    rules,
    shouldUnregister,
    control,
  });

  // Note: This approach relies on _not_ validating on blur. If you were to desire that to be the behavior,
  // we'd most likely want to check for `touched` on `isInvalid` as well.
  return (
    <FormControl
      isInvalid={!!(formState.errors as any)[field.name]}
      id={field.name}
      {...formControlProps}
    >
      {(inlineLabel
        ? (children: React.ReactNode) => (
            <Flex direction="row" justify="center">
              {children}
            </Flex>
          )
        : (children: React.ReactNode) => children)(
        <>
          <FormLabel hidden={hideLabel} mt={2} {...(labelProps || {})}>
            {label}
            {isRequired && <RequiredAsterisk />}
          </FormLabel>
          <NumberInput isRequired={isRequired} placeholder={placeholder} {...field} {...inputProps}>
            <NumberInputField bg={bg} />
            <NumberInputStepper>
              <NumberIncrementStepper bg={bg} />
              <NumberDecrementStepper bg={bg} />
            </NumberInputStepper>
          </NumberInput>
        </>
      )}
      <FormErrorMessage>
        <FormErrorMessage>{getFieldErrorMessage(formState, field.name)}</FormErrorMessage>
      </FormErrorMessage>
    </FormControl>
  );
}
