import { Box, Text, useColorModeValue as mode } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

interface InlineXButtonProps extends BoxProps {
  onClick?: () => void;
  testId?: string;
}

export const InlineXButton = ({ onClick, testId, ...restProps }: InlineXButtonProps) => (
  <Box
    data-testid={testId}
    as="button"
    rounded="full"
    w={6}
    minW={6}
    h={6}
    transition="background var(--chakra-transition-duration-normal)"
    _hover={{ bg: mode('gray.200', 'whiteAlpha.300') }}
    onClick={onClick}
    {...restProps}
  >
    <Text mt={-0.5}>&times;</Text>
  </Box>
);
