import type React from 'react';
import type { ReactNode } from 'react';
import { BiMap, BiXCircle } from 'react-icons/bi';
import { FaPallet } from 'react-icons/fa';
import { faBarcodeScan, faBox } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Box,
  Circle,
  Text,
  VStack,
  Tooltip,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import type { AutocompleteOption, SearchEntity } from '@features/scanner/quick-scan';

function getIconForEntity(entity: SearchEntity): ReactNode {
  switch (entity) {
    case 'location':
      return <BiMap size="15px" data-testid="location-icon" />;
    case 'lpn':
      return <FaPallet size="15px" data-testid="lpn-icon" />;
    case 'productBarcode':
      return <FontAwesomeIcon icon={faBarcodeScan} size="sm" data-testid="upc-icon" />;
    case 'shippingLabel':
      return <FontAwesomeIcon icon={faBox} size="sm" data-testid="shipping-label-icon" />;
  }
}

function getTypeLabelForEntity(entity: SearchEntity) {
  switch (entity) {
    case 'location':
      return 'Location';
    case 'lpn':
      return 'LPN';
    case 'productBarcode':
      return 'Item Barcode';
    case 'shippingLabel':
      return 'Shipping Label';
  }
}

export function QuickPickButton({
  selected,
  onClick,
  onXClick,
}: {
  selected: AutocompleteOption;
  onClick: (value: AutocompleteOption) => void;
  onXClick: (value: AutocompleteOption) => void;
}) {
  return (
    <Button
      variant="outline"
      borderRadius="full"
      px={2}
      height="auto"
      w="100%"
      borderWidth={2}
      colorScheme="blue"
      onClick={() => onClick(selected)}
      justifyContent="flex-start"
      data-testid={`quick-pick-button-${selected.value}`}
    >
      <Circle size={8} bg={mode('blue.500', 'darkmode.blue.300')}>
        <Box as="span" color="white" fontWeight="bold">
          {getIconForEntity(selected.type)}
        </Box>
      </Circle>
      <VStack px={3} mr="auto" my={1} alignItems="flex-start" spacing={1}>
        <Text textAlign="left" fontSize="xs" variant="lighter">
          {getTypeLabelForEntity(selected.type)}
        </Text>
        <Text
          maxWidth={{ base: 40, md: 28, xl: 36 }}
          textOverflow="ellipsis"
          overflow="hidden"
          textAlign="left"
        >
          {selected.label}
        </Text>
      </VStack>
      <Tooltip hasArrow label="Remove saved scan from list" shouldWrapChildren closeOnClick>
        <Box
          as={BiXCircle}
          size="22px"
          color={mode('blue.500', 'darkmode.blue.300')}
          onClick={(e: React.MouseEvent) => {
            onXClick(selected);
            e.stopPropagation();
          }}
          data-testid={`quick-pick-x-${selected.value}`}
        />
      </Tooltip>
    </Button>
  );
}
