import client from '@auth0/auth0-spa-js';
import type { Auth0Client, Auth0ClientOptions, IdToken } from '@auth0/auth0-spa-js';
import { setAuthData } from '@features/auth/actions';
import type { AppDispatch } from '@store';

let clientInstance: Auth0Client | undefined;
export const getAuth0Client = async (options: Auth0ClientOptions) => {
  if (!clientInstance) {
    clientInstance = await client(options);
  }
  return clientInstance;
};

// Being that this outside of the scope of the Auth0Provider, we leverage
// the underlying auth0-spa-js library to set the token on requests to the WMS API
export const getAndDispatchClaims = async (
  dispatch: AppDispatch,
  options: Auth0ClientOptions
): Promise<IdToken | undefined> => {
  let claims;

  try {
    const client = await getAuth0Client(options);
    claims = await client.getIdTokenClaims();
    // eslint-disable-next-line no-empty
  } catch (err) {}

  if (claims) {
    dispatch(setAuthData(claims));
  }
  return claims;
};
