import * as yup from 'yup';

const base = yup.object({
  price: yup.string(),
  currency_code: yup.string(),
  service_method: yup.string(),
  tracking_number: yup.string(),
});

const create = base.shape({
  price: yup
    .number()
    .positive('Must be a number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  currency_code: yup.string(),
  service_method: yup.string().required('Service Method is required'),
  tracking_number: yup.string().required('Tracking Number is required'),
  carrier_name: yup.string().required('Carrier is required'),
});

export { create as createManualParcelSchema, base as updateManualParcelSchema };
