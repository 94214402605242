/* eslint-disable no-console */
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useKeyenceScanner } from './useKeyenceScanner';
import type { LEDColor } from './useKeyenceScanner';

/**
 * Establishes a callback that returns a scan result.
 *
 * Please note that we can only register one callback at a time, so if
 * you're using this in multiple places, their component hierarchy matters.
 *
 * @param callbackFunction An optional function that will be called with the scan result
 * @example
 * const testFn: KeyenceScannerCallbackFunction = (result) => {
 *    // Do something with the result
 *    alert(JSON.stringify(result));
 *  };
 *  useKeyenceScannerCallback(testFn);
 *
 * @example
 * import { useMemo, useEffect } from 'react';
 * import { useNavigate } from 'react-router-dom';
 * import { useKeyenceScanner, useKeyenceScannerCallback } from '@scanner/hooks';
 *
 * export const ExampleLocationsContainerWithCallback = () => {
 *   const { result } = useKeyenceScannerCallback();
 *   const { successFeedback } = useKeyenceScanner();
 *   const { push } = useHistory();
 *
 *   // Locations data fixture, should be replaced by a query
 *   const locationsData = useMemo(() => [{ id: '0071142446667' }], []);
 *
 *   useEffect(() => {
 *     const scanValue = result?.mStringData;
 *     if (result?.mDecodeResult === 'SUCCESS' && scanValue) {
 *       if (locationsData.find((entry) => entry.id === scanValue)) {
 *         successFeedback();
 *         // Navigate to the next step if we have a known value in a list
 *         push(`/settings/locations/${scanValue}`);
 *       }
 *     }
 *   }, [locationsData, result, push]);
 *
 *   return (
 *     <div>
 *       <h3>Scan a location to do some action</h3>
 *       <ul>
 *         {locationsData.map((location) => (
 *           <li>
 *             <button onClick={() => push(`/settings/locations/${location.id}`)}>
 *               Loc: {location.id}
 *             </button>
 *           </li>
 *         ))}
 *       </ul>
 *     </div>
 *   );
 * };
 */

export const UNIQUE_SCANNER_CALLBACK_FN_NAME = 'TOTALLY_SUPER_BANANAS_ON_SCANNER_CB';

const defaultFeedbackConfig: UserFeedbackConfig = {
  success: {
    sound: 'BUZZER',
    soundTone: 16,
    vibrator: true,
    led: 'GREEN',
    onPeriod: 100,
    offPeriod: 0,
    repeatCount: 1,
  },
  error: {
    sound: 'DISABLE',
    soundTone: 16,
    vibrator: false,
    led: 'DISABLE',
    onPeriod: 100,
    offPeriod: 0,
    repeatCount: 1,
  },
};

const callbackBackFunctions: Record<string, Function> = {};

export function useKeyenceScannerCallback({
  callbackFunction,
  callbackKey,
  ignoreDefaultFeedback = true,
}: {
  callbackFunction?: KeyenceScannerCallbackFunction;
  ignoreDefaultFeedback?: boolean;
  callbackKey?: string;
} = {}) {
  const { canUseKJS } = useKeyenceScanner();
  const [result, setResult] = useState<ScanResult | undefined>();

  useEffect(() => {
    if (!canUseKJS || ignoreDefaultFeedback) return;

    const disabledFeedback: UserFeedbackOptions = {
      sound: 'DISABLE',
      soundTone: 1,
      vibrator: false,
      led: 'DISABLE',
      onPeriod: 0,
      offPeriod: 0,
      repeatCount: 0,
    };

    const existingConfig = window._scanManager.getConfig(configNameToIdMap['UserFeedback']) as any;

    existingConfig.success = disabledFeedback;
    existingConfig.error = disabledFeedback;

    window._scanManager.setConfig(configNameToIdMap['UserFeedback'], existingConfig);

    return () => {
      // Restore defaults when cleaning up
      window._scanManager.setConfig(configNameToIdMap['UserFeedback'], defaultFeedbackConfig);
    };
  }, [canUseKJS, ignoreDefaultFeedback]);

  useLayoutEffect(() => {
    if (callbackFunction && callbackKey) {
      callbackBackFunctions[callbackKey] = callbackFunction;
    }

    return () => {
      if (callbackKey && callbackBackFunctions[callbackKey])
        delete callbackBackFunctions[callbackKey];
    };
  }, [callbackFunction, callbackKey]);

  useLayoutEffect(() => {
    if (!canUseKJS) return;

    (window as any)[UNIQUE_SCANNER_CALLBACK_FN_NAME] = (result: ScanResult) => {
      // Note: this is for less noisy tests. Feel free to move it out of the block for debugging.
      if (process.env.NODE_ENV !== 'test') {
        console.info('useKeyenceScannerCallback: scan result', `'${result.mStringData}'`);
      }

      Object.values(callbackBackFunctions).forEach((fn) => {
        fn(result);
      });

      setResult(result);
    };

    const didSetCallback = window._scanManager.setReadCallback(UNIQUE_SCANNER_CALLBACK_FN_NAME);

    if (!didSetCallback) {
      console.error('Failed to set useKeyenceScannerCallback provided callback function');
    }

    return () => {
      window._scanManager.clearReadCallback();
    };
  }, [callbackFunction, canUseKJS]);

  return useMemo(
    () => ({
      result,
    }),
    [result]
  );
}

export type KeyenceScannerCallbackFunction = (result: ScanResult) => void;

export type ScanResult = {
  mDecodeResult:
    | 'SUCCESS'
    | 'ALERT'
    | 'OCR_MULTI_DATES' // Multiple date candidates (OCR only)
    | 'OCR_MULTI_DATES_ALERT' // Multiple date candidates + alert (OCR only)
    | 'TIMEOUT'
    | 'CANCELED' // Read cancel
    | 'FAILED'; // Read error
  mCodeType:
    | 'UPC/EAN/JAN'
    | 'Code128'
    | 'Code39'
    | 'ITF'
    | 'DataMatrix'
    | 'QRCode'
    | 'PDF'
    | 'TOF'
    | 'Codabar(NW7)'
    | 'COOP'
    | 'C93'
    | 'COMPOSITE'
    | 'POSTAL'
    | 'OCR';
  /**
   * Byte string of the read data. It is output as a character string containing
   * each byte separated by a comma.
   */
  mRawData: number[];
  /**
   * Character string of the read data
   */
  mStringData: string;
};

const configNameToIdMap = {
  ScanParams: 0,
  CodeType: 1,
  DataFormat: 2,
  DataOutput: 3,
  UserFeedback: 4,
  UPCEAN: 100,
  JAN: 100,
  C128: 101,
  C39: 102,
  ITF: 103,
  GS1: 104,
  DM: 105,
  QR: 106,
  PDF: 107,
  TOF: 108,
  CODABAR: 109,
  NW7: 109,
  COOP: 110,
  C93: 111,
  COMPOSITE: 112,
  POSTAL: 113,
  OCR: 114,
};

export type ConfigNames = keyof typeof configNameToIdMap;
export type TriggerModes = 'NORMAL' | 'CONTINUOUS' | 'TRIGGER_AT_RELEASE';
export type StorageType = 0 | 1;

type ToneLevel = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;

type RepeatCount = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

type UserFeedbackOptions = {
  sound: 'BUZZER' | 'DISABLE';
  soundTone: ToneLevel;
  vibrator: boolean;
  led: 'DISABLE' | Uppercase<LEDColor>;
  onPeriod: number; // 0-5000
  offPeriod: number; // 0-5000
  repeatCount: RepeatCount;
};

type UserFeedbackConfig = {
  success: UserFeedbackOptions;
  error: UserFeedbackOptions;
};
