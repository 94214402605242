import {
  Button,
  HStack,
  IconButton,
  Link,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';
import type React from 'react';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/pro-regular-svg-icons';

interface FileUploadProps extends Omit<ButtonProps, 'onChange' | 'onSelect'> {
  accepts?: string;
  isDisabled?: boolean;
  label?: string;
  name?: string;
  onReset: () => void;
  onFileNameClick?: () => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFileName?: string;
}

export const FileUploadButton = ({
  accepts,
  isDisabled = false,
  label = 'Select File',
  onFileNameClick,
  onReset,
  onSelect,
  selectedFileName,
  ...buttonProps
}: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputKey, setInputKey] = useState<number>(Date.now());
  const buttonColor = mode('gray.800', 'darkmode.gray.100');

  const onResetClick = () => {
    // update key to reset input element
    // when file is cleared
    setInputKey(Date.now());
    onReset();
  };

  if (selectedFileName) {
    return (
      <HStack>
        {onFileNameClick && !isDisabled ? (
          <Link data-testid="uploaded-filename" onClick={onFileNameClick}>
            {selectedFileName}
          </Link>
        ) : (
          <Text data-testid="uploaded-filename">{selectedFileName}</Text>
        )}

        <IconButton
          isDisabled={isDisabled}
          color={buttonColor}
          onClick={onResetClick}
          data-testid="delete-icon"
          aria-label="Delete file"
          variant="ghost"
          colorScheme="blue"
          icon={<FontAwesomeIcon icon={faTrash} />}
        />
      </HStack>
    );
  }

  return (
    <>
      <Button
        onClick={() => inputRef.current?.click()}
        variant="outline"
        colorScheme="gray"
        isDisabled={isDisabled}
        {...buttonProps}
      >
        <HStack>
          <FontAwesomeIcon icon={faUpload} size="sm" />
          <Text as="span">{label}</Text>
        </HStack>
      </Button>
      <input
        data-testid="file-input"
        type="file"
        name="file"
        ref={inputRef}
        key={inputKey}
        accept={accepts}
        style={{ display: 'none' }}
        onChange={onSelect}
      />
    </>
  );
};
