import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { WmsModal, WmsModalFooter } from '@components';
import { RHFSelect, RHFTextarea } from '@components/forms';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface CancelOrderModalProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
}

const CANCELLATION_OPTIONS = [
  { label: 'Duplicate Order', value: 'duplicate_order' },
  { label: 'System Issue', value: 'system_issue' },
  { label: 'Inventory Discrepancy', value: 'inventory_discrepancy' },
  { label: 'Other', value: 'other' },
];

const cancellationSchema = yup.object({
  cancelation_reason_select: yup.string().required('Cancelation requires a reason'),
  cancelation_reason: yup.string().required(),
});
type FormData = yup.InferType<typeof cancellationSchema>;

export const CancelOrderModal = ({
  isLoading,
  isOpen,
  onClose,
  onConfirm,
}: CancelOrderModalProps) => {
  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(cancellationSchema),
  });

  const selectValue = watch('cancelation_reason_select');
  const textareaValue = watch('cancelation_reason');

  const placeholderSelectColor = mode('gray.700', 'whiteAlpha.600');
  const normalSelectColor = mode('gray.800', 'whiteAlpha.800');

  useEffect(() => {
    if (selectValue !== 'other') {
      setValue('cancelation_reason', selectValue);
    } else {
      setValue('cancelation_reason', '');
    }
  }, [selectValue, setValue]);

  const onCancellationSubmit = handleSubmit(() => {
    onConfirm(selectValue === 'other' ? textareaValue : selectValue);
  });

  return (
    <WmsModal isOpen={isOpen} onClose={onClose} title="Cancel Order" size="lg">
      <Alert status="warning" mb={4}>
        <AlertIcon>
          <FontAwesomeIcon icon={faTriangleExclamation} />
        </AlertIcon>
        <AlertDescription>Order cancelation is final and cannot be undone</AlertDescription>
      </Alert>
      <form onSubmit={onCancellationSubmit}>
        <RHFSelect
          label=""
          name="cancelation_reason_select"
          placeholder="Select a reason for canceling order"
          options={CANCELLATION_OPTIONS}
          control={control}
          color={selectValue ? normalSelectColor : placeholderSelectColor}
          iconColor={mode('gray.800', 'whiteAlpha.800')}
        />
        {selectValue === 'other' && (
          <RHFTextarea
            name="cancelation_reason"
            label=""
            control={control}
            characterCounterLimit={250}
            data-testid="other-reason-textarea"
          />
        )}
        <WmsModalFooter>
          <Button variant="outline" colorScheme="gray" size="sm" onClick={onClose}>
            Dismiss
          </Button>
          <Button
            type="submit"
            variant="danger"
            size="sm"
            isDisabled={!textareaValue || textareaValue === ''}
            isLoading={isLoading}
          >
            Cancel Order
          </Button>
        </WmsModalFooter>
      </form>
    </WmsModal>
  );
};
