import * as yup from 'yup';

export const slipStreamSchema = yup.object({
  packaging_id: yup.string().nullable(),
  product_id: yup.string().required('Product is required'),
  product_quantity: yup
    .number()
    .integer('Must be whole number')
    .positive('Must be greater than 0')
    .typeError('Order line quantity is required')
    .required('Order line quantity is required'),
  threshold: yup
    .number()
    .integer('Must be whole number')
    .positive('Must be greater than 0')
    .max(200)
    .typeError('Threshold quantity is required')
    .required('Threshold quantity is required'),
  tags: yup.string().nullable(),
});
