import { Auth0Provider as Provider } from '@auth0/auth0-react';
import type { Auth0ProviderOptions } from '@auth0/auth0-react';
import { useConfig, useScannerUserAgent } from '@hooks';
import { useGetConfigQuery } from '@store/services/api';
import type { EnvConfigKeys } from '@store/services/api';
import { useNavigate } from 'react-router-dom';

const requiredAuth0ConfigKeys: EnvConfigKeys[] = ['AUTH0_DOMAIN', 'AUTH0_CLIENT_ID'];

export const Auth0Provider = (
  props: Omit<Auth0ProviderOptions, 'domain' | 'clientId'> & {
    domain?: Auth0ProviderOptions['domain'];
    clientId?: Auth0ProviderOptions['clientId'];
  }
) => {
  const config = useConfig();
  const navigate = useNavigate();
  const { refetch } = useGetConfigQuery();
  const { isScanner } = useScannerUserAgent();

  const onRedirectCallback = (appState: any) => {
    // if the user is trying to navigate to a specific page,
    // route them to back to it after logging in,
    // otherwise, route them to the outbounds page
    if (appState?.returnTo !== '/') {
      navigate(appState?.returnTo, { replace: true });
      // We refetch the configuration in case it's being overwritten
      // via query parameters.
      refetch();
    } else {
      isScanner
        ? navigate('/scanner', { replace: true })
        : navigate('/overview', { replace: true });
    }
  };

  requiredAuth0ConfigKeys.forEach((key) => {
    if (!config[key]) {
      console.warn('Missing Auth0 setting for key', key);
    }
  });

  return (
    <Provider
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      {...props}
    >
      {props.children}
    </Provider>
  );
};
