import type { Item, Product, ProductBarcode } from '@store/services/api.generated';
import type { AutocompleteOption } from '../features/scanner/quick-scan/QuickScan';

export const isScanForProduct = (barcode?: string, product?: Product) => {
  if (!barcode || !product) return false;
  return (product.barcodes ?? []).some((b: ProductBarcode) => barcode === b.barcode_value);
};

export const getUpcForProduct = (product?: Product) => {
  if (!product) return undefined;
  return product.barcodes?.find((b) => b.name?.toLowerCase() === 'upc')?.barcode_value;
};

export const getVpnForProduct = (product?: Product) => {
  if (!product) return undefined;
  return product.barcodes?.find((b) => b.name?.toLowerCase() === 'vpn')?.barcode_value;
};

export const getItemBarcodeValues = (items?: Item[]) => {
  return (items ?? []).flatMap((item) =>
    (item?.product?.barcodes ?? []).map((barcode) => barcode.barcode_value)
  );
};

export const getAutocompleteOptionsForProducts = (
  products: Product[],
  recentEntities: AutocompleteOption[],
  favoriteEntities: AutocompleteOption[]
) => {
  const productBarcodeOptions: AutocompleteOption[] =
    products.flatMap((product) =>
      (product.barcodes ?? []).map((barcode) => ({
        label: `${barcode.barcode_value} (${product.sku})`,
        value: barcode.barcode_value!,
        isRecent: recentEntities.some(({ value }) => value === barcode.barcode_value),
        isFavorite: favoriteEntities.some(({ value }) => value === barcode.barcode_value),
        type: 'productBarcode',
      }))
    ) ?? [];

  return productBarcodeOptions;
};
