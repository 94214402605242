import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import type { AsyncProviderConfig } from 'launchdarkly-react-client-sdk';
import type { LDClient, LDEvaluationDetail } from 'launchdarkly-js-client-sdk';
import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { useAuth, useConfig } from '@hooks';
import { Loading, useDatadogRUM } from '@components';
import { useAppSelector } from '@store/hooks';
import { selectCurrentBuildingId } from '@features/auth/authSlice';
import type { User as Auth0User } from '@auth0/auth0-spa-js';

interface CommonProps {
  fallback?: ReactNode;
  children: ReactNode;
}

export interface FeatureFlagProviderProps extends CommonProps {}

export const identifyUserLD = (
  ldClient: LDClient,
  userDetails: Auth0User,
  additionalAttrs = {}
): void => {
  ldClient
    ?.identify({
      kind: 'user',
      key: userDetails.sub,
      ...userDetails,
      custom: {
        ...additionalAttrs,
      },
    })
    .catch(console.error);
};

export const LDProvider = ({ children }: { children: ReactNode }) => {
  const [LDProvider, setLDProvider] = useState<any>(() => null);
  const [ldConfig, setLDConfig] = useState<AsyncProviderConfig | null>(null);
  const { LAUNCHDARKLY_CLIENT_ID } = useConfig();
  const datadogRum = useDatadogRUM();

  useEffect(() => {
    if (LAUNCHDARKLY_CLIENT_ID) {
      setLDConfig({
        clientSideID: LAUNCHDARKLY_CLIENT_ID,
        options: {
          inspectors: [
            {
              type: 'flag-used',
              name: 'dd-inspector',
              method: (key: string, detail: LDEvaluationDetail) => {
                if (datadogRum != null) {
                  (datadogRum as any).addFeatureFlagEvaluation(key, detail.value);
                }
              },
            },
          ],
          sendEvents: !(window as any).Cypress,
          streaming: !(window as any).Cypress,
        },
        context: {
          kind: 'user',
          key: 'not_authenticated',
        },
      });
    }
  }, [datadogRum, LAUNCHDARKLY_CLIENT_ID]);

  useEffect(() => {
    if (ldConfig && !LDProvider) {
      asyncWithLDProvider(ldConfig)
        .then((provider) => {
          setLDProvider(() => provider);
        })
        .catch(console.error);
    }
  }, [ldConfig, LDProvider]);

  return LDProvider ? <LDProvider>{children}</LDProvider> : <>{children}</>;
};

const LDInitializer = ({ fallback = <Loading isLoaded={false} />, children }: CommonProps) => {
  const [initialized, setInitialized] = useState(false);
  const { user } = useAuth();
  const ldClient = useLDClient();
  const buildingId = useAppSelector(selectCurrentBuildingId);

  useEffect(() => {
    if (!user || !buildingId || !ldClient) return;
    identifyUserLD(ldClient, user, { buildingId });
  }, [ldClient, buildingId, user]);

  useEffect(() => {
    if (!ldClient) return;
    ldClient
      .waitForInitialization()
      .then(() => setInitialized(true))
      .catch(console.error);
  }, [ldClient]);

  return initialized ? <>{children}</> : <>{fallback}</>;
};

export const FeatureFlagProvider = ({ fallback, children }: FeatureFlagProviderProps) => (
  <LDProvider>
    <LDInitializer {...{ fallback }}>{children}</LDInitializer>
  </LDProvider>
);

export default FeatureFlagProvider;
