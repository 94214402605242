import * as yup from 'yup';
import type { Product } from '@store/services/api.generated';
import { dateValidationSchema } from '@schemas/dateValidationSchema';

export const buildGUIDSchema = (message: string = 'Please scan a barcode') =>
  yup.string().uuid(message).required();

export const greaterThanZeroNumberSchema = yup
  .number()
  .positive('Must be greater than 0')
  .required()
  .typeError('Must be greater than 0');

export const zeroOrGreaterNumberSchema = yup
  .number()
  .min(0, 'Must be 0 or greater')
  .required()
  .typeError('Must be 0 or greater');

export const getInboundOrderByOrderNumberSchema = yup.object({
  orderNumber: yup.string().required(),
  shipperName: yup.string().required(),
});

export const pickLPNSchema = yup.object({
  id: yup.string().uuid('Rescan LPN to continue').required(),
  quantity: yup.number(),
});
export type pickLPNFormData = yup.InferType<typeof pickLPNSchema>;

export const pickLPNQuantitySchema = yup.object({
  new_lpn: yup.string().required(),
  quantity: greaterThanZeroNumberSchema,
});
export type pickLPNQuantityFormData = yup.InferType<typeof pickLPNQuantitySchema>;

export const getReceiveLpnFormSchema = (product?: Product) => {
  return yup.object({
    lpn: yup.string().required('LPN required'),
    quantity: greaterThanZeroNumberSchema,
    lot_number: product?.lot_tracked
      ? yup
          .string()
          .ensure()
          .trim()
          .required('Lot # required')
          .test({
            name: 'matches-expected',
            test: (value, ctx) => {
              const expectedLotNumber = ctx?.options?.context?.expectedLotNumber;
              if (!expectedLotNumber || expectedLotNumber === value) return true;
              return ctx.createError({
                message: 'The entered lot number does not match the expected value',
              });
            },
          })
      : yup.string(),
    expiration_date: product?.expiration_date_tracked
      ? dateValidationSchema({
          label: 'Exp. date',
          key: 'isExpirationDateTracked',
        })
          .required('Expiration date is required')
          .test({
            name: 'matches-expected',
            test: (value, ctx) => {
              const expectedExpirationDate = ctx?.options?.context?.expectedExpirationDate;
              if (!expectedExpirationDate || expectedExpirationDate === value) return true;
              return ctx.createError({
                message: 'Please ensure the expiration date matches the expected value.',
              });
            },
          })
      : yup.string(),
    manufacturer_date: dateValidationSchema({
      label: 'Mfr. date',
      key: 'isManufacturerDateTracked',
    }),
  });
};

export const saveNotesSchema = yup.object().shape({
  notes: yup.string(),
});

export const printBarcodesSchema = yup.object({
  count: yup.number().when('newOrExisting', {
    is: 'new',
    then: yup
      .number()
      .required('Must specify number of barcodes to print')
      .typeError('Must specify number of barcodes to print')
      .min(1, 'Must print at least 1 barcode')
      .max(200, 'Cannot print more than 200 barcodes at once'),
    otherwise: yup.number(),
  }),
  barcodeType: yup.string(),
  barcode: yup.string().when('newOrExisting', {
    is: 'existing',
    then: yup.string().required().max(30, 'Must be 30 characters or less'),
    otherwise: yup.string(),
  }),
  prefix: yup.string().when('newOrExisting', {
    is: 'new',
    then: yup.string().max(5, 'Must be 5 characters or less'),
    otherwise: yup.string(),
  }),
  printerId: yup.string().required(),
  newOrExisting: yup.string().required(),
});

export const cycleCountResultsSchema = yup.object({
  quantity: zeroOrGreaterNumberSchema,
  puomQuantity: yup.number().required(),
});
