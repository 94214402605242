import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import loadable from '@loadable/component';

import { RequireAuth, RequireBuilding } from '@components';
import { selectCurrentBuildingId } from '@features/auth/authSlice';
import { useAppSelector } from '@store/hooks';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '@hooks';

const AuthError = loadable(() => import('@features/auth/AuthError'));
const Login = loadable(() => import('@features/auth/Login'));
const ScannerLayout = loadable(() => import('./layouts/ScannerLayout'));
const MainLayout = loadable(() => import('./layouts/MainLayout'));
const MePasswordReset = loadable(() => import('@features/me/ResetPassword'));

function App() {
  const { user } = useAuth();
  const buildingId = useAppSelector(selectCurrentBuildingId);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    if (!searchParams.has('building_id') && buildingId) {
      setSearchParams({ building_id: buildingId }, { replace: true, state: location.state });
    }
  }, [buildingId, searchParams, setSearchParams, location]);

  if (
    user?.['https://wms.stord.com']?.must_change_password &&
    window?.location?.pathname !== '/me/reset-password'
  ) {
    return <Navigate to="/me/reset-password" replace />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/auth_error" element={<AuthError />} />

      <Route
        path="/me/reset-password"
        element={
          <RequireAuth>
            <MePasswordReset />
          </RequireAuth>
        }
      />

      <Route
        path="/*"
        element={
          <RequireAuth>
            <RequireBuilding>
              <MainLayout />
            </RequireBuilding>
          </RequireAuth>
        }
      />
      <Route
        path="/scanner/*"
        element={
          <RequireAuth>
            <RequireBuilding>
              <ScannerLayout />
            </RequireBuilding>
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default App;
