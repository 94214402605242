import { Button, Flex, Spacer } from '@chakra-ui/react';
import type { ReactNode } from 'react';

export const FormButtons = ({
  allowBack,
  allowSkip,
  backLabel = 'Back',
  isLoading,
  isSubmitDisabled,
  submitLabel = 'Next',
  onBackClick,
  onSkipClick,
  skipLabel = 'Skip',
}: {
  allowBack?: boolean;
  allowSkip?: boolean;
  backLabel?: ReactNode;
  isLoading?: boolean;
  isSubmitDisabled?: boolean;
  submitLabel?: ReactNode;
  onBackClick?: () => void;
  onSkipClick?: () => void;
  skipLabel?: ReactNode;
}) => {
  return (
    <Flex paddingY={4}>
      <Spacer />
      {allowBack && (
        <Button variant="ghost" colorScheme="blue" mr={4} onClick={onBackClick}>
          {backLabel}
        </Button>
      )}
      {allowSkip && (
        <Button variant="ghost" colorScheme="blue" mr={4} onClick={() => onSkipClick?.()}>
          {skipLabel}
        </Button>
      )}
      <Button type="submit" variant="primary" isDisabled={isSubmitDisabled} isLoading={isLoading}>
        {submitLabel}
      </Button>
    </Flex>
  );
};

export default FormButtons;
