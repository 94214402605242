import type { LinkProps } from '@chakra-ui/react';
import { Link, Text } from '@chakra-ui/react';
import { getBuildingIdFromLocalStorage } from '@utils';
import type React from 'react';
import { useMemo } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

export interface LinkWithBuildingIdProps extends LinkProps, Pick<NavLinkProps, 'state'> {
  children: React.ReactNode;
  to?: string;
}

export const LinkWithBuildingId = ({ children, to, w, ...props }: LinkWithBuildingIdProps) => {
  const buildingId = getBuildingIdFromLocalStorage();

  const url = useMemo(() => {
    if (!to) return null;
    if (!buildingId) return to;

    const base = window.location.origin;
    const originalUrl = new URL(to, base);
    originalUrl.searchParams.append('building_id', buildingId);

    return originalUrl.toString().replace(base, '');
  }, [buildingId, to]);

  return url ? (
    <Link as={NavLink} w={w} {...props} to={url ?? ''} className="_no-translate">
      {children}
    </Link>
  ) : (
    <Text w={w}>{children}</Text>
  );
};

export default LinkWithBuildingId;
