import type { SelectProps } from '@chakra-ui/react';
import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import type { Options, OptionValue } from '@type-utils';
import { useController } from 'react-hook-form';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { getFieldErrorMessage, RequiredAsterisk } from './utils';
import type {
  FormLabelProps as ChakraFormLabelProps,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react';

export const RHFSelect = <
  TOptionValue extends OptionValue = OptionValue,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  color,
  disabled,
  label,
  hideLabel = false,
  iconColor,
  placeholder,
  options,
  'data-testid': testId,
  labelProps = {},
  isRequired = false,
  w,
  inputProps = {},
  ...controllerProps
}: UseControllerProps<TFieldValues, TName> & {
  color?: string;
  disabled?: boolean;
  label: string;
  hideLabel?: boolean;
  iconColor?: string;
  isRequired?: boolean;
  placeholder?: string;
  options: Options<TOptionValue>;
  'data-testid'?: string;
  labelProps?: ChakraFormLabelProps;
  inputProps?: ChakraSelectProps;
  w?: SelectProps['w'];
}) => {
  const { field, formState } = useController({
    defaultValue: '' as any,
    ...controllerProps,
  });

  return (
    <FormControl isInvalid={!!(formState.errors as any)[field.name]} id={field.name}>
      <FormLabel hidden={hideLabel} {...labelProps}>
        {label}
        {isRequired && <RequiredAsterisk />}
      </FormLabel>
      <Select
        placeholder={placeholder}
        data-testid={testId}
        disabled={disabled}
        color={color}
        iconColor={iconColor}
        isRequired={isRequired}
        w={w}
        {...field}
        {...inputProps}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{getFieldErrorMessage(formState, field.name)}</FormErrorMessage>
    </FormControl>
  );
};
