import { Button, Heading, Stack } from '@chakra-ui/react';
import { FileUploadButton } from '@components';
import { useCSVDownload } from '@hooks';
import type { ChangeEvent, ReactNode } from 'react';
import type { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

export const UploadSection = ({
  downloadFilename,
  downloadLabel,
  downloadTrigger,
  isDisabled,
  isDownloading,
  selectedFile,
  setSelectedFile,
  uploadLabel,
}: {
  downloadFilename: string;
  downloadLabel: ReactNode;
  downloadTrigger: MutationTrigger<any>;
  isDisabled?: boolean;
  isDownloading: boolean;
  selectedFile: File | undefined;
  setSelectedFile: (file: File | undefined) => void;
  uploadLabel: ReactNode;
}) => {
  const downloadFile = useCSVDownload({
    downloadFn: downloadTrigger,
    filename: `${downloadFilename}.csv`,
  });

  const handleSelectUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setSelectedFile(files[0]);
    }
  };

  return (
    <>
      <Stack mb={4}>
        <Heading size="xs">{downloadLabel}</Heading>
        <Button
          data-testid={`download-${downloadFilename}-btn`}
          isLoading={isDownloading}
          onClick={() => downloadFile({})}
          variant="outline"
          colorScheme="gray"
          width="fit-content"
        >
          Download File
        </Button>
      </Stack>
      <Stack>
        <Heading size="xs">{uploadLabel}</Heading>
        <FileUploadButton
          accepts=".csv"
          isDisabled={isDisabled}
          onSelect={(e) => handleSelectUploadFile(e)}
          selectedFileName={selectedFile?.name}
          onReset={() => setSelectedFile(undefined)}
        />
      </Stack>
    </>
  );
};

export default UploadSection;
