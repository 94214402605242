import * as yup from 'yup';
import { greaterThanZeroNumberSchema } from '@features/scanner/schemas';

export const createWorkOrderSchema = yup.object({
  category: yup.string().required('Category is required'),
  shipper: yup.string().required('Shipper is required'),
  productId: yup.string().required('Kit SKU is required'),
  quantity: greaterThanZeroNumberSchema,
  notes: yup.string(),
});

export const createReplenishmentWorkOrderSchema = yup.object({
  locationId: yup.string().required('Location is required'),
  productId: yup.string().required('SKU is required'),
  quantity: greaterThanZeroNumberSchema,
  shipperId: yup.string().required('Shipper is required'),
});
