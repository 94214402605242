import type { ReactPortal } from 'react';
import { createContext, useContext } from 'react';
import { noop } from 'lodash';

export type PrintWindowState = {
  onPrint: () => void;
};

export const PrintWindowContext = createContext<PrintWindowState>({
  onPrint: noop,
});

export const PrintWindowProvider = ({
  children,
  ...state
}: PrintWindowState & { children: ReactPortal }) => (
  <PrintWindowContext.Provider value={state}>{children}</PrintWindowContext.Provider>
);

export const usePrintWindow = () => {
  const context = useContext(PrintWindowContext);

  if (!context) {
    throw new Error('usePrintWindow must be used within a PrintWindowContext');
  }

  return context;
};
