import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import { pickSpecificColor } from '@theme/utils';

const variants = {
  outline: defineStyle((props) => {
    const { colorScheme } = props;

    if (props.colorMode === 'dark') {
      return {
        bg: 'whiteAlpha.50',
        borderColor: pickSpecificColor(colorScheme, {
          gray: 800,
          blue: 200,
        }),
        _hover: {
          bg: 'whiteAlpha.200',
        },
      };
    }

    return {
      bg: 'white',
      color: pickSpecificColor(colorScheme, {
        gray: 800,
        blue: 500,
      }),
      borderColor: pickSpecificColor(colorScheme, {
        gray: 300,
        blue: 500,
      }),
      _hover: {
        color: pickSpecificColor(colorScheme, {
          gray: 800,
          blue: 600,
        }),
        borderColor: pickSpecificColor(colorScheme, {
          gray: 300,
          blue: 600,
        }),
        bg: `${colorScheme}.100`,
      },
      _active: {
        color: pickSpecificColor(colorScheme, {
          gray: 800,
          blue: 600,
        }),
        borderColor: pickSpecificColor(colorScheme, {
          gray: 300,
          blue: 600,
        }),
        bg: `${colorScheme}.100`,
      },
    };
  }),

  ghost: defineStyle((props) => {
    const { colorScheme } = props;

    return {
      color: pickSpecificColor(colorScheme, {
        whiteAlpha: 700,
      }),
      _hover: {
        color: colorScheme === 'whiteAlpha' ? 'white' : undefined,
        bg: pickSpecificColor(colorScheme, {
          whiteAlpha: 200,
        }),
      },
      _active: {
        color: colorScheme === 'whiteAlpha' ? 'white' : undefined,
        bg: pickSpecificColor(colorScheme, {
          whiteAlpha: 200,
        }),
      },
    };
  }),

  // NOTE: Variants below haven't been updated to match global theme

  primary: defineStyle((props) => ({
    bg: mode('blue.500', 'darkmode.primary')(props),
    color: mode('white', 'black')(props),
    _hover: {
      _disabled: {
        bg: mode('gray.500', 'whiteAlpha.700')(props),
      },
    },
  })),

  nav: defineStyle((props) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 'sm',
    fontWeight: 'normal',
    height: 'auto',
    p: 2,
    color: mode('gray.900', 'gray.300')(props),
    _activeLink: {
      color: mode('blue.500', 'darkmode.blue.900')(props),
      bg: mode('blue.50', 'darkmode.blue.200')(props),
    },
    _focus: {
      bg: mode('gray.100', 'gray.800')(props),
      outline: 'none',
    },
    _hover: {
      bg: mode('gray.100', 'gray.800')(props),
    },
  })),

  navIcon: defineStyle((props) => ({
    bg: 'transparent',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'md',
    fontWeight: 'normal',
    h: 8,
    minW: 8,
    color: mode('gray.900', 'darkmode.gray.100')(props),
    _focus: {
      bg: mode('gray.100', 'gray.800')(props),
    },
    _hover: {
      bg: mode('gray.100', 'gray.800')(props),
      transform: 'scale(1.25)',
    },
  })),

  collapsedNav: defineStyle((props) => ({
    _activeLink: {
      color: mode('blue.500', 'darkmode.blue.900')(props),
      bg: mode('blue.50', 'darkmode.blue.200')(props),
    },
    _hover: {
      bg: mode('gray.100', 'gray.800')(props),
      transform: 'scale(1.25)',
    },
  })),

  link: defineStyle((props) => ({
    color: mode('blue.500', 'darkmode.blue.300')(props),
  })),

  filter: defineStyle((props) => ({
    bg: mode('white', 'darkmode.gray.800')(props),
    borderWidth: '1px',
    borderColor: mode('gray.200', 'whiteAlpha.300')(props),
    borderRadius: 'base',
    color: mode('gray.700', 'darkmode.gray.100')(props),
    fontSize: 'md',
    fontWeight: 400,
    h: 10,
    size: 'sm',
  })),

  danger: defineStyle((props) => ({
    bg: mode('red.500', 'red.550')(props),
    color: mode('white', 'black')(props),
    _disabled: {
      bg: mode('red.500', 'red.550')(props),
    },
    _hover: {
      _disabled: {
        bg: mode('red.500', 'red.550')(props),
      },
    },
  })),

  uploadDropzone: defineStyle((props) => ({
    bg: 'transparent',
    borderColor: 'gray.800',
    borderRadius: 4,
    borderStyle: 'dashed',
    borderWidth: 1,
    fontWeight: 'normal',
    h: 'auto',
    p: 4,
    w: '100%',
    _hover: {
      bg: mode('gray.300', 'whiteAlpha.300')(props),
    },
  })),
};

export const Button = defineStyleConfig({
  variants,
});
