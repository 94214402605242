import { Box, Button, Text } from '@chakra-ui/react';
import type { TableColumn } from '@components';
import { NoTranslate, TableContent } from '@components';
import { faBarcode } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePaginatedQuery } from '@hooks';
import type { AutocompleteOption } from '@scanner/quick-scan';
import { useGetLicensePlatesQuery } from '@store/services/api';
import type { LicensePlate, Location, Product } from '@store/services/api.generated';
import { useMemo } from 'react';

type LpnInfo = LicensePlate & {
  quantity: number;
};
export const QuickPickHelper = ({
  favoriteEntities,
  location,
  product,
  recentEntities,
  scanAndClose,
}: {
  favoriteEntities: AutocompleteOption[];
  location: Location;
  product: Product;
  recentEntities: AutocompleteOption[];
  scanAndClose: (option: AutocompleteOption) => void;
}) => {
  const {
    data: lpnData,
    isLoading: isLpnLoading,
    isFetching: isLpnFetching,
    isError: isLpnError,
    page,
    onPageChange,
  } = usePaginatedQuery('getLicensePlates', useGetLicensePlatesQuery, {
    locationIds: location?.id!,
    productIds: product?.id!,
    active: 'true',
  });

  const columns: TableColumn<LpnInfo>[] = [
    {
      Header: 'LPN',
      id: 'lpn',
      accessor: 'number',
      disableSortBy: true,
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      id: 'quantity',
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: '',
      id: 'select',
      disableSortBy: true,
      Cell: (lpn: LpnInfo) => {
        return (
          <Button
            variant="outline"
            colorScheme="gray"
            leftIcon={<FontAwesomeIcon icon={faBarcode} />}
            onClick={() => {
              scanAndClose({
                label: lpn.number!,
                value: lpn.number!,
                isRecent: recentEntities.some(({ value }) => value === lpn.number),
                isFavorite: favoriteEntities.some(({ value }) => value === lpn.number),
                type: 'lpn',
              });
            }}
          >
            Scan LPN
          </Button>
        );
      },
    },
  ];

  const tableData: LpnInfo[] = useMemo(() => {
    return (lpnData?.data ?? []).map((lpn) => {
      const pickableQuantity = (lpn?.items ?? []).reduce((acc, item) => {
        if (item?.product?.id === product?.id) {
          return acc + (item?.quantity ?? 0);
        }

        return acc;
      }, 0);
      return {
        ...lpn,
        quantity: pickableQuantity,
      };
    });
  }, [lpnData, product]);

  return (
    <Box p={2}>
      <TableContent
        columns={columns}
        data={tableData}
        emptyTableElement={
          <Text variant="lighter" textAlign="center">
            <NoTranslate>{product?.sku}</NoTranslate> has no inventory at{' '}
            <NoTranslate>{location?.name}</NoTranslate>
          </Text>
        }
        isError={isLpnError}
        isLoading={isLpnLoading || isLpnFetching}
        pageInfo={lpnData?.meta ?? page}
        onPageChange={onPageChange}
        title={
          <Text>
            Pickable LPNs for <NoTranslate>{product.sku}</NoTranslate> at{' '}
            <NoTranslate>{location.name}</NoTranslate>
          </Text>
        }
      />
    </Box>
  );
};
