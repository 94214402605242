import { mode } from '@chakra-ui/theme-tools';
import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const ScannerFlowContent: ComponentStyleConfig = {
  parts: [
    'instructionBanner',
    'instructionBannerText',
    'containerCard',
    'scannerHeader',
    'scannerHeaderText',
    'secondaryBanner',
    'secondaryBannerText',
    'skuDetails',
    'instructionText',
    'instructionNumber',
  ],
  baseStyle: (props) => ({
    instructionBanner: {},
    instructionBannerText: {
      fontSize: '3xl',
      fontWeight: 'bold',
      lineHeight: '40px',
    },
    containerCard: {
      borderRadius: 4,
      borderWidth: '1px',
      boxShadow: 'lg',
    },
    scannerHeader: {},
    scannerHeaderText: {
      fontSize: 'xs',
      fontWeight: 'bold',
      lineHeight: 5,
      px: 4,
      textTransform: 'uppercase',
    },
    secondaryBannerText: {
      fontWeight: 'semibold',
    },
    skuDetails: {
      fontSize: 'sm',
      color: mode('gray.700', 'darkmode.gray.300')(props),
    },
    instructionText: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    instructionNumber: {
      fontSize: '7xl',
      fontWeight: 'bold',
    },
    resultIcon: {
      color: mode('white', 'gray.900')(props),
      ml: 8,
    },
  }),
  variants: {
    exceptionPrimary: (props) => ({
      instructionBanner: {
        bgColor: mode('red.500', 'red.200')(props),
      },
      instructionBannerText: {
        color: mode('white', 'gray.900')(props),
      },
      containerCard: {
        bg: mode('white', 'darkmode.gray.800')(props),
        borderColor: mode('gray.300', 'gray.800')(props),
      },
      scannerHeader: {
        bgColor: mode('red.50', 'red.200')(props),
      },
      scannerHeaderText: {
        color: mode('red.700', 'gray.900')(props),
      },
      instructionText: {
        color: mode('gray.400', 'gray.700')(props),
      },
      instructionNumber: {
        color: mode('gray.400', 'gray.700')(props),
      },
      secondaryBanner: {
        color: mode('white', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('white', 'gray.900')(props),
      },
    }),
    exceptionSecondary: (props) => ({
      instructionBanner: {
        bgColor: mode('red.50', 'red.200')(props),
      },
      instructionBannerText: {
        color: mode('red.700', 'gray.900')(props),
      },
      containerCard: {
        bg: mode('white', 'darkmode.gray.800')(props),
        borderColor: mode('gray.300', 'gray.800')(props),
      },
      scannerHeader: {
        bgColor: mode('red.50', 'red.200')(props),
      },
      scannerHeaderText: {
        color: mode('red.700', 'gray.900')(props),
      },
      instructionText: {
        color: mode('gray.400', 'gray.700')(props),
      },
      instructionNumber: {
        color: mode('gray.400', 'gray.700')(props),
      },
      secondaryBanner: {
        color: mode('white', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('white', 'gray.900')(props),
      },
    }),
    location: (props) => ({
      instructionBanner: {
        bgColor: mode('green.600', 'green.200')(props),
      },
      instructionBannerText: {
        color: mode('white', 'gray.900')(props),
      },
      containerCard: {
        bg: mode('white', 'darkmode.gray.800')(props),
        borderColor: mode('gray.300', 'gray.800')(props),
      },
      scannerHeader: {
        bgColor: mode('green.50', 'green.200')(props),
      },
      scannerHeaderText: {
        color: mode('green.600', 'gray.900')(props),
      },
      secondaryBanner: {
        color: mode('white', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('white', 'gray.900')(props),
      },
      instructionText: {
        color: mode('green.600', 'green.200')(props),
      },
      instructionNumber: {
        color: mode('green.600', 'green.200')(props),
      },
    }),
    pick: (props) => ({
      instructionBanner: {
        bgColor: mode('blue.50', 'darkmode.blue.200')(props),
      },
      instructionBannerText: {
        color: mode('blue.500', 'gray.900')(props),
      },
      scannerHeader: {
        bgColor: mode('blue.50', 'darkmode.blue.200')(props),
      },
      scannerHeaderText: {
        color: mode('blue.500', 'gray.900')(props),
      },
      instructionText: {
        color: mode('blue.500', 'darkmode.blue.200')(props),
      },
      instructionNumber: {
        color: mode('blue.500', 'darkmode.blue.200')(props),
      },
      secondaryBanner: {
        color: mode('blue.500', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('blue.500', 'gray.900')(props),
      },
    }),
    place: (props) => ({
      instructionBanner: {
        bgColor: mode('purple.50', 'purple.200')(props),
      },
      instructionBannerText: {
        color: mode('purple.700', 'gray.900')(props),
      },
      scannerHeader: {
        bgColor: mode('purple.50', 'purple.200')(props),
      },
      scannerHeaderText: {
        color: mode('purple.700', 'gray.900')(props),
      },
      instructionText: {
        color: mode('purple.700', 'purple.200')(props),
      },
      instructionNumber: {
        color: mode('purple.700', 'purple.200')(props),
      },
      secondaryBanner: {
        color: mode('purple.700', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('purple.700', 'gray.900')(props),
      },
    }),
    primary: (props) => ({
      instructionBanner: {
        bgColor: mode('blue.500', 'darkmode.blue.200')(props),
      },
      instructionBannerText: {
        color: mode('white', 'gray.900')(props),
      },
      containerCard: {
        bg: mode('white', 'darkmode.gray.800')(props),
        borderColor: mode('gray.300', 'gray.800')(props),
      },
      scannerHeader: {
        bgColor: mode('blue.50', 'darkmode.blue.200')(props),
      },
      scannerHeaderText: {
        color: mode('blue.500', 'gray.900')(props),
      },
      instructionText: {
        color: mode('gray.400', 'gray.700')(props),
      },
      instructionNumber: {
        color: mode('gray.400', 'gray.700')(props),
      },
      secondaryBanner: {
        color: mode('white', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('white', 'gray.900')(props),
      },
    }),
    quantity: (props) => ({
      instructionBanner: {
        bgColor: mode('purple.700', 'purple.200')(props),
      },
      instructionBannerText: {
        color: mode('white', 'gray.900')(props),
      },
      scannerHeader: {
        bgColor: mode('purple.50', 'purple.200')(props),
      },
      scannerHeaderText: {
        color: mode('purple.700', 'gray.900')(props),
      },
      instructionText: {
        color: mode('purple.700', 'purple.200')(props),
      },
      instructionNumber: {
        color: mode('purple.700', 'purple.200')(props),
      },
      secondaryBanner: {
        color: mode('purple.700', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('purple.700', 'gray.900')(props),
      },
    }),
    product: (props) => ({
      instructionBanner: {
        bgColor: mode('green.100', 'green.100')(props),
      },
      instructionBannerText: {
        color: mode('green.800', 'green.800')(props),
      },
      containerCard: {
        bg: mode('white', 'darkmode.gray.800')(props),
        borderColor: mode('gray.300', 'gray.800')(props),
      },
      scannerHeader: {
        bgColor: mode('green.100', 'green.100')(props),
      },
      scannerHeaderText: {
        color: mode('green.800', 'green.800')(props),
      },
      secondaryBanner: {
        color: mode('white', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('white', 'gray.900')(props),
      },
      instructionText: {
        color: mode('green.600', 'green.200')(props),
      },
      instructionNumber: {
        color: mode('green.600', 'green.200')(props),
      },
    }),
    trackedAttributes: (props) => ({
      instructionBanner: {
        bgColor: mode('blue.50', 'darkmode.blue.200')(props),
      },
      instructionBannerText: {
        color: mode('blue.500', 'gray.900')(props),
      },
      scannerHeader: {
        bgColor: mode('blue.50', 'darkmode.blue.200')(props),
      },
      scannerHeaderText: {
        color: mode('blue.500', 'gray.900')(props),
      },
      instructionText: {
        color: mode('blue.500', 'darkmode.blue.200')(props),
      },
      instructionNumber: {
        color: mode('blue.500', 'darkmode.blue.200')(props),
      },
      secondaryBanner: {
        color: mode('blue.500', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('blue.500', 'gray.900')(props),
      },
    }),
    recountPrimary: (props) => ({
      instructionBanner: {
        bgColor: mode('purple.50', 'purple.200')(props),
      },
      instructionBannerText: {
        color: mode('purple.700', 'gray.900')(props),
      },
      scannerHeader: {
        bgColor: mode('purple.50', 'purple.200')(props),
      },
      scannerHeaderText: {
        color: mode('purple.700', 'gray.900')(props),
      },
      instructionText: {
        color: mode('purple.700', 'purple.200')(props),
      },
      instructionNumber: {
        color: mode('purple.700', 'purple.200')(props),
      },
      secondaryBanner: {
        color: mode('purple.700', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('purple.700', 'gray.900')(props),
      },
    }),
    recountSecondary: (props) => ({
      instructionBanner: {
        bgColor: mode('red.50', 'red.200')(props),
      },
      instructionBannerText: {
        color: mode('red.700', 'gray.900')(props),
      },
      scannerHeader: {
        bgColor: mode('red.50', 'red.200')(props),
      },
      scannerHeaderText: {
        color: mode('red.700', 'gray.900')(props),
      },
      instructionText: {
        color: mode('gray.400', 'gray.700')(props),
      },
      instructionNumber: {
        color: mode('gray.400', 'gray.700')(props),
      },
      secondaryBanner: {
        color: mode('white', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('white', 'gray.900')(props),
      },
    }),
    recountTertiary: (props) => ({
      instructionBanner: {
        bgColor: mode('red.500', 'red.200')(props),
      },
      instructionBannerText: {
        color: mode('white', 'gray.900')(props),
      },
      scannerHeader: {
        bgColor: mode('red.50', 'red.200')(props),
      },
      scannerHeaderText: {
        color: mode('red.700', 'gray.900')(props),
      },
      instructionText: {
        color: mode('gray.400', 'gray.700')(props),
      },
      instructionNumber: {
        color: mode('gray.400', 'gray.700')(props),
      },
      secondaryBanner: {
        color: mode('white', 'gray.900')(props),
      },
      secondaryBannerText: {
        color: mode('white', 'gray.900')(props),
      },
    }),
  },
};
