import type { FormEventHandler } from 'react';
import { useState } from 'react';
import {
  useDownloadLocationFileMutation,
  usePostLocationsUploadMutation,
} from '@store/services/api';
import { useToast } from '@hooks';
import { FormButtons, FormContentHeader } from '@components/multi-step-form';
import type { MultiStepFormPageProps } from '@components/multi-step-form';
import { UploadSection } from '../shared';
import type { CreateBuildingFormState } from '@features/building/CreateBuilding/CreateBuilding';

export const AddLocations = ({
  multiStepFormState,
  onBackClick,
  onSkipClick,
  onSuccess,
}: MultiStepFormPageProps<CreateBuildingFormState>) => {
  const [downloadTrigger, { isLoading: isDownloadingLocations }] =
    useDownloadLocationFileMutation();

  const [uploadLocationFile] = usePostLocationsUploadMutation();

  const [selectedLocationFile, setSelectedLocationFile] = useState<File | undefined>(undefined);

  const { errorToast, successToast } = useToast();

  const handleUploadLocations: FormEventHandler = async (e) => {
    e.preventDefault();

    if (!selectedLocationFile) {
      errorToast('Please select a file before trying to upload locations.');
    } else {
      const formData = new FormData();
      formData.append('file', selectedLocationFile);
      formData.append('type', 'text/csv');
      formData.append('title', selectedLocationFile.name);
      try {
        await uploadLocationFile({ body: formData }).unwrap();
        successToast('Locations updated successfully.');
        onSuccess(multiStepFormState);
      } catch (err) {
        console.error(err);
        errorToast(
          'The upload failed. Please check your file for proper formatting and try again.'
        );
      }
    }
  };

  return (
    <form onSubmit={handleUploadLocations}>
      <FormContentHeader
        mainText="Add to your list of warehouse locations"
        secondaryText="Your building was created with some default locations already.
          Download the file and make any updates/additions, then re-upload it.
          Do not edit or remove the ID column."
        stepNumber={4}
        totalSteps={6}
      />
      <UploadSection
        downloadFilename="locations"
        downloadLabel="Download Default Locations"
        downloadTrigger={downloadTrigger}
        isDownloading={isDownloadingLocations}
        selectedFile={selectedLocationFile}
        setSelectedFile={setSelectedLocationFile}
        uploadLabel="Upload Locations"
      />
      <FormButtons
        allowBack
        allowSkip
        submitLabel="Upload"
        onBackClick={onBackClick}
        onSkipClick={onSkipClick}
        isSubmitDisabled={!selectedLocationFile}
      />
    </form>
  );
};

export default AddLocations;
