import { CloseButton, Flex, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircleExclamation,
  faInfoCircle,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import type { ReactNode } from 'react';

interface ToastOptions {
  title?: ReactNode;
  description?: ReactNode;
  duration?: number;
}

interface CustomToastProps extends ToastOptions {
  onClose: () => void;
  variant: 'error' | 'info' | 'success' | 'warning';
}

const getToastIcon = (variant: CustomToastProps['variant']) => {
  switch (variant) {
    case 'error':
      return faCircleExclamation;
    case 'success':
      return faCheckCircle;
    case 'warning':
      return faTriangleExclamation;
    default:
      return faInfoCircle;
  }
};

export const Toast = ({ description, variant = 'info', title, onClose }: CustomToastProps) => {
  const styles = useMultiStyleConfig('Toast', { variant });
  const icon = getToastIcon(variant);

  return (
    <Flex sx={styles.container} data-testid="toast-container" className="_translate">
      <Flex sx={styles.iconContainer}>
        <FontAwesomeIcon color="white" icon={icon} />
      </Flex>
      <Flex sx={styles.messageContainer}>
        <>
          {title && (
            <Text
              sx={styles.title}
              alignSelf={description ? 'auto' : 'center'}
              data-testid="toast-title"
            >
              {title}
            </Text>
          )}
          {description && (typeof description === 'string' || typeof description === 'number') ? (
            <Text data-testid="toast-description" fontSize={title ? 'sm' : 'base'}>
              {description}
            </Text>
          ) : (
            description
          )}
        </>
      </Flex>
      <CloseButton onClick={onClose} size="sm" m="0.5rem" />
    </Flex>
  );
};
