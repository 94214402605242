import { Box, Flex, useColorModeValue as mode } from '@chakra-ui/react';
import type { ComponentType, ReactElement } from 'react';
import { Progressbar, Step } from './';
import { useProgressState } from '@hooks';
import { WmsModal } from '@components';
import { BiCheck } from 'react-icons/bi';

export interface MultiStepFormPageProps<T> {
  multiStepFormState: T;
  onBackClick: () => void;
  onClose: () => void;
  onSkipClick: (stepNumber?: number) => void;
  onSuccess: (multiStepFormState: T) => void;
}

export type FormStep<T> = {
  label: string;
  optional?: boolean;
  icon: ReactElement;
  pageComponent: ComponentType<MultiStepFormPageProps<T>>;
};

export const MultiStepForm = <T extends Record<any, any>>({
  isOpen,
  multiStepFormState,
  onCloseModal,
  setMultiStepFormState,
  steps,
  title,
}: {
  isOpen: boolean;
  multiStepFormState?: T;
  onCloseModal: () => void;
  setMultiStepFormState: (updatedMultiStepState: T) => void;
  steps: FormStep<T>[];
  title: string;
}) => {
  const { value, getState, setActiveStep, activeStep } = useProgressState(steps);

  const onStepSuccess = (updatedMultiStepState: T) => {
    setMultiStepFormState(updatedMultiStepState);

    if (activeStep === steps.length - 1) {
      onClose();
      return;
    }

    setActiveStep(activeStep + 1);
  };

  const onClose = () => {
    onCloseModal();
    setActiveStep(0);
    setMultiStepFormState({} as T);
  };

  const getStepContent = (index: number) => {
    const props: MultiStepFormPageProps<T> = {
      onBackClick: () => setActiveStep(activeStep - 1),
      onClose,
      multiStepFormState: multiStepFormState as T,
      onSkipClick: (nextStep?: number) => setActiveStep(nextStep ?? activeStep + 1),
      onSuccess: onStepSuccess,
    };

    const PageComponent = steps[index]?.pageComponent;

    if (!PageComponent) {
      return null;
    }

    return <PageComponent {...props} />;
  };

  return (
    <WmsModal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      modalContentProps={{
        paddingX: 0,
        paddingY: 0,
      }}
      modalHeaderProps={{
        fontSize: '3xl',
        borderBottom: '1px solid',
        borderColor: mode('gray.400', 'whiteAlpha.300'),
      }}
      onClose={onClose}
      size="2xl"
      title={title}
    >
      <Box mx="auto" maxH="3xl" py={2} px={0}>
        <Box as="nav" py={2} aria-label="Steps" position="relative" display="flex">
          <Flex
            borderRight="1px solid"
            borderColor={mode('gray.200', 'whiteAlpha.300')}
            direction="column"
            justify="space-between"
            as="ol"
            listStyleType="none"
            pr={4}
            width="33%"
          >
            {steps.map((step, index) => (
              <Step
                label={step?.label!}
                key={index}
                state={getState(index)}
                stepNumber={index}
                optional={step?.optional}
              >
                {activeStep > index ? <BiCheck /> : step.icon}
              </Step>
            ))}
            <Progressbar value={value} />
          </Flex>
          <Flex flex={2} p={4} direction="column">
            {getStepContent(activeStep)}
          </Flex>
        </Box>
      </Box>
    </WmsModal>
  );
};

export default MultiStepForm;
