import {
  faPalletBox,
  faLocationDot,
  faTruckArrowRight,
  faBoxesStacked,
} from '@fortawesome/pro-solid-svg-icons';
import type { ScanResultType } from '../types';

export const getIconForType = (type: ScanResultType) => {
  switch (type) {
    case 'lpn':
      return faPalletBox;
    case 'location':
      return faLocationDot;
    case 'product':
      return faBoxesStacked;
    case 'outbound':
      return faTruckArrowRight;
  }
};
