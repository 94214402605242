import { useEffect } from 'react';
import { getChannelsPubSub } from '@utils';
import type { ChannelsPubSub } from '@utils';
import type { KnownTopic } from '@features/realtime/realtimeSlice';
import { useShallowStableValue } from '@hooks';

const channelsPubSub = getChannelsPubSub();

export function useChannelsPubSub(
  topics: Array<KnownTopic>,
  callback: ({ topic, event, payload }: ChannelsPubSub) => any,
  /**
   * @remarks Passing a reservedKey will enforce a unique subscription constraint - if you call the hook many times while providing the same reservedKey, only one subscription will be created until the existing one unsubscribes.
   */
  reservedKey?: string
) {
  // We make the list of topics stable to prevent accidental infinite loop issues
  // from regular usage.
  const stableTopics = useShallowStableValue(topics);

  useEffect(() => {
    const subscription = channelsPubSub.subscribe((message) => {
      if (stableTopics.some((topic) => topic.includes(message.topic))) {
        callback({
          topic: message.topic,
          event: message.event,
          payload: message.payload,
        });
      }
    }, reservedKey);

    return () => subscription?.unsubscribe();
  }, [callback, stableTopics, reservedKey]);
}
