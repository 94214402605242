import type React from 'react';
import { useEffect } from 'react';
import { useAuth } from '@hooks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectCurrentBuildingId } from '@features/auth/authSlice';
import SelectBuildingModal from '@features/building/SelectBuildingModal';
import { setBuildingId } from '@features/auth/actions';
import { EnvironmentBar } from '@components/EnvironmentBar';
import { getBuildingIdFromLocalStorage } from '@utils';

export const RequireBuilding = ({ children }: React.PropsWithChildren<{}>) => {
  const { isStordEmployee } = useAuth();
  const dispatch = useAppDispatch();
  const currentBuildingId = useAppSelector(selectCurrentBuildingId);

  // Users with stord_employee role can select any building, which will be set in local storage
  const buildingId = isStordEmployee ? getBuildingIdFromLocalStorage() : currentBuildingId;

  useEffect(() => {
    // only need to this on the initial setting of the building id
    if (isStordEmployee && buildingId && !currentBuildingId) {
      dispatch(setBuildingId({ id: buildingId! }));
    }
  }, [buildingId, currentBuildingId, dispatch, isStordEmployee]);

  if (isStordEmployee && !buildingId) {
    return (
      <>
        <EnvironmentBar />
        <SelectBuildingModal isOpen />
      </>
    );
  }

  return <>{children}</>;
};
