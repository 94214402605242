import { useGetZonesQuery } from '@store/services/api';
import { useMemo } from 'react';
import { MAX_PAGE_SIZE } from '@constants/paging';

export const useGetZoneOptions = ({
  blankOptionText,
  showBlankOption,
}: {
  blankOptionText?: string;
  showBlankOption?: boolean;
}) => {
  const { data, isLoading, isFetching, isError, error } = useGetZonesQuery({
    sort: 'name',
    pageSize: MAX_PAGE_SIZE,
  });

  const zoneOptions = useMemo(() => {
    const blankOption = showBlankOption ? [{ label: blankOptionText ?? '--', value: '' }] : [];
    const zoneDataOptions = (data?.data ?? []).map((zone) => {
      return {
        label: zone?.name!,
        value: zone?.id!,
      };
    });

    return [...blankOption, ...zoneDataOptions];
  }, [blankOptionText, data, showBlankOption]);

  const zoneMap = useMemo(
    () => new Map((data?.data ?? []).map((zone) => [zone.id!, zone])),
    [data]
  );

  return {
    data,
    zoneMap,
    zoneOptions,
    isLoading: isLoading || isFetching,
    isError,
    error,
  };
};
