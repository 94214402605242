import * as React from 'react';
import { Spinner } from '@chakra-ui/spinner';
import type { UseMultipleSelectionProps } from 'downshift';
import { useCombobox, useMultipleSelection } from 'downshift';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
import type { FormLabelProps } from '@chakra-ui/form-control';
import type { ListProps, ListItemProps } from '@chakra-ui/layout';
import { Text, Stack, Box, List, ListItem } from '@chakra-ui/layout';
import type { ButtonProps } from '@chakra-ui/button';
import { Button } from '@chakra-ui/button';
import type { InputProps } from '@chakra-ui/input';
import { Input, InputGroup, InputRightElement, InputLeftElement } from '@chakra-ui/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faCircleCheck, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import type { StackProps, TagProps } from '@chakra-ui/react';
import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { useColorModeValue as mode } from '@chakra-ui/react';
import { FilterLabel } from '@components';

export interface Item {
  label: string;
  value: string;
}

export interface ChakraAutocompleteProps<T extends Item> extends UseMultipleSelectionProps<T> {
  items: T[];
  placeholder: string;
  label?: React.ReactNode;
  highlightItemBg?: string;
  highlightItemColor?: string;
  onCreateItem?: (item: T) => void;
  optionFilterFunc: (items: T[], inputValue: string) => T[];
  itemRenderer?: (item: T) => string | JSX.Element;
  labelStyleProps?: FormLabelProps;
  inputStyleProps?: InputProps & { 'data-testid'?: string };
  isSearching?: boolean;
  toggleButtonStyleProps?: ButtonProps;
  tagStyleProps?: TagProps;
  listStyleProps?: ListProps;
  listItemStyleProps?: ListItemProps;
  emptyState?: (inputValue: string) => React.ReactNode;
  hideToggleButton?: boolean;
  createItemRenderer?: (value: string) => string | JSX.Element;
  disableCreateItem?: boolean;
  renderCustomInput?: (inputProps: any, toggleButtonProps: any) => JSX.Element;
  showCreateItemWhenNoExactMatch?: boolean;
  containerProps?: StackProps;
}

function defaultCreateItemRenderer(value: string) {
  if (value) {
    return (
      <Text>
        <Box as="span">Create</Box>{' '}
        <Box as="span" fontWeight="bold">
          "{value}"
        </Box>
      </Text>
    );
  }

  return null;
}
export const ChakraAutocomplete = <T extends Item>(
  props: ChakraAutocompleteProps<T>
): React.ReactElement<ChakraAutocompleteProps<T>> => {
  const {
    items,
    optionFilterFunc,
    itemRenderer,
    highlightItemBg = mode('blue.50', 'blue.700'),
    highlightItemColor = mode('blue.700', 'blue.50'),
    placeholder,
    label,
    listStyleProps,
    labelStyleProps,
    inputStyleProps,
    toggleButtonStyleProps,
    tagStyleProps,
    listItemStyleProps,
    onCreateItem,
    isSearching,
    hideToggleButton = false,
    disableCreateItem = false,
    createItemRenderer = defaultCreateItemRenderer,
    renderCustomInput,
    showCreateItemWhenNoExactMatch = false,
    containerProps,
    ...downshiftProps
  } = props;

  /* States */
  const [isCreating, setIsCreating] = React.useState(false);
  const [inputItems, setInputItems] = React.useState<T[]>(items);

  /* Refs */
  const disclosureRef = React.useRef(null);

  /* Downshift Props */
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection(downshiftProps);
  const selectedItemValues = selectedItems.map((item) => item.value);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectItem,
    setHighlightedIndex,
  } = useCombobox({
    selectedItem: undefined,
    items: inputItems,
    onInputValueChange: ({ inputValue, selectedItem }) => {
      // do not pass the string [object Object] if it appears after resetting input
      if (inputValue === '[object Object]') return;

      const filteredItems = optionFilterFunc(items, inputValue || '');

      if (isCreating && filteredItems.length > 0) {
        setIsCreating(false);
      }

      if (!selectedItem) {
        setInputItems(filteredItems);
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            isOpen: false,
          };
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: false,
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            isOpen: true,
            inputValue: changes.inputValue === '[object Object]' ? '' : changes.inputValue,
          };
        case useCombobox.stateChangeTypes.FunctionSelectItem:
          return {
            ...changes,
            inputValue: '',
            isOpen: false,
          };
        case useCombobox.stateChangeTypes.InputChange:
          return {
            ...changes,
            highlightedIndex: 0,
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (selectedItemValues.includes(selectedItem.value)) {
              removeSelectedItem(selectedItem);
            } else {
              if (onCreateItem && isCreating) {
                onCreateItem(selectedItem);
                setIsCreating(false);
                setInputItems(items);
              } else {
                addSelectedItem(selectedItem);
              }
            }

            selectItem(null);
          }
          break;
        default:
          break;
      }
    },
  });

  React.useEffect(() => {
    if (disableCreateItem) {
      return;
    }

    const { value } = getInputProps();

    // if no matches, allow the create option to display
    if (inputItems.length === 0) {
      setIsCreating(true);
      // @ts-expect-error replace ts-ignore
      setInputItems([{ label: `${value}`, value }]);
      setHighlightedIndex(0);
      return;
    }

    // allows the create options to display if no options match the input value
    const hasInputMatch = inputItems?.some((item) => item.label === value);
    if (!hasInputMatch && showCreateItemWhenNoExactMatch) {
      setIsCreating(true);
      // @ts-expect-error replace ts-ignore
      setInputItems([...inputItems, { label: `${value}`, value }]);
      setHighlightedIndex(0);
    }

    // if items are returned and we're in create mode, disable create mode
    if (items.length > 0 && isCreating) {
      setIsCreating(false);
    }
  }, [
    items.length,
    isCreating,
    isSearching,
    inputItems,
    setIsCreating,
    setHighlightedIndex,
    getInputProps,
    disableCreateItem,
    showCreateItemWhenNoExactMatch,
  ]);

  useDeepCompareEffect(() => {
    setInputItems(items);
  }, [items]);

  const backgroundColor = mode('white', 'darkmode.gray.800');

  return (
    <Stack spacing={0} {...containerProps}>
      {label && <FilterLabel label={label} {...labelStyleProps} />}
      {/* ---------Stack with Selected Menu Tags above the Input Box--------- */}
      {selectedItems && (
        <Stack spacing={2} flexWrap="wrap">
          {selectedItems.map((selectedItem, index) => (
            <Tag
              mb={1}
              {...tagStyleProps}
              key={`selected-item-${index}`}
              {...getSelectedItemProps({ selectedItem, index })}
            >
              <TagLabel>{selectedItem.label}</TagLabel>
              <TagCloseButton
                onClick={(e) => {
                  e.stopPropagation();
                  removeSelectedItem(selectedItem);
                }}
                aria-label="Remove menu selection badge"
              />
            </Tag>
          ))}
        </Stack>
      )}
      {/* ---------Stack with Selected Menu Tags above the Input Box--------- */}

      {/* -----------Section that renders the input element ----------------- */}
      <Stack>
        {renderCustomInput ? (
          renderCustomInput(
            {
              ...inputStyleProps,
              ...getInputProps(
                getDropdownProps({
                  placeholder,
                  ref: disclosureRef,
                })
              ),
            },
            {
              ...toggleButtonStyleProps,
              ...getToggleButtonProps(),
              ariaLabel: 'toggle menu',
              hideToggleButton,
            }
          )
        ) : (
          <>
            <InputGroup>
              <InputLeftElement>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputLeftElement>
              <Input
                {...inputStyleProps}
                {...getInputProps(
                  getDropdownProps({
                    placeholder,
                    onClick: isOpen ? () => {} : openMenu,
                    onFocus: isOpen ? () => {} : openMenu,
                    ref: disclosureRef,
                  })
                )}
              />
              <InputRightElement>
                {isSearching ? (
                  <Spinner data-testid="chakra-autocomplete-loading" color="blue.400" size="sm" />
                ) : null}
              </InputRightElement>
            </InputGroup>
            {!hideToggleButton && (
              <Button
                {...toggleButtonStyleProps}
                {...getToggleButtonProps()}
                aria-label="toggle menu"
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </Button>
            )}
          </>
        )}
      </Stack>
      {/* -----------Section that renders the input element ----------------- */}

      {/* -----------Section that renders the Menu Lists Component ----------------- */}
      <Box>
        <List
          bg={backgroundColor}
          zIndex={10}
          borderRadius="md"
          border={isOpen && inputItems.length > 0 ? '1px solid rgba(0,0,0,0.1)' : {}}
          boxShadow={mode('md', 'dark-lg')}
          {...listStyleProps}
          {...getMenuProps()}
        >
          {isOpen &&
            inputItems.map((item, index) => (
              <ListItem
                px={2}
                py={1}
                borderBottom="1px solid rgba(0,0,0,0.01)"
                {...listItemStyleProps}
                bg={highlightedIndex === index ? highlightItemBg : 'inherit'}
                borderTopLeftRadius={index === 0 ? 'md' : '0'}
                borderTopRightRadius={index === 0 ? 'md' : '0'}
                borderBottomLeftRadius={index === inputItems.length - 1 ? 'md' : '0'}
                borderBottomRightRadius={index === inputItems.length - 1 ? 'md' : '0'}
                key={`${item.value}${index}`}
                data-testid={`option-${item.label}`}
                {...getItemProps({ item, index })}
              >
                {isCreating && index === inputItems.length - 1 ? (
                  createItemRenderer(item.label)
                ) : (
                  <Box display="inline-flex" alignItems="center" width="100%">
                    {selectedItemValues.includes(item.value) && (
                      <Box color="green.500">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </Box>
                    )}

                    {itemRenderer ? (
                      itemRenderer(item)
                    ) : (
                      <Text
                        cursor="pointer"
                        ml={2}
                        p={1}
                        color={highlightedIndex === index ? highlightItemColor : 'inherit'}
                      >
                        {item.label}
                      </Text>
                    )}
                  </Box>
                )}
              </ListItem>
            ))}
        </List>
      </Box>
      {/* ----------- End Section that renders the Menu Lists Component ----------------- */}
    </Stack>
  );
};
