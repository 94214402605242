import type React from 'react';
import { useEffect } from 'react';
import { useGetConfigQuery } from '@store/services/api';
import userflow from 'userflow.js';

export const ConfigGate = ({ children }: { children: React.ReactNode }) => {
  // Conceptually, we could autopoll here to check for updated configs assuming we ever used a service as well,
  // but being that we just copy the src/env/{env}.json file to config.json, we don't need to. This might be useful
  // in the future as doing this would RTK Query would allow us to also listen for config updates that are hot fixes
  // pushed over WS before another build/release happens
  const { data, isLoading, isError } = useGetConfigQuery();
  const cypressAuth = window.Cypress && window.localStorage.getItem('auth0Cypress');

  let content: React.ReactNode;

  if (isLoading) {
    content = null; // Prevent any type of screen flicker
  } else if (isError) {
    content = 'Error loading config';
  } else if (data) {
    content = children;
  } else {
    // Impossible!
    content = null;
  }

  useEffect(() => {
    const token = data?.USERFLOW_TOKEN;
    if (token && !cypressAuth) userflow.init(token);
  }, [cypressAuth, data]);

  return <div data-testid="config-gate">{content}</div>;
};
