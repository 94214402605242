export function getEnvironment() {
  const origin = window.location.origin;

  if (origin.includes('staging.wms.stord.com') || origin.includes('stord-wms.pages.dev')) {
    return 'staging';
  } else if (origin.includes('wms.stord.com')) {
    return 'production';
  } else if (
    origin.includes('localhost') ||
    origin.includes('nonprod.wms.stord.com') ||
    origin.includes('signalwms.ngrok.io')
  ) {
    return 'development';
  }

  return 'unknown';
}
