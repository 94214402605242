import type { ShipperConfig } from '@store/services/api.generated';

export interface WithOrderIdentifier {
  custom_reference?: string;
  external_id?: string;
  external_order_number?: string;
  shipper_id?: string;
}

export const getOrderIdentifier = (
  orderType: 'inbound' | 'outbound',
  order: WithOrderIdentifier,
  config?: ShipperConfig
): string | undefined => {
  if (!config) {
    return order.external_id;
  }

  const field = config?.[`${orderType}_order_identifier`];
  return order?.[field ?? 'external_id'] ?? order.external_id;
};

export const getOutboundOrderIdentifier = (order: WithOrderIdentifier, config?: ShipperConfig) =>
  getOrderIdentifier('outbound', order, config);

export const getInboundOrderIdentifier = (order: WithOrderIdentifier, config?: ShipperConfig) =>
  getOrderIdentifier('inbound', order, config);
