import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import type { TagProps } from '@chakra-ui/react';

type SelectedTagFC = {
  label?: React.ReactNode;
  onClickClose: () => void;
  showCloseButton?: boolean;
  testId?: string;
} & TagProps;

export const SelectedTag = ({
  label,
  onClickClose,
  showCloseButton = true,
  testId,
  ...rest
}: SelectedTagFC) => {
  return (
    <Tag h="30px" {...rest}>
      <TagLabel data-testid={testId}>{label}</TagLabel>
      {showCloseButton && (
        <TagCloseButton
          onClick={(e) => {
            e.stopPropagation();
            onClickClose();
          }}
          aria-label={`Remove ${label}`}
        />
      )}
    </Tag>
  );
};
