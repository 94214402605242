import { capitalize } from '@utils';
import type { ScanResultType } from '../types';

export const getTitleForType = (type: ScanResultType) => {
  switch (type) {
    case 'lpn':
      return 'LPN';
    case 'product':
      return 'SKU';
    case 'outbound':
      return 'Outbound Order';
    default:
      return capitalize(type);
  }
};
